import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewService } from '../../services/view.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})

export class ViewComponent implements OnInit {
  // filter input
  @Input() viewInput: any;
  // filter output
  @Output() viewOutput: EventEmitter<void> = new EventEmitter<void>();
  // view popup active state
  view_popup = false;
  // constructor
  constructor(
    private view: ViewService) {

  }
  // init
  ngOnInit(): void {
    // add default sort option on first position
    this.viewInput.sort.fields.unshift({
      field: 'date',
      name: 'Erstellungsdatum',
      type: 'string',
    });
  }
  // open view 
  openView() {
    // update popup active state
    this.view_popup = true;
    // no existing filter
    if (this.viewInput.filter.options.length == 0) {
      // add empty option
      // this.addFilterOption('');
    }
    // no existing sort
    if (this.viewInput.sort.options.length == 0) {
      // add empty option
      this.addSortOption();
    }
  }
  // close view
  closeView(): void {
    // update popup active state
    this.view_popup = false;
  }
  // add filter option
  addFilterOption(type: any) {
    // add empty option
    this.viewInput.filter.options.push(
      {
        field: this.viewInput.filter.fields[0].field,
        connection: type,
        operator: 'contains',
        value: '',
      }
    );
  }
  // remove filter option
  removeFilterOption(index: any) {
    // remove option
    this.viewInput.filter.options.splice(index, 1);
  }
  // remove all filter options
  removeFilterOptions() {
    // remove all options
    this.viewInput.filter.options = [];
  }
  // add sort option
  addSortOption() {
    // add empty option
    this.viewInput.sort.options.push(
      {
        field: this.viewInput.sort.fields[0].field,
        operator: 'desc',
        type: this.viewInput.sort.fields[0].type,
      }
    );
  }
  // process view
  processView() {
    // process view via service
    var view = this.view.processView(this.viewInput, false);
    // emit output to parent
    this.viewOutput.emit(view);
    // close view
    this.closeView();
  }
  // reset view
  resetView() {
    // input = output
    this.viewInput.output = this.viewInput.input;
    // reset filter and sort
    this.viewInput.filter.options = [];
    this.viewInput.sort.options = [];
    // emit output to parent
    this.viewOutput.emit(this.viewInput);
     // close filter
    this.closeView();
  }
}
