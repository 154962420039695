import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { WpService } from '../../services/wp.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  // tab
  tab = 1;
  // loading data
  loading_data = false;
  // saved
  saved = false;
  // user
  user: any;
  // form data
  form_data: FormGroup = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    profile_image: new FormControl(),
    email: new FormControl('', Validators.required),
  });
  // constructor
  constructor(
    private wp: WpService) {

  }
  // init
  ngOnInit(): void {
    this.getUser();
  }
  // get user
  getUser() {
    this.wp.getUserData().subscribe((response:any) => {
      // save data
      this.user = response;
      // prefill form general
      this.prefillFormData();
    });
  }
  // prefill form data
  prefillFormData() {
    this.form_data.setValue({
      first_name: this.user.user_first_name ? this.user.user_first_name : '',
      last_name: this.user.user_last_name ? this.user.user_last_name : '',
      profile_image: this.user.acf.profilbild ? this.user.acf.profilbild : '',
      email: this.user.user_email ? this.user.user_email : '',
    })
  }
  // on profile image change
  onProfileImageChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form_data.patchValue({
        profile_image: file
      });
    }
  }
  // update user
  updateUser(id: any) {
    // update loading
    this.loading_data = true;
    // logo change
    if (this.form_data.value.profile_image.name) {
      // upload profile image
      this.wp.uploadFile(this.form_data.value.profile_image, this.form_data.value.profile_image.name).subscribe((response:any) => {
        // update profile image
        this.wp.updateUserData(id, '{"fields":{"profilbild": ' + response.id + '}}').subscribe((response:any) => {
          // prepare data
          var data = JSON.stringify({
            'first_name': this.form_data.value.first_name,
            'last_name': this.form_data.value.last_name,
            'name':  this.form_data.value.first_name + ' ' +  this.form_data.value.last_name,
            'email': this.form_data.value.email,
          });
          // update user
          this.updateUserWp(id, data);
        });
      });
    }
    // no logo change
    else {
      // prepare data
      var data = JSON.stringify({
        'first_name': this.form_data.value.first_name,
        'last_name': this.form_data.value.last_name,
        'name':  this.form_data.value.first_name + ' ' +  this.form_data.value.last_name,
        'email': this.form_data.value.email,
      });
      // update user
      this.updateUserWp(id, data);
    }
  }
  // update wordpress user
  updateUserWp(id: any, data: any) {
    // update
    this.wp.updateUserDataWp(id, data).subscribe((response:any) => {
      // reset loading state
      this.loading_data = false;
      // sucessfully saved
      this.saved = true;
      // get user data
      this.getUser();
      // update saved variable to automatically hide success notification
      setTimeout(() => {
          this.saved = false;
      }, 3000);
    });
  }
}
