<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    <img class="mx-auto h-10 w-auto" src="./assets/images/logo.svg" alt="Funos Logo">
    <p *ngIf="error" [innerHtml]="error" class="mt-10 text-center text-sm text-signal-red bg-red-100 p-4"></p>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    <form (submit)="submitLogin()" class="space-y-6" action="#" method="POST">
      <div>
        <label for="email">Benutzername</label>
        <div class="mt-2">
          <input [(ngModel)]="user" id="user" name="user" type="text" autocomplete="text" required>
        </div>
      </div>

      <div>
        <div class="flex items-center justify-between">
          <label for="password">Passwort</label>
          <div class="text-sm">
            <a href="#" class="text-link">Passwort vergessen?</a>
          </div>
        </div>
        <div class="mt-2">
          <input [(ngModel)]="password" id="password" name="password" type="password" autocomplete="current-password" required>
        </div>
      </div>

      <div>
        <button *ngIf="!loading" type="submit" class="primary flex gap-2 justify-center w-full">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1C7.51472 1 5.5 3.01472 5.5 5.5V9H5C3.89543 9 3 9.89543 3 11V17C3 18.1046 3.89543 19 5 19H15C16.1046 19 17 18.1046 17 17V11C17 9.89543 16.1046 9 15 9H14.5V5.5C14.5 3.01472 12.4853 1 10 1ZM13 9V5.5C13 3.84315 11.6569 2.5 10 2.5C8.34315 2.5 7 3.84315 7 5.5V9H13Z" fill="#0F172A"/>
          </svg>
          <span>Login</span>
        </button>
        <div *ngIf="loading" class="text-center">
          <div role="status">
            <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#333"/>
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </form>

    <p class="mt-10 text-center text-sm text-gray-500">
      Noch keinen Account?
      <a href="https://www.funos.at/registrieren" class="text-link">Jetzt registrieren</a>
    </p>
  </div>
</div>