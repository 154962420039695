import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-customselect',
  templateUrl: './customselect.component.html',
  styleUrls: ['./customselect.component.css']
})

export class CustomselectComponent implements OnInit {
  // inputs
  @Input() controlName: string;
  @Input() options: string;
  // vars
  options_array: any = [];
  selected_option: string;
  custom_option: boolean = false;
  custom_input: string = '';
  // constructor
  constructor(
   private parentForm: FormGroupDirective) {

  }
  // on init
  ngOnInit(): void {
    // provide options as array
    this.options_array = this.options.split(',');
    // set selected option based on parent form value
    this.selected_option = this.parentForm.form.controls[this.controlName].value;
    // selected option is empty
    if (this.selected_option == '' || this.selected_option == null) {
      // load default
      this.loadDefault();
    }
    // selected option is custom value
    else if (this.options.indexOf(this.selected_option) === -1) {
      // prefill custom value
      this.custom_input = this.parentForm.form.controls[this.controlName].value;
      // set selected option to "custom"
      this.selected_option = 'custom';
      // set custom option to "true"
      this.custom_option = true;
    }
  }
  // on select change
  changeOption(event: any) {
    // selection option is "custom"
    if (event.value == 'custom') {
       // set custom option to "true"
      this.custom_option = true;
      // clear value
      event.value = '';
    }
    // update selected option
    this.selected_option = event.value;
    // change option within parent form
    this.parentForm.form.patchValue({
      [this.controlName]: event.value,
    });
  }
  // load default values
  loadDefault() {
    // prepare event
    var event = {
      // set value to first default value
      value: this.options_array[0]
    }
    // change option
    this.changeOption(event);
    // set custom option to "false"
    this.custom_option = false;
  }
}
