<!-- header -->
<div class="py-8 md:flex md:items-center md:justify-between">
	<div class="min-w-0 flex-1">
		<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Abrechnung</h2>
		<h3>Zeitraum: {{ billing_date_range.after }} — {{ billing_date_range.before }}</h3>
	</div>
	<div class="mt-4 flex md:ml-4 md:mt-0"></div>
</div>
<!-- customers -->
<ul role="list" class="grid grid-cols-2 gap-4">
	<li *ngFor="let customer of customers" class="overflow-hidden border border-sand">
		<div class="bg-grey-light border-b border-sand p-4 h-28">
			<p class="text-txt-large"><strong>{{ customer.acf.name }}</strong></p>
				<p [innerHTML]="customer.acf.address | nl2br" class="text-txt-large"></p>
		</div>
		<div class="px-4">
			<div *ngFor="let month of getMonthSort()" class="grid grid-cols-[25%,30%,40%,5%] justify-between items-center py-1 border-b border-grey-light text-txt-large last:border-b-0">
				<p>{{ months[month].name }}</p>
				<p><span class="text-sand">Sterbefälle </span><strong *ngIf="customer.acf.deceases_split">{{ customer.acf.deceases_split[month].length }}</strong></p>
				<p><span class="text-sand">Abgerechnet </span><strong *ngIf="customer.acf.deceases_split"><input (input)="saveBilling(customer, month, $event)" type="number" [value]="(customer.acf.billing[billing_year] && customer.acf.billing[billing_year][month]) ? customer.acf.billing[billing_year][month] : 0" [readonly]="loading_update" min="0" [max]="customer.acf.deceases_split[month].length" class="!w-16 !h-8 !p-2"></strong></p>
				<div *ngIf="customer.acf.deceases_split">
					<p *ngIf="checkMonthStatus(customer, month)" class="text-signal-green text-right">ok</p>
					<p *ngIf="!checkMonthStatus(customer, month)" class="text-signal-red text-right">!!!</p>
				</div>
			</div>
		</div>
		<div class="grid grid-cols-[20%,20%,auto] items-center gap-8 p-4 border-t border-sand">
			<div>
				<p class="font-bold">IST</p>
				<p *ngIf="customer.acf.sum_billed">{{ (customer.acf.sum_billed | number:"1.2-2":"de-DE") + " €" }}</p>
				<p *ngIf="!customer.acf.sum_billed">0,00 €</p>
			</div>
			<div>
				<p class="font-bold">SOLL</p>
				<p *ngIf="customer.acf.sum_all">{{ (customer.acf.sum_all | number:"1.2-2":"de-DE") + " €" }}</p>
				<p *ngIf="!customer.acf.sum_all">0,00 €</p>
			</div>
			<div *ngIf="customer.acf.sum_billed == customer.acf.sum_all" class="text-txt-small flex gap-2 items-baseline text-signal-green justify-end">
				<svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M9.16603 0.126042C9.51067 0.355806 9.6038 0.821458 9.37404 1.1661L4.37404 8.66611C4.2494 8.85306 4.0475 8.97429 3.8239 8.99643C3.6003 9.01857 3.37855 8.93929 3.21967 8.78041L0.21967 5.78041C-0.0732233 5.48752 -0.0732233 5.01264 0.21967 4.71975C0.512563 4.42686 0.987437 4.42686 1.28033 4.71975L3.63343 7.07285L8.12596 0.334055C8.35573 -0.010592 8.82138 -0.103722 9.16603 0.126042Z" fill="#73B82E"/>
				</svg>
				<span>Keine offenen Posten! 💰</span>
			</div>
			<div *ngIf="customer.acf.sum_billed != customer.acf.sum_all" class="text-txt-small flex gap-2 items-baseline text-signal-red justify-end">
				<svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M8.80789 0.55302C8.91678 0.153376 9.32903 -0.0823241 9.72867 0.0265689L12.8435 0.875294C13.2408 0.983538 13.4765 1.39181 13.3716 1.78997L12.5492 4.91189C12.4437 5.31244 12.0334 5.55161 11.6329 5.44609C11.2324 5.34057 10.9932 4.93032 11.0987 4.52978L11.4363 3.24827C10.1124 4.15807 8.9076 5.184 7.82741 6.30442C7.6877 6.44933 7.49561 6.532 7.29433 6.53384C7.09305 6.53568 6.89947 6.45654 6.75714 6.31421L5 4.55706L1.28033 8.27673C0.987437 8.56963 0.512563 8.56963 0.21967 8.27673C-0.0732233 7.98384 -0.0732233 7.50897 0.21967 7.21607L4.46967 2.96607C4.76256 2.67318 5.23744 2.67318 5.53033 2.96607L7.28597 4.72172C8.35156 3.68222 9.52228 2.72662 10.7936 1.87141L9.33434 1.47381C8.93469 1.36491 8.69899 0.952664 8.80789 0.55302Z" fill="#DC3545"/>
				</svg>
				<span>Abrechnung ausstehend! 🤑</span>
			</div>
		</div>
	</li>
</ul>