import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { WpService } from '../../services/wp.service';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
// import jsPDF from 'jspdf';
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var htmlToPdfmake = require("html-to-pdfmake");

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})

export class DocumentComponent implements OnInit {
  // logged in user
  @Input() user: any = [];
  // document id
  document_id: String = this.route.snapshot.params?.['id'];
  // document data
  document: any = [];
  // decease
  decease: any = [];
  // events
  events: any = [];
  // product connections
  product_connections: any = [];
  // pdf container
  @ViewChild('pdfContainer') pdfContainer: ElementRef;
  // pdf footer
  @ViewChild('pdfFooter') pdfFooter: ElementRef;
  // loading
  loading = true;
  // Object for object iteration in template
  Object = Object;
  // document type
  type: string = '';
  // decease doc content
  decease_doc_content: any = {
    decease: true,
    client: true,
    events: true,
    products: true,
    documents: true,
  }
  // needed documents
  needed_documents = {
    birth_certificate: {
      name: 'Geburtsurkkunde',
      exists: false
    },
    death_certificate: {
      name: 'Sterbeurkunde',
      exists: false
    },
    dsgvo_confirmation: {
      name: 'Datenschutzerklärung',
      exists: false
    },
  };
  // current time
  timestamp = new Date();
  // recipient
  recipients: any = [];
  // bids
  bids: any = [];
  // bid basis
  bid_basis: any = false;
  // contructor
  constructor(
    private route: ActivatedRoute,
    private wp: WpService,
    private router: Router) {
    // reload page when calling component via router.navigate
    // otherwise constructor and init functions are not called
    this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
    // get document data
    this.getDocumentData(this.document_id);
  }
  // on init
  ngOnInit(): void {

  }
  // get document data
  getDocumentData(id: any) {
    if (id) {
      // decease document
      if (id == 'decease') {
        // set document type
        this.type = 'decease';
        // get decease document
        this.getDeceaseDocument(this.route.snapshot.queryParamMap.get('id'));
      }
      // new bid document
      else if (id == 'bid') {
        // set document type
        this.type = 'bid_new';
        // get new bid document
        this.getNewBidDocument(this.route.snapshot.queryParamMap.get('id'));
      }
      // new invoice document
      else if (id == 'invoice') {
        // set document type
        this.type = 'invoice_new';
        // get new bid document
        this.getNewInvoiceDocument(this.route.snapshot.queryParamMap.get('id'));
      }
      // all other documents
      else {
        // get document
        this.getDocument(id);
      }
    }
    else {

      // TODO: error handling if no ID given via url param

    }
  }
  // get decease document
  getDeceaseDocument(decease_id: any) {
    // get decease
    this.decease = this.getDecease(decease_id);
    // get events
    this.events = this.getEvents(decease_id);
    // get product conncations
    this.product_connections = this.getProductConnections(decease_id);
    // get documents
    var documents = this.wp.getDocuments(decease_id).subscribe((response: any) => {
      // check needed documents
      for (const [key, value] of Object.entries(this.needed_documents)) {
        if (response.find((x:any) => x.acf.type == key) !== undefined) {
          this.needed_documents[key as keyof typeof this.needed_documents]['exists'] = true;
        }
        else {
          this.needed_documents[key as keyof typeof this.needed_documents]['exists'] = false;
        }
      }
    });
  }
  // get decease
  getDecease(decease_id: any) {
    this.wp.getDecease(decease_id).subscribe((response:any) => {
      // save decease data
      this.decease = response;
      // loading update
      this.loading = false;
    });
  }
  // get events
  getEvents(decease_id: any) {
    this.wp.getEvents(decease_id).subscribe((response:any) => {
      this.events = response;
      this.events.forEach(function(value: any, index: any) {
        value.acf.custom_fields = JSON.parse(value.acf.custom_fields);
        value.acf.show = true;
      });
    });
  }
  // get product connections
  getProductConnections(decease_id: any) {
    this.wp.getProductConnections(decease_id).subscribe((response:any) => {
      this.product_connections = response;
    });
  }
  // prepare new bid
  async getNewBidDocument(decease_id: any) {
    // get decease
    this.decease = await firstValueFrom(this.wp.getDecease(decease_id));
    // check if decease belongs to current undertaker
    if (this.user.acf.undertaker.ID == this.decease.acf.undertaker.ID) {
      // get recipients
      this.recipients = await firstValueFrom(this.wp.getContacts(decease_id));
      // get bid counter
      var bid_counter = this.user.acf.undertaker.acf.bid_counter ? parseInt(this.user.acf.undertaker.acf.bid_counter)+1 : 1;
      // get available product connections
      this.product_connections = await firstValueFrom(this.wp.getProductConnections(decease_id));
      var available_products: any = [];
      this.product_connections.forEach((element: any) => {
        if (!element.acf.bid ||  element.acf.bid == '') {
          available_products.push(element);
        }
      });
      this.product_connections = available_products;
      // price
      var price : any = 0;
      // get product connections
      var products: any = [];
      this.product_connections.forEach((element: any, index: any) => {
        products[index] = this.prepareProduct(element);
        // upate price
        price += parseFloat(element.acf.price_total);
      });
      // calculate totals
      var totals = this.calculateTotals(products);
      // set up document data
      this.document = {
        'products': products,
        'products_block': totals.products_block,
        'totals': totals.totals,
        'bid_counter_raw': bid_counter,
        'bid_counter': 'A' + new Date().getFullYear() + '-' + bid_counter,
        'timestamp': this.timestamp,
        'recipient': this.recipients[0],
      };
      // update loading
      this.loading = false;
    }
    else {
      // TODO: error handling
    }
  }
  // prepare new invoice
  async getNewInvoiceDocument(decease_id: any) {
    // get decease
    this.decease = await firstValueFrom(this.wp.getDecease(decease_id));
    // check if decease belongs to current undertaker
    if (this.user.acf.undertaker.ID == this.decease.acf.undertaker.ID) {
      // get active bids
      var documents = await firstValueFrom(this.wp.getDocuments(decease_id));
      documents.forEach((element: any) => {
        if (element.acf.type == 'bid' && element.acf.status != 3) {
          this.bids.push(element);
        }
      });
      // set bid basis to first entry
      this.bid_basis = this.bids[0];
      // get recipients
      this.recipients = await firstValueFrom(this.wp.getContacts(decease_id));
      // get invoice counter
      var invoice_counter = this.user.acf.undertaker.acf.invoice_counter ? parseInt(this.user.acf.undertaker.acf.invoice_counter)+1 : 1;
      // get available product connections
      this.product_connections = await firstValueFrom(this.wp.getProductConnections(decease_id));
      var available_products: any = [];
      this.product_connections.forEach((element: any) => {
        if (!element.acf.invoice ||  element.acf.invoice == '') {
          available_products.push(element);
        }
      });
      this.product_connections = available_products;
      // price
      var price : any = 0;
      // get product connections
      var products: any = [];
      this.bid_basis.acf.content.products.forEach((element: any, index: any) => {
        // product is not part of existing invoice
        if (this.product_connections.find((o: any) => o.id === element.id)) {
          // add product
          products[index] = this.prepareProduct(this.product_connections.find((o: any) => o.id === element.id));
          // upate price
          price += parseFloat(this.product_connections.find((o: any) => o.id === element.id).acf.price_total);
        }
      });
      // calculate totals
      var totals = this.calculateTotals(products);
      // set up document data
      this.document = {
        'products': products,
        'products_block': totals.products_block,
        'totals': totals.totals,
        'invoice_counter_raw': invoice_counter,
        'invoice_counter': 'R' + new Date().getFullYear() + '-' + invoice_counter,
        'timestamp': this.timestamp,
        'recipient': this.recipients[0],
      };
      // update loading
      this.loading = false;
    }
    else {
      // TODO: error handling
    }
  }
  // change recipient
  changeRecipient(event: any) {
    this.document.recipient = this.recipients[event.target.value];
  }
  // check product visibility
  checkProductVisibility(product_id: any) {
    if (this.type == 'invoice_new') {
      return this.bid_basis.acf.content.products.find((o: any) => o.id === product_id);
    }
    else {
      return this.document.products.find((o: any) => o.id === product_id);
    }
  }
  // toggle product
  toggleProduct(event: any) {
    if (event.target.checked && this.product_connections.find((o: any) => o.id === parseInt(event.target.value))) {
      var product = this.prepareProduct(this.product_connections.find((o: any) => o.id === parseInt(event.target.value)));
      this.document.products.push(product);
    }
    else {
      var index = this.document.products.map((o: any) => o.id).indexOf(parseInt(event.target.value));
      this.document.products.splice(index, 1);
    }
    // calculate totals
    var totals = this.calculateTotals(this.document.products);
    this.document.products_block = totals.products_block;
    this.document.totals = totals.totals;
  }
  // prepare product
  prepareProduct(product_object: any) {
    var product = {
      'name': product_object.acf.product.acf.name,
      'amount': product_object.acf.amount,
      'tax': parseFloat(product_object.acf.product.acf.tax),
      'price_single': parseFloat(product_object.acf.price_single),
      'price': parseFloat(product_object.acf.price),
      'price_tax': parseFloat(product_object.acf.price) / 100 * parseFloat(product_object.acf.product.acf.tax),
      'price_total': parseFloat(product_object.acf.price_total),
      'event': product_object.acf.event ? product_object.acf.event.acf.name : false,
      'type': parseInt(product_object.acf.product.acf.type),
      'desc': product_object.acf.product.acf.desc,
      'id': product_object.id,
    }
    return product;
  }
  // calculate totals
  calculateTotals(products: any) {
    // set up products block object
    var products_block: any = {
      1: {
        products: [],
        type: 'Eigenleistung',
      },
      2: {
        products: [],
        type: 'Fremdleistung',
      },
      3: {
        products: [],
        type: 'Durchlaufposten',
      },
    };
    // divide all products into blocks
    products.forEach((element: any) => {
      products_block[element.type].products.push(element);
    });
    // totals object
    var totals: any = {
      netto: 0,
      brutto: 0,
    };
    // iterate over all product blocks
    Object.values(products_block).forEach((element: any) => {
      // init helpers
      var sum = 0;
      var sum_total = 0;
      var tax: any = {};
      // iterate over all products in product block
      Object.values(element.products).forEach((prod: any) => {
        // update helpers
        sum+= parseFloat(prod.price);
        sum_total+= parseFloat(prod.price_total);
        if (tax[prod.tax] === undefined) {
          tax[prod.tax] = {
            price: 0,
            tax: 0,
          };
        }
        tax[prod.tax].price+= prod.price;
        tax[prod.tax].tax+= prod.price_tax;
        if (totals[prod.tax] === undefined) {
          totals[prod.tax] = {
            price: 0,
            tax: 0,
          };
        }
        totals[prod.tax].price+= prod.price;
        totals[prod.tax].tax+= prod.price_tax;
      });
      // write helpers into respective objects
      element.sum = sum;
      element.sum_total = sum_total;
      element.tax = tax;
      totals.netto+= sum;
      totals.brutto+= sum_total;
    });

    var totals_object = {
      'products_block': products_block,
      'totals': totals,
    }

    return totals_object;
  }
  // save bid
  async saveBid() {
    // loading update
    this.loading = true;
    // prepare data
    var data = JSON.stringify({
      'title': this.document.bid_counter + ': ' + this.decease.acf.firstname + ' ' + this.decease.acf.lastname + ' (' + this.user.acf.undertaker.post_title + ')',
      'fields': {
        'type': 'bid',
        'timestamp': new Date(),
        'content': JSON.stringify(this.document),
        'undertaker': this.user.acf.undertaker.ID,
        'decease_id': this.decease.id,
        'status': 1,
      },
      'status': 'publish',
    });
    // create document
    this.wp.createDocument(data).subscribe(async (response:any) => {
      // store document id
      var document_id = response.id;
      // update product connections
      for (const object of this.product_connections) {
        if (this.document.products.find((o: any) => o.id === parseInt(object.id))) {
          var data = JSON.stringify({
            'fields': {
              'bid': document_id,
            }
          });
        }
        else {
          var data = JSON.stringify({
            'fields': {
              'bid': '',
            }
          });
        }
        await firstValueFrom(this.wp.updateProductConnection(object.id, data));
      };
      // update bid counter
      var data = JSON.stringify({
        'fields': {
          'bid_counter': this.document.bid_counter_raw,
        }
      });
      this.wp.updateUndertaker(this.user.acf.undertaker.ID, data).subscribe((response:any) => {
        // redirect to created document
        this.router.navigate(['dashboard/document/' + document_id]);
      });
    });
  }
  // save invoice
  async saveInvoice() {
    // loading update
    this.loading = true;
    // prepare data
    var data = JSON.stringify({
      'title': this.document.invoice_counter + ': ' + this.decease.acf.firstname + ' ' + this.decease.acf.lastname + ' (' + this.user.acf.undertaker.post_title + ')',
      'fields': {
        'type': 'invoice',
        'timestamp': new Date(),
        'content': JSON.stringify(this.document),
        'undertaker': this.user.acf.undertaker.ID,
        'decease_id': this.decease.id,
        'status': 1,
      },
      'status': 'publish',
    });
    // create document
    this.wp.createDocument(data).subscribe(async (response:any) => {
      // store document id
      var document_id = response.id;
      // update product connections
      for (const object of this.product_connections) {
        if (this.document.products.find((o: any) => o.id === parseInt(object.id))) {
          var data = JSON.stringify({
            'fields': {
              'invoice': document_id,
            }
          });
        }
        else {
          var data = JSON.stringify({
            'fields': {
              'invoice': '',
            }
          });
        }
        await firstValueFrom(this.wp.updateProductConnection(object.id, data));
      };
      // update invoice counter
      var data = JSON.stringify({
        'fields': {
          'invoice_counter': this.document.invoice_counter_raw,
        }
      });
      this.wp.updateUndertaker(this.user.acf.undertaker.ID, data).subscribe((response:any) => {
        // redirect to created document
        this.router.navigate(['dashboard/document/' + document_id]);
      });
    });
  }
  // get existing document
  async getDocument(document_id: any) {
    // get document
    this.document = await firstValueFrom(this.wp.getDocument(document_id));
    // check if document belongs to current undertaker and is published
    if (this.user.acf.undertaker.ID == this.document.acf.undertaker.ID && this.document.status == 'publish') {
      // get decease id
      var decease_id = this.document.acf.decease_id;
      // get document status
      var document_status = this.document.acf.status;
      // set document type
      this.type = this.document.acf.type;
      // get only needed data
      this.document = this.document.acf.content;
      // write document status
      this.document.status = document_status;
      // get decease
      this.decease = await firstValueFrom(this.wp.getDecease(decease_id));
      // get recipients
      this.recipients = await firstValueFrom(this.wp.getContacts(decease_id));
      // get available product connections
      this.product_connections = await firstValueFrom(this.wp.getProductConnections(decease_id));
      var available_products: any = [];
      this.product_connections.forEach((element: any) => {
        // product is already part of document
        if (this.document.products.find((o: any) => o.id === parseInt(element.id))) {
            available_products.push(element);
        }
        // product is not part of document
        else {
          // bid
          if (this.type == 'bid') {
            // product is not part of any other document with same type
            if (!element.acf.bid ||  element.acf.bid == '') {
              available_products.push(element);
            }
          }
          // invoice
          else if (this.type == 'invoice') {
            // product is not part of any other document with same type
            if (!element.acf.invoice ||  element.acf.invoicevoice == '') {
              available_products.push(element);
            }
          }
        }
      });
      this.product_connections = available_products;
      // update loading
      this.loading = false;
    }
    else {
      // TODO error handling
    }
  }
  // update bid
  updateBid() {
    // loading update
    this.loading = true;
    // prepare data
    var data = JSON.stringify({
      'fields': {
        'content': JSON.stringify(this.document),
      },
    });
    // update document
    this.wp.updateDocument(this.document_id, data).subscribe(async (response:any) => {
      // update product connections
      for (const object of this.product_connections) {
        if (this.document.products.find((o: any) => o.id === parseInt(object.id))) {
          var data = JSON.stringify({
            'fields': {
              'bid': this.document_id,
            }
          });
        }
        else {
          var data = JSON.stringify({
            'fields': {
              'bid': '',
            }
          });
        }
        await firstValueFrom(this.wp.updateProductConnection(object.id, data));
      };
      // get document
      this.getDocument(this.document_id);
    });
  }
  // update invoice
  updateInvoice() {
    // loading update
    this.loading = true;
    // prepare data
    var data = JSON.stringify({
      'fields': {
        'content': JSON.stringify(this.document),
      },
    });
    // update document
    this.wp.updateDocument(this.document_id, data).subscribe(async (response:any) => {
      // update product connections
      for (const object of this.product_connections) {
        if (this.document.products.find((o: any) => o.id === parseInt(object.id))) {
          var data = JSON.stringify({
            'fields': {
              'invoice': this.document_id,
            }
          });
        }
        else {
          var data = JSON.stringify({
            'fields': {
              'invoice': '',
            }
          });
        }
        await firstValueFrom(this.wp.updateProductConnection(object.id, data));
      };
      // get document
      this.getDocument(this.document_id);
    });
  }
  // update document status
  async updateDocumentStatus(event: any) {
    // update loading
    this.loading = true;
     // set data
    var data = JSON.stringify({
      'fields': {
        'status': event.target.value,
      },
    });
    // update document status
    await firstValueFrom(this.wp.updateDocument(this.document_id, data));
    // old status is not "canceled"
    if (this.document.status != 3) {
      // new status is "canceled"
      if (parseInt(event.target.value) == 3) {
        // set data
        if (this.type == 'bid') {
          var data = JSON.stringify({
            'fields': {
              'bid': '',
            }
          });
        }
        else if (this.type == 'invoice') {
          var data = JSON.stringify({
            'fields': {
              'invoice': '',
            }
          });
        }
        // remove product connections
        this.document.products.forEach(async (element: any) => {
          await firstValueFrom(this.wp.updateProductConnection(element.id, data));
        });
      }
      // get document
      this.getDocument(this.document_id);
    }
    // old status is "canceled"
    else {
      // set still available products
      var available_products: any = [];
      this.document.products.forEach(async (element: any) => {
          var product = this.product_connections.find((x:any) => x.id == element.id);
          if (product.acf.bid == false || product.acf.bid == '') {
            available_products.push(product);
          }
      });
      // reset price
      var price : any = 0;
      // prepare data
      available_products.forEach((element: any, index: any) => {
        available_products[index] = this.prepareProduct(element);
        price += parseFloat(element.acf.price_total);
      });
      // update product connections
      this.product_connections = available_products;
      // calculate totals
      var totals = this.calculateTotals(available_products);
      // update document data
      this.document.products = available_products;
      this.document.products_block = totals.products_block;
      this.document.totals = totals.totals;
      // update bid
      this.updateBid();
    }
  }
  // change bid basis
  changeBidBasis(event: any) {
    // get bid basis
    this.bid_basis = this.bids.find((o: any) => o.id === parseInt(event.target.value));
    // reset document products
    this.document.products = [];
    this.document.products_block = [];
    this.document.totals = [];
    // add products
    this.bid_basis.acf.content.products.forEach((element: any) => {
      var event = {
        target: {
          value: element.id,
          checked: true,
        }
      }
      this.toggleProduct(event);
    });
  }
  // download as pdf
  downloadAsPDF() {
    // const doc = new jsPDF();
    const pdfContainer = this.pdfContainer.nativeElement;
    const pdfFooter = this.pdfFooter.nativeElement;
    var html = htmlToPdfmake(pdfContainer.innerHTML, {
      imagesByReference: true,
    });
    var footer = htmlToPdfmake(pdfFooter.innerHTML, {
      imagesByReference: true,
    });
    const documentDefinition = {
      content: html.content,
      images: html.images,
      tableAutoSize: true,
      pageSize: 'A4',
      pageMargins: [ 55, 55, 55, 105],
      footer: {
        columns: [
          footer.content,
        ]
      },
      styles: {
        'html-table': {
          'border': 'none',
        },
        'html-tr': {
          'border': 'none',
        },
        'html-td': {
          'border': 'none',
        },
        'headline': {
          'color': 'red',
          'background': 'blue',
          'border': '2'
        },
        'text-right': {
          'alignment': 'right',
        },
        'desc': {
          'color': '#666',
          'fontSize': '9',
        },
         'text-size-small': {
          'fontSize': '10',
        },
         'text-size-large': {
          'fontSize': '13',
          'bold': 'true'
        },
        'text-size-medium': {
          'fontSize': '11',
          'bold': 'true'
        },
        'color-grey': {
          'color': '#666',
        },
        'color-white': {
          'color': '#fff',
        },
      }
    };
    pdfMake.createPdf(documentDefinition).download('funos.pdf');
  }
}
