<div *ngIf="data" class="mt-2">
	<div *ngFor="let entry of data; let i = index" class="flex gap-4 items-center my-2">
		<input type="text" [value]="entry" (input)="updateListfield()" class="listfield_entry" [id]="unique_id + '_listfield_entry_' + i" />
		<div (click)="deleteField(i, 'old')" class="cursor-pointer py-4">
			<svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 1H15" stroke="#DC3545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>
	</div>
	<div class="flex gap-2 items-center">
		<button class="secondary" (click)="addField()">Hinzufügen</button>
		<button class="warning" (click)="deleteAllFields()" *ngIf="data.length > 0">Alle entfernen</button>
	</div>
</div>