import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError, throwError } from 'rxjs';
import { CookieService } from './cookie.service';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root',
  })

export class WpService {

    public apiHost: String = environment.wp_api;

    constructor(
    	private http: HttpClient,
    	private cookie: CookieService) {

    }

	getToken(user: String, password: String) {
		return this.http
		.post(this.apiHost + '/jwt-auth/v1/token', {
			username: user,
			password: password
		})
		.pipe(map((response) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error)
		}));
	}

	validateToken(token: any) {
		return this.http
		.post(this.apiHost + '/jwt-auth/v1/token/validate', null, {
			headers: { 'Authorization': 'Bearer ' + token }
		})
		.pipe(map((response: any) => {
			if (response.code == 'jwt_auth_valid_token') {
				return true;
			}
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}	

	getUserData() {
		return this.http
		.get(this.apiHost + '/wp/v2/users/me', {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {return response;}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	// update user data (acf fields)
	updateUserData(id: any, data: String) {
		return this.http
		.post(this.apiHost + '/acf/v3/users/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	// update user data (wp fields)
	updateUserDataWp(id: any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/users/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	getDeceases(undertaker:any, archive: boolean = false) {
		// api url
		switch(archive) {
			// active deceases
			case false:
				var apiUrl = '/wp/v2/decease?undertaker=' + undertaker + '&status=1,2';
				break;
			// archived deceases
			case true:
				var apiUrl = '/wp/v2/decease?undertaker=' + undertaker + '&status=3';
				break;
		}
		// get deceases
		return this.http
		.get(this.apiHost + apiUrl, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {
			// iterate over all deceases
			Object.values(response).forEach((element: any) => {
				// set status text
				switch (element.acf.status) {
					case "1":
						element.acf.status_text = 'offen';
						break;
					case "2":
						element.acf.status_text = 'in Bearbeitung';
						break;
					case "3":
						element.acf.status_text = 'abgeschlossen';
						break;
					default:
						element.acf.status_text = 'offen';
						break;
				}
			});
			return response;
		}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	getDecease(id:any) {
		return this.http
		.get(this.apiHost + '/wp/v2/decease/' + id, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {return response;}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	updateDecease(id:any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/decease/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	createDecease(data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/decease/', data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	deleteDecease(id: any) {
		return this.http
		.delete(this.apiHost + '/wp/v2/decease/' + id, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	getProducts(undertaker:any) {
		return this.http
		.get(this.apiHost + '/wp/v2/product?undertaker=' + undertaker, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {
			// calc final price with tax
			Object.values(response).forEach((element: any) => {
				element.acf.price_total = parseFloat(element.acf.price) + parseFloat(element.acf.price) * parseFloat(element.acf.tax) / 100;
			})
			return response;
		}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	getProduct(id:any) {
		return this.http
		.get(this.apiHost + '/wp/v2/product/' + id, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {return response;}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	updateProduct(id:any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/product/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	createProduct(data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/product/', data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	deleteProduct(id: any) {
		return this.http
		.delete(this.apiHost + '/wp/v2/product/' + id, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	getProductConnections(decease:any) {
		return this.http
		.get(this.apiHost + '/wp/v2/pconnection?decease=' + decease, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {
			// calc final price with tax
			Object.values(response).forEach((element: any) => {
				element.acf.price_total = parseFloat(element.acf.price) + parseFloat(element.acf.price) * parseFloat(element.acf.product.acf.tax) / 100;
			})
			return response;
		}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	createProductConnection(data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/pconnection/', data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	updateProductConnection(id:any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/pconnection/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	deleteProductConnection(id: any) {
		return this.http
		.delete(this.apiHost + '/wp/v2/pconnection/' + id, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	getEventTemplates(undertaker:any) {
		return this.http
		.get(this.apiHost + '/wp/v2/event-template?undertaker=' + undertaker, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {return response;}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	createEventTemplate(data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/event-template/', data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	updateEventTemplate(id:any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/event-template/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	deleteEventTemplate(id: any) {
		return this.http
		.delete(this.apiHost + '/wp/v2/event-template/' + id, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	getProcessTemplate(id: any) {
		return this.http
		.get(this.apiHost + '/wp/v2/process-template/' + id, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {return response;}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	getProcessTemplates(undertaker: any) {
		return this.http
		.get(this.apiHost + '/wp/v2/process-template?undertaker=' + undertaker, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {return response;}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	createProcessTemplate(data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/process-template/', data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	updateProcessTemplate(id:any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/process-template/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	deleteProcessTemplate(id: any) {
		return this.http
		.delete(this.apiHost + '/wp/v2/process-template/' + id, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	getEvents(decease:any) {
		return this.http
		.get(this.apiHost + '/wp/v2/event?decease=' + decease, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response: any) => {
			// reverse
			response.reverse();
			// sort
			response.sort((a: any,b: any) => a.acf.sort - b.acf.sort);
			// return
			return response;
		}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	createEvent(data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/event/', data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	updateEvent(id:any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/event/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	deleteEvent(id: any) {
		return this.http
		.delete(this.apiHost + '/wp/v2/event/' + id, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	getDocument(id:any) {
		return this.http
		.get(this.apiHost + '/wp/v2/document/' + id, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response: any) => {
			if (response.acf.content != null && response.acf.content != '') {
				response.acf.content = JSON.parse(response.acf.content);
			}
			return response;
		}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	getDocuments(decease: any) {
		return this.http
		.get(this.apiHost + '/wp/v2/document?decease_id=' + decease, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response: any) => {
			response.forEach((element: any) => {
				if (element.acf.content != null && element.acf.content != '') {
					element.acf.content = JSON.parse(element.acf.content);
				}
			});
			// return
			return response;
		}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	createDocument(data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/document/', data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	updateDocument(id:any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/document/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	deleteDocument(id: any) {
		return this.http
		.delete(this.apiHost + '/wp/v2/document/' + id, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	getContacts(decease: any) {
		return this.http
		.get(this.apiHost + '/wp/v2/contact_person?decease_id=' + decease, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response: any) => {
			// return
			return response;
		}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	createContact(data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/contact_person/', data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	updateContact(id:any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/contact_person/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	deleteContact(id: any) {
		return this.http
		.delete(this.apiHost + '/wp/v2/contact_person/' + id, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	getUndertaker(id:any) {
		return this.http
		.get(this.apiHost + '/wp/v2/undertaker/' + id, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {return response;}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	updateUndertaker(id:any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/undertaker/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	uploadFile(file: any, name: string) {
		return this.http
		.post(this.apiHost + '/wp/v2/media/', file, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json',
				'Content-Disposition': 'attachment; filename=' + name,
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	getCustomers() {
		return this.http
		.get(this.apiHost + '/wp/v2/undertaker/', {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {return response;}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	getDeceasesForBilling(undertaker: any, date_range: any) {
		// get deceases
		return this.http
		.get(this.apiHost + '/wp/v2/decease?undertaker=' + undertaker + '&after=' + date_range.after + '&before=' + date_range.before, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response) => {
			return response;
		}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	getInvoices(undertaker: any) {
		return this.http
		.get(this.apiHost + '/wp/v2/document?undertaker=' + undertaker, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response: any) => {
			var invoices: any = [];
			response.forEach((element: any) => {
				if (element.acf.type == 'invoice') {
					element.acf.content = JSON.parse(element.acf.content);
					invoices.push(element);
				}
			});
			// return
			return invoices;
		}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	getAppointments(undertaker: any) {
		return this.http
		.get(this.apiHost + '/wp/v2/appointment?undertaker=' + undertaker, {
			headers: { 'Authorization': 'Bearer ' + this.cookie.get('funos_token') }
		})
		.pipe(map((response: any) => {
			// filter
			response = response.filter((a: any) => {
				const date = new Date(a.acf.date);
				a.acf.time = date.getHours() + ':' + date.getMinutes() + ' Uhr';
				if (date.getHours() === 0 && date.getMinutes()=== 0 && date.getSeconds() === 0) {
					date.setHours(23, 59, 59, 999);
					a.acf.time = 'ganztags';
				}
			 	return date.getTime() > Date.now();
			});
			// sort
			response.sort((a: any, b: any) => new Date(a.acf.date).getTime() - new Date(b.acf.date).getTime());
			// return
			return response;
		}))
		.pipe(catchError(error => {return throwError(error)}));
	}

	createAppointment(data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/appointment/', data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}

	updateAppointment(id: any, data: String) {
		return this.http
		.post(this.apiHost + '/wp/v2/appointment/' + id, data, {
			headers: { 
				'Authorization': 'Bearer ' + this.cookie.get('funos_token'),
				'Content-Type': 'application/json'
			}
		})
		.pipe(map((response: any) => {
			return response;
		}))
		.pipe(catchError(error => {
			return throwError(error);
		}));
	}
}