<div class="relative">
  <input type="hidden" name="date" x-ref="date" />
  <input *ngIf="controlName"
    (input)="showDatepicker = false"
    type="text"
    [formControlName]="controlName"
    id="datepickerValue"
    (keydown.escape)="showDatepicker = false"
    placeholder=""
  />
  <input *ngIf="!controlName"
    (input)="showDatepicker = false"
    type="text"
    [(ngModel)]="datepicker_value"
    [ngModelOptions]="{standalone: true}"
    attr.id="custom_field_content_{{index}}"
    (keydown.escape)="showDatepicker = false"
    placeholder=""
  />

  <div (click)="toggleDatepicker()" class="absolute right-4 top-2/4 -translate-y-2/4 cursor-pointer">
    <svg class="h-6 w-6 text-grey" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  </div>
  <div
    class="bg-white mt-14 rounded border border-grey-light p-4 absolute top-0 left-0 z-10"
    style="width: 17rem"
    [hidden]="!showDatepicker"
    (keydown.away)="showDatepicker = false"
  >
    <div class="flex justify-between items-center mb-2">
      <div>
        <span class="text-lg font-bold text-grey">{{ MONTH_NAMES[month] }}</span>
        <span class="ml-1 text-lg text-grey font-normal">{{ year }}</span>
      </div>
      <div>
        <button
          type="button"
          class="
            transition
            ease-in-out
            duration-100
            inline-flex
            cursor-pointer
            hover:bg-gray-200
            p-1
            rounded-full
          "
          (click)="updateDatepicker('-')"
          (click)="getNoOfDays(year, month)"
        >
          <svg
            class="h-6 w-6 text-grey inline-flex"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <button
          type="button"
          class="
            transition
            ease-in-out
            duration-100
            inline-flex
            cursor-pointer
            hover:bg-gray-200
            p-1
            rounded-full
          "
          (click)="updateDatepicker('+')"
          (click)="getNoOfDays(year, month)"
        >
          <svg
            class="h-6 w-6 text-grey inline-flex"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>

    <div class="flex flex-wrap mb-3 -mx-1">
      <ng-container *ngFor="let day of DAYS; let index = index">
        <div style="width: 14.26%" class="px-1">
          <div class="text-grey font-medium text-center text-xs">{{ day }}</div>
        </div>
      </ng-container>
    </div>

    <div class="flex flex-wrap -mx-1">
      <ng-container *ngFor="let blankday of blankdays">
        <div style="width: 14.28%" class="text-center border-none p-1 border-transparent text-sm"></div>
      </ng-container>
      <ng-container *ngFor="let date of no_of_days; let dateIndex = index; trackBy: trackByIdentity">
        <div style="width: 14.28%" class="px-1 mb-1">
          <div
            (click)="getDateValue(date)"
            class="
              cursor-pointer
              text-center text-sm
              rounded-full
              leading-loose
              transition
              ease-in-out
              duration-100
            "
            [ngClass]="{
              'bg-primary text-grey': isDate(date) === true,
              'text-grey hover:bg-sand': isDate(date) === false
            }"
          >
            {{ date }}
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="time">
      <div class="flex justify-between items-center mt-4">
        <p>Uhrzeit</p>
        <div>
          <label class="relative inline-flex items-center cursor-pointer">
            <input (change)="toggleTime()" type="checkbox" value="" [checked]="time_on" class="sr-only peer">
            <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-signal-red peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-signal-green"></div>
          </label>
        </div>
      </div>
      <div *ngIf="time_on">
        <div class="mt-2 flex justify-between items-center gap-4">
            <input (input)="getDateValue(day)" [(ngModel)]="hour" [ngModelOptions]="{standalone: true}" type="text">
            <span> : </span>
            <input (input)="getDateValue(day)" [(ngModel)]="min" [ngModelOptions]="{standalone: true}"  type="text">
        </div>
      </div>
      <button (click)="toggleDatepicker()" type="submit" class="primary mt-4">Speichern</button>
    </div>
  </div>
</div>