<div *ngIf="show_error">404 - Kein Sterbefall gefunden!</div>
<div *ngIf="show_data" class="decease">
  <!-- header -->
  <div class="py-8 md:flex md:items-center md:justify-between">
  	<div class="min-w-0 flex-1">
  		<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {{ form_decease.value.firstname != '' ? form_decease.value.firstname + ' ' + form_decease.value.lastname : 'Neuer Sterbefall' }}
      </h2>
  	</div>
   	<div class="mt-4 flex gap-2 md:ml-4 md:mt-0">
      <button *ngIf="!decease_id" [routerLink]="['/dashboard/deceases']" type="button" class="secondary">Abbrechen</button>
      <button *ngIf="decease_id" [routerLink]="['/dashboard/deceases']" type="button" class="secondary">Zurück</button>
      <button *ngIf="decease_id" type="submit" class="warning" (click)="deleteDecease(decease_id)">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75 1C7.23122 1 6 2.23122 6 3.75V4.1927C5.20472 4.26972 4.41602 4.36947 3.63458 4.49129C3.22531 4.5551 2.94525 4.9386 3.00906 5.34787C3.07286 5.75714 3.45637 6.0372 3.86564 5.97339L4.01355 5.95062L4.85504 16.4693C4.96938 17.8985 6.16254 19 7.59629 19H12.4035C13.8372 19 15.0304 17.8985 15.1447 16.4693L15.9862 5.95055L16.1346 5.97339C16.5438 6.0372 16.9274 5.75714 16.9912 5.34787C17.055 4.9386 16.7749 4.5551 16.3656 4.49129C15.5841 4.36946 14.7954 4.2697 14 4.19268V3.75C14 2.23122 12.7688 1 11.25 1H8.75ZM10.0001 4C10.8395 4 11.673 4.02523 12.5 4.07499V3.75C12.5 3.05964 11.9404 2.5 11.25 2.5H8.75C8.05964 2.5 7.5 3.05964 7.5 3.75V4.075C8.32707 4.02524 9.16068 4 10.0001 4ZM8.57948 7.72002C8.56292 7.30614 8.21398 6.98404 7.8001 7.0006C7.38622 7.01716 7.06412 7.36609 7.08068 7.77998L7.38069 15.28C7.39725 15.6939 7.74619 16.016 8.16007 15.9994C8.57395 15.9828 8.89605 15.6339 8.87949 15.22L8.57948 7.72002ZM12.9195 7.77998C12.936 7.36609 12.614 7.01715 12.2001 7.0006C11.7862 6.98404 11.4372 7.30614 11.4207 7.72002L11.1207 15.22C11.1041 15.6339 11.4262 15.9828 11.8401 15.9994C12.254 16.016 12.6029 15.6939 12.6195 15.28L12.9195 7.77998Z" fill="#FCFCFC"/>
        </svg>
        <span>Sterbefall löschen</span>
      </button>
    </div>
  </div>
  <!-- tabs -->
  <div class="border-b border-grey-light mb-8 pb-5 sm:pb-0">
    <div class="mt-3 sm:mt-4">
      <div class="sm:block">
        <nav class="-mb-px sm:flex sm:space-x-8 block">
          <a [class.active]="tab == 1" class="tab" (click)="tab = 1">Übersicht</a>
          <a [class.active]="tab == 2" class="tab" (click)="tab = 2">Verstorbener</a>
          <a [class.active]="tab == 3" class="tab" (click)="tab = 3; loadContacts();">Auftraggeber</a>
          <a [class.active]="tab == 6" class="tab" (click)="tab = 6">Dokumente</a>
          <a [class.active]="tab == 4" class="tab" (click)="tab = 4; loadProcess();">Ablauf</a>
          <a [class.active]="tab == 5" class="tab" (click)="tab = 5; loadProducts();">Produkte</a>
        </nav>
      </div>
    </div>
  </div>
  <!-- übersicht -->
  <div *ngIf="tab == 1">
    <!-- meta data -->
    <dl class="mx-auto grid grid-cols-1 gap-px border border-sand sm:grid-cols-2 lg:grid-cols-4">
      <div class="border-b sm:border-r lg:border-b-0 border-grey-light gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
        <dt class="text-sand">Name</dt>
        <dd class="w-full flex-none tracking-tight mt-1">
          <h3>{{ decease?.acf?.firstname ? decease?.acf?.firstname + ' ' + decease?.acf?.lastname : '-'}}</h3>
        </dd>
      </div>
      <div class="border-b lg:border-r lg:border-b-0 border-grey-light gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
        <dt class="text-sand">Sterbetag</dt>
        <dd class="w-full flex-none tracking-tight mt-1">
          <h3>{{ decease?.acf?.deathday ? decease?.acf?.deathday : '-' }}</h3>
        </dd>
      </div>
      <div class="border-b sm:border-r sm:border-b-0 border-grey-light gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
        <dt class="text-sand">Gebiet</dt>
        <dd class="w-full flex-none tracking-tight mt-1">
          <h3>{{ decease?.acf?.region ? decease?.acf?.region : '-' }}</h3>
        </dd> 
      </div>
      <div class="gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
        <dt class="text-sand">Status</dt>
        <dd class="w-full flex-none tracking-tight mt-1">
          <select (change)="updateDeceaseStatus(decease_id, $event)" [value]="decease?.acf?.status ? decease?.acf?.status : '1'" [attr.class]="'status_dropdown text-hl-small border-0 p-0 ' + status_color">
              <option value="1">offen</option>
              <option value="2">in Bearbeitung</option>
              <option value="3">abgeschlossen</option>
            </select>
        </dd>
      </div>
    </dl>
    <ul role="list" class="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
      <!-- bid box -->
      <li class="overflow-hidden border border-sand">
        <div class="flex justify-between items-center gap-x-4 bg-grey-light border-b border-sand p-4">
          <div class="flex items-baseline gap-4">
            <span class="text-txt-large">Kostenvoranschläge</span>
          </div>
          <div class="flex gap-2">
            <button [disabled]="totals['price'] == totals['bid']" type="submit" class="primary" (click)="saveDocument('bid')">Erstellen</button>
          </div>
        </div>
        <dl class="p-4">
          <ng-container *ngFor="let document of documents">
            <div *ngIf="document.acf.type == 'bid'" class="flex items-center justify-between border-b border-1 pb-4 mb-4">
              <div>
                <div class="flex items-center gap-4">
                  <h3>{{ document.acf.content.bid_counter }}</h3>
                  <p [attr.data-value]="document.acf.status" class="document_status">
                    <span *ngIf="document.acf.status == 1">• Erstellt</span>
                    <span *ngIf="document.acf.status == 2">• Genehmigt</span>
                    <span *ngIf="document.acf.status == 3">• Abgelehnt</span>
                  </p>
                </div>
                <p class="text-sand text-txt-small">{{ (document.acf.timestamp | date:"dd.MM.YYYY") }} - {{ document.acf.content.recipient.acf.firstname }} {{ document.acf.content.recipient.acf.lastname }}</p>
              </div>
              <div class="flex items-center gap-2">
                <button *ngIf="decease_id" [routerLink]="['/dashboard/document/' + document.id.split('_')[1]]" type="button" class="secondary">Bearbeiten</button>
              </div>
            </div>
          </ng-container>
          <div class="flex items-center gap-8" *ngIf="totals['bid'] > 0">
            <div>
              <p class="font-bold">KV Betrag</p>
              <p>{{ (totals['bid'] | number:"1.2-2":"de-DE") + " €" }}</p>
            </div>
            <div>
              <p class="font-bold">Aktuelle Kosten</p>
              <p>{{ (totals['price'] | number:"1.2-2":"de-DE") + " €" }}</p>
            </div>
            <div *ngIf="totals['price'] == totals['bid']" class="text-txt-small flex gap-2 items-baseline text-signal-green">
              <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16603 0.126042C9.51067 0.355806 9.6038 0.821458 9.37404 1.1661L4.37404 8.66611C4.2494 8.85306 4.0475 8.97429 3.8239 8.99643C3.6003 9.01857 3.37855 8.93929 3.21967 8.78041L0.21967 5.78041C-0.0732233 5.48752 -0.0732233 5.01264 0.21967 4.71975C0.512563 4.42686 0.987437 4.42686 1.28033 4.71975L3.63343 7.07285L8.12596 0.334055C8.35573 -0.010592 8.82138 -0.103722 9.16603 0.126042Z" fill="#73B82E"/>
              </svg>
              <span>Keine abweichenden Kosten</span>
            </div>
            <div *ngIf="totals['price'] != totals['bid']" class="text-txt-small flex gap-2 items-baseline text-signal-red">
              <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.80789 0.55302C8.91678 0.153376 9.32903 -0.0823241 9.72867 0.0265689L12.8435 0.875294C13.2408 0.983538 13.4765 1.39181 13.3716 1.78997L12.5492 4.91189C12.4437 5.31244 12.0334 5.55161 11.6329 5.44609C11.2324 5.34057 10.9932 4.93032 11.0987 4.52978L11.4363 3.24827C10.1124 4.15807 8.9076 5.184 7.82741 6.30442C7.6877 6.44933 7.49561 6.532 7.29433 6.53384C7.09305 6.53568 6.89947 6.45654 6.75714 6.31421L5 4.55706L1.28033 8.27673C0.987437 8.56963 0.512563 8.56963 0.21967 8.27673C-0.0732233 7.98384 -0.0732233 7.50897 0.21967 7.21607L4.46967 2.96607C4.76256 2.67318 5.23744 2.67318 5.53033 2.96607L7.28597 4.72172C8.35156 3.68222 9.52228 2.72662 10.7936 1.87141L9.33434 1.47381C8.93469 1.36491 8.69899 0.952664 8.80789 0.55302Z" fill="#DC3545"/>
              </svg>
              <span>Abweichende Kosten</span>
            </div>
          </div>
          <p *ngIf="totals['bid'] == 0">Es wurde noch kein Kostenvoranschlag erstellt!</p>
        </dl>
      </li>
      <!-- invoice box -->
      <li class="overflow-hidden border border-sand">
        <div class="flex justify-between items-center gap-x-4 bg-grey-light border-b border-sand p-4">
          <div class="flex items-baseline gap-4">
            <span class="text-txt-large">Rechnungen</span>
          </div>
          <div class="flex gap-2">
            <button [disabled]="totals['price'] == totals['invoice']" type="submit" class="primary" (click)="saveDocument('invoice')">Erstellen</button>
          </div>
        </div>
        <dl class="p-4">
          <ng-container *ngFor="let document of documents">
            <div *ngIf="document.acf.type == 'invoice'" class="flex items-center justify-between border-b border-1 pb-4 mb-4">
              <div>
                <div class="flex items-center gap-4">
                  <h3>{{ document.acf.content.invoice_counter }}</h3>
                  <p [attr.data-value]="document.acf.status" class="document_status">
                    <span *ngIf="document.acf.status == 1">• Erstellt</span>
                    <span *ngIf="document.acf.status == 2">• Genehmigt</span>
                    <span *ngIf="document.acf.status == 3">• Abgelehnt</span>
                  </p>
                </div>
                <p class="text-sand text-txt-small">{{ (document.acf.timestamp | date:"dd.MM.YYYY") }} - {{ document.acf.content.recipient.acf.firstname }} {{ document.acf.content.recipient.acf.lastname }}</p>
              </div>
              <div class="flex items-center gap-2">
                <button *ngIf="decease_id" [routerLink]="['/dashboard/document/' + document.id.split('_')[1]]" type="button" class="secondary">Bearbeiten</button>
              </div>
            </div>
          </ng-container>
          <div class="flex items-center gap-8" *ngIf="totals['invoice'] > 0">
            <div>
              <p class="font-bold">RE Betrag</p>
              <p>{{ (totals['invoice'] | number:"1.2-2":"de-DE") + " €" }}</p>
            </div>
            <div>
              <p class="font-bold">Aktuelle Kosten</p>
              <p>{{ (totals['price'] | number:"1.2-2":"de-DE") + " €" }}</p>
            </div>
            <div *ngIf="totals['price'] == totals['invoice']" class="text-txt-small flex gap-2 items-baseline text-signal-green">
              <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16603 0.126042C9.51067 0.355806 9.6038 0.821458 9.37404 1.1661L4.37404 8.66611C4.2494 8.85306 4.0475 8.97429 3.8239 8.99643C3.6003 9.01857 3.37855 8.93929 3.21967 8.78041L0.21967 5.78041C-0.0732233 5.48752 -0.0732233 5.01264 0.21967 4.71975C0.512563 4.42686 0.987437 4.42686 1.28033 4.71975L3.63343 7.07285L8.12596 0.334055C8.35573 -0.010592 8.82138 -0.103722 9.16603 0.126042Z" fill="#73B82E"/>
              </svg>
              <span>Keine abweichenden Kosten</span>
            </div>
            <div *ngIf="totals['price'] != totals['invoice']" class="text-txt-small flex gap-2 items-baseline text-signal-red">
              <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.80789 0.55302C8.91678 0.153376 9.32903 -0.0823241 9.72867 0.0265689L12.8435 0.875294C13.2408 0.983538 13.4765 1.39181 13.3716 1.78997L12.5492 4.91189C12.4437 5.31244 12.0334 5.55161 11.6329 5.44609C11.2324 5.34057 10.9932 4.93032 11.0987 4.52978L11.4363 3.24827C10.1124 4.15807 8.9076 5.184 7.82741 6.30442C7.6877 6.44933 7.49561 6.532 7.29433 6.53384C7.09305 6.53568 6.89947 6.45654 6.75714 6.31421L5 4.55706L1.28033 8.27673C0.987437 8.56963 0.512563 8.56963 0.21967 8.27673C-0.0732233 7.98384 -0.0732233 7.50897 0.21967 7.21607L4.46967 2.96607C4.76256 2.67318 5.23744 2.67318 5.53033 2.96607L7.28597 4.72172C8.35156 3.68222 9.52228 2.72662 10.7936 1.87141L9.33434 1.47381C8.93469 1.36491 8.69899 0.952664 8.80789 0.55302Z" fill="#DC3545"/>
              </svg>
              <span>Abweichende Kosten</span>
            </div>
          </div>
          <p *ngIf="totals['invoice'] == 0">Es wurde noch keine Rechnung erstellt!</p>
        </dl>
      </li>
    </ul>
  </div>
  <!-- verstorbener -->
  <form *ngIf="tab == 2" [formGroup]="form_decease">
    <div class="space-y-12">
      <div class="pb-12">
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <label>Bild</label>
             <ng-container *ngIf="decease.acf?.image?.url">
              <img [src]="decease.acf?.image?.url" width="200" class="mt-2" />
            </ng-container>
            <div class="mt-2">
              <input class="" (change)="onFileChange($event)" id="file_input" type="file">
            </div>
          </div>
          <div class="sm:col-span-6">
            <label>Geschlecht</label>
            <div class="mt-2">
              <select formControlName="gender">
                <option value="männlich">männlich</option>
                <option value="weiblich">weiblich</option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Vorname (*)</label>
            <div class="mt-2">
              <input formControlName="firstname" type="text">
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Nachname (*)</label>
            <div class="mt-2">
              <input formControlName="lastname" type="text">
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Geburtstag (*)</label>
            <div class="mt-2">
              <app-datepicker controlName="birthday"></app-datepicker>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Sterbetag (*)</label>
            <div class="mt-2">
              <app-datepicker controlName="deathday"></app-datepicker>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Gebiet (*)</label>
            <div class="mt-2">
              <select formControlName="region">
                <option *ngFor="let region of undertaker?.acf?.regions" [value]="region">{{ region }}</option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Adresse</label>
            <div class="mt-2">
              <input formControlName="address" type="text">
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>PLZ</label>
            <div class="mt-2">
              <input formControlName="zip" type="text">
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Ort</label>
            <div class="mt-2">
              <input formControlName="city" type="text">
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Religionsbekenntnis</label>
            <div class="mt-2">
              <select formControlName="religion">
                <option value="Katholisch">Katholisch</option>
                <option value="Evangelisch">Evangelisch</option>
                <option value="Alevitisch">Alevitisch</option>
                <option value="Altkatholisch">Altkatholisch</option>
                <option value="Armenisch-apostolisch">Armenisch-apostolisch</option>
                <option value="Evangelisch-methodistisch">Evangelisch-methodistisch</option>
                <option value="Anhänger der Freikirchen">Anhänger der Freikirchen</option>
                <option value="Griechisch-orientalisch / orthodox">Griechisch-orientalisch / orthodox</option>
                <option value="Islamisch">Islamisch</option>
                <option value="Israelitisch">Israelitisch</option>
                <option value="Zeugen Jehovas">Zeugen Jehovas</option>
                <option value="Mitglied der Kirche Jesu Christi der Heiligen der Letzten Tage">Mitglied der Kirche Jesu Christi der Heiligen der Letzten Tage</option>
                <option value="Koptisch-orthodox">Koptisch-orthodox</option>
                <option value="Neuapostolisch">Neuapostolisch</option>
                <option value="Buddhistisch">Buddhistisch</option>
                <option value="Syrisch-orthodox">Syrisch-orthodox</option>
                <option value="Ohne Bekenntnis">ohne Bekenntnis</option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Familienstand</label>
            <div class="mt-2">
              <select formControlName="maritalstatus">
                <option value="ledig">ledig</option>
                <option value="verheiratet">verheiratet</option>
                <option value="geschieden">geschieden</option>
                <option value="verwitwet">verwitwet</option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Sterbeort</label>
            <div class="mt-2">
              <input formControlName="placeofdeath" type="text">
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Durch Totenbeschau freigegeben</label>
            <div class="mt-2">
              <select formControlName="deathinspection">
                <option value="Ja">Ja</option>
                <option value="Nein">Nein</option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label>Pensionsversicherungsträger</label>
            <div class="mt-2">
              <app-customselect controlName="pensioninsurance" options="PVA,BVAEB,SVS,VAN,Keine"></app-customselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!loading_decease" class="mt-6 flex items-center justify-end gap-x-6">
      <button type="submit" class="primary" [disabled]="form_decease.invalid" (click)="updateDecease(decease_id)">Speichern</button>
    </div>
    <div *ngIf="loading_decease" class="text-right mt-6">
      <div role="status">
        <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#333333"/>
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="saved" class="rounded bg-signal-green p-4 my-4">
      <p class="text-white">Sterbefall wurde erfolgreich gespeichert</p>
    </div>
  </form>
  <!-- auftraggeber -->
  <div *ngIf="tab == 3" class="mt-6">
    <div *ngIf="!loading_contacts">
      <!-- notes -->
      <div class="rounded bg-signal-orange p-4 mb-4" *ngIf="!needed_documents.dsgvo_confirmation && documents.length > 0">
        <p class="text-white">Es wurde noch keine Datenschutzerklärung hochgeladen!</p>
        <button type="button" class="tertiary mt-4" (click)="tab = 6; openDocument(false, 'dsgvo_confirmation');">Jetzt hochladen</button>
      </div>
      <div class="rounded bg-signal-green p-4 mb-4" *ngIf="needed_documents.dsgvo_confirmation && documents.length > 0">
        <p class="text-white">Datenschutzerklärung wurde hochgeladen.</p>
      </div>
      <!-- list -->
      <app-list [data]="list_data_contacts" (action)="processListActionContacts($event)"></app-list>
      <button type="submit" class="primary" (click)="openContact(false)">Kontakt hinzufügen</button>
      <div *ngIf="contact_slide_over" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <div class="pointer-events-auto w-screen max-w-5xl">
                <div class="flex h-full flex-col overflow-y-scroll bg-white p-6 shadow-xl">
                  <div>
                    <div class="flex justify-end mb-2">
                      <a (click)="contact_slide_over = false" type="button" class="cursor-pointer">
                        <span class="sr-only">Schließen</span>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.5 22.5L22.5 7.5M7.5 7.5L22.5 22.5" stroke="#505050" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </a>
                    </div>
                    <div class="flex items-start justify-between mb-4">
                      <h2 id="slide-over-title">Kontakt</h2>
                    </div>
                  </div>
                  <div>
                    <form [formGroup]="form_contact">
                      <div>
                        <div class="pb-12">
                          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div class="sm:col-span-3">
                              <label>Vorname (*)</label>
                              <div class="mt-2">
                                <input formControlName="firstname" type="text">
                              </div>
                            </div>
                            <div class="sm:col-span-3">
                              <label>Nachname (*)</label>
                              <div class="mt-2">
                                <input formControlName="lastname" type="text">
                              </div>
                            </div>
                            <div class="sm:col-span-3">
                              <label>Adresse</label>
                              <div class="mt-2">
                                <input formControlName="address" type="text">
                              </div>
                            </div>
                            <div class="sm:col-span-3">
                              <label>PLZ</label>
                              <div class="mt-2">
                                <input formControlName="zip" type="text">
                              </div>
                            </div>
                            <div class="sm:col-span-3">
                              <label>Ort</label>
                              <div class="mt-2">
                                <input formControlName="city" type="text">
                              </div>
                            </div>
                            <div class="sm:col-span-3">
                              <label>Email</label>
                              <div class="mt-2">
                                <input formControlName="email" type="text">
                              </div>
                            </div>
                            <div class="sm:col-span-3">
                              <label>Telefon</label>
                              <div class="mt-2">
                                <input formControlName="phone" type="text">
                              </div>
                            </div>
                            <div class="sm:col-span-3">
                              <label>Beziehung zum Verstorbenen</label>
                              <div class="mt-2">
                                <app-customselect controlName="connection" options="Ehepartner,Elternteil,Bruder/Schwester,Kind,Beauftragter"></app-customselect>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-6 flex items-center justify-end gap-x-6">
                        <button type="submit" class="primary" [disabled]="form_contact.invalid" (click)="saveContact(contact_id)">Speichern</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading_contacts" class="py-4">
      <div role="status">
        <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#333333"/>
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <!-- dokumente -->
  <div *ngIf="tab == 6" class="mt-6">
    <div *ngIf="!loading_documents">
      <!-- warnings -->
      <div class="rounded bg-signal-orange p-4 mb-4" *ngIf="!needed_documents.birth_certificate && documents.length > 0">
        <p class="text-white">Es wurde noch keine Geburtsurkunde hochgeladen!</p>
        <button type="button" class="tertiary mt-4" (click)="tab = 6; openDocument(false, 'birth_certificate');">Jetzt hochladen</button>
      </div>
      <div class="rounded bg-signal-orange p-4 mb-4" *ngIf="!needed_documents.death_certificate && documents.length > 0">
        <p class="text-white">Es wurde noch keine Sterbeurkunde hochgeladen!</p>
        <button type="button" class="tertiary mt-4" (click)="tab = 6; openDocument(false, 'death_certificate');">Jetzt hochladen</button>
      </div>
      <div class="rounded bg-signal-orange p-4 mb-4" *ngIf="!needed_documents.dsgvo_confirmation && documents.length > 0">
        <p class="text-white">Es wurde noch keine Datenschutzerklärung hochgeladen!</p>
        <button type="button" class="tertiary mt-4" (click)="tab = 6; openDocument(false, 'dsgvo_confirmation');">Jetzt hochladen</button>
      </div>
      <!-- list -->
      <app-list [data]="list_data_doc" (action)="processListActionDocuments($event)"></app-list>
      <button type="submit" class="primary" (click)="openDocument(false, false)">Dokument hinzufügen</button>
      <div *ngIf="document_slide_over" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <div class="pointer-events-auto w-screen max-w-5xl">
                <div class="flex h-full flex-col overflow-y-scroll bg-white p-6 shadow-xl">
                  <div>
                    <div class="flex justify-end mb-2">
                      <a (click)="document_slide_over = false" type="button" class="cursor-pointer">
                        <span class="sr-only">Schließen</span>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.5 22.5L22.5 7.5M7.5 7.5L22.5 22.5" stroke="#505050" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </a>
                    </div>
                    <div class="flex items-start justify-between mb-4">
                      <h2 id="slide-over-title">Dokument</h2>
                    </div>
                  </div>
                  <div>
                    <form [formGroup]="form_document">
                      <div class="mt-4">
                        <label>Typ (*)</label>
                        <div class="mt-2">
                          <select formControlName="type" (change)="processDocumentTypeChange()">
                            <option value="bid" *ngIf="totals['price'] != totals['bid']">Kostenvoranschlag</option>
                            <option value="invoice" *ngIf="totals['price'] != totals['invoice']">Rechnung</option>
                            <option value="birth_certificate">Geburtsurkunde</option>
                            <option value="marriage_certificate">Heiratsurkunde</option>
                            <option value="death_certificate">Sterbeurkunde</option>
                            <option value="dsgvo_confirmation">Datenschutzerklärung</option>
                            <option value="other">Sonstiges</option>
                          </select>
                        </div>
                      </div>
                      <div class="mt-4" *ngIf="form_document.value.type != 'bid' && form_document.value.type != 'invoice'">
                        <label>Datei (*)</label>
                        <div class="mt-2">
                           <input type="file" (change)="onDocumentFileChange($event)">
                        </div>
                      </div>
                      <div class="mt-6 flex items-center justify-end gap-x-6">
                        <button type="submit" class="primary" [disabled]="form_document.invalid" (click)="saveDocument(false)">Erstellen</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading_documents" class="py-4">
      <div role="status">
        <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#333333"/>
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <!-- ablauf -->
  <div *ngIf="tab == 4" class="mt-6">
    <div *ngIf="!loading_events">
      <app-list [data]="list_data_events" (action)="processListActionEvents($event)"></app-list>
      <div class="flex items-center gap-4">
        <button type="submit" class="primary" (click)="openEvent(false)">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75V9.25H6.75C6.33579 9.25 6 9.58579 6 10C6 10.4142 6.33579 10.75 6.75 10.75H9.25V13.25C9.25 13.6642 9.58579 14 10 14C10.4142 14 10.75 13.6642 10.75 13.25V10.75H13.25C13.6642 10.75 14 10.4142 14 10C14 9.58579 13.6642 9.25 13.25 9.25H10.75V6.75Z" fill="#0F172A"/>
          </svg>
          <span>Ereignis hinzufügen</span>
        </button>
        <button *ngIf="process_templates.length > 0" type="submit" class="secondary" (click)="openProcess(false)">Prozess laden</button>
      </div>
    </div>
    <div *ngIf="loading_events" class="py-4">
      <div role="status">
        <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#333333"/>
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="event_slide_over" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto w-screen max-w-5xl">
              <div class="flex h-full flex-col overflow-y-scroll bg-white p-6 shadow-xl">
                <div>
                  <div class="flex justify-end mb-2">
                    <a (click)="event_slide_over = false" type="button" class="cursor-pointer">
                      <span class="sr-only">Schließen</span>
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 22.5L22.5 7.5M7.5 7.5L22.5 22.5" stroke="#505050" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </a>
                  </div>
                  <div class="flex items-start justify-between mb-4">
                    <h2 id="slide-over-title">Ereignis</h2>
                  </div>
                </div>
                <div>
                  <div class="mt-4" *ngIf="!event_id">
                    <label>Ereignis aus Vorlage laden</label>
                    <div class="mt-2">
                      <select (change)="prefillEventTemplate($event)">
                        <option value="0">Keine Vorlage verwenden</option>
                        <option *ngFor="let event_template of event_templates" [value]="event_template.id">{{ event_template.acf.name }}</option>
                      </select>
                    </div>
                  </div>
                  <form [formGroup]="form_event">
                    <div class="grid grid-cols-2 gap-8">
                      <div>
                        <div class="mt-4">
                          <label>Ereignisname (*)</label>
                          <div class="mt-2">
                            <input formControlName="name" type="text">
                          </div>
                        </div>
                        <div class="mt-4">
                          <label>Anmerkungen</label>
                          <div class="mt-2">
                            <textarea formControlName="note" rows="4"></textarea>
                          </div>
                        </div>
                        <div *ngIf="custom_fields.length > 0">
                          <div *ngFor="let custom_field of custom_fields; let i = index" class="mt-4">
                            <label>{{ custom_field.name }}</label>
                            <div *ngIf="custom_field.type == 'text'">
                               <input attr.id="custom_field_content_{{i}}" [value]="custom_field.content ? custom_field.content : ''" type="text">
                            </div>
                            <div *ngIf="custom_field.type == 'dropdown'">
                              <select attr.id="custom_field_content_{{i}}">
                                <option *ngFor="let option of custom_field.value" [value]="option" [selected]="(option == custom_field.content) ? true : false">{{ option }}</option>
                              </select>
                            </div>
                            <div *ngIf="custom_field.type == 'checkbox'">
                              <div *ngFor="let checkbox of custom_field.value; let j = index"class="relative flex items-start">
                                <div class="flex items-center">
                                  <div *ngIf="custom_field.content">
                                    <input attr.id="custom_field_content_{{i}}_{{j}}" [checked]="(custom_field.content[j] == true) ? true : false" aria-describedby="comments-description" type="checkbox">
                                  </div>
                                  <div *ngIf="!custom_field.content">
                                    <input attr.id="custom_field_content_{{i}}_{{j}}" aria-describedby="comments-description" type="checkbox">
                                  </div>
                                </div>
                                <div class="ml-3 text-sm leading-6">
                                  <label>{{ checkbox }}</label>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="custom_field.type == 'date'">
                               <app-datepicker [index]="$any(i)" [value]="custom_field.content ? custom_field.content : ''" time="active"></app-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="mt-4">
                          <label class="text-hl-small">Produkte</label>
                          <div *ngFor="let product of products_choice" class="my-3 py-2 px-4 border border-grey-light rounded grid grid-cols-[48px_60%_auto] gap-4 items-center">
                            <div>
                              <img [src]="product.acf.image?.url ? product.acf.image?.url : 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='" class="h-12 w-12 flex-none rounded-full bg-gray-200 object-cover" />
                            </div>
                            <div class="text-txt-large">{{ product.acf.name }}</div>
                            <div class="text-right" style="line-height:0;">
                              <a (click)="removeEventProduct(product)" class="px-3 py-2 bg-signal-red rounded inline-block cursor-pointer">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.37019 4.5L7.19712 9M4.80288 9L4.62981 4.5M9.61382 2.89528C9.78481 2.92111 9.95521 2.94873 10.125 2.97815M9.61382 2.89528L9.0799 9.83628C9.03482 10.4224 8.54607 10.875 7.95821 10.875H4.04179C3.45393 10.875 2.96519 10.4224 2.9201 9.83628L2.38618 2.89528M9.61382 2.89528C9.04061 2.80872 8.46075 2.74243 7.875 2.69716M1.875 2.97815C2.04479 2.94873 2.21519 2.92111 2.38618 2.89528M2.38618 2.89528C2.95939 2.80872 3.53925 2.74243 4.125 2.69716M7.875 2.69716V2.23909C7.875 1.64941 7.41967 1.15712 6.83029 1.13826C6.55461 1.12944 6.27782 1.125 6 1.125C5.72218 1.125 5.44539 1.12944 5.16971 1.13826C4.58033 1.15712 4.125 1.64941 4.125 2.23909V2.69716M7.875 2.69716C7.25628 2.64935 6.63098 2.625 6 2.625C5.36902 2.625 4.74372 2.64935 4.125 2.69716" stroke="#FCFCFC" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div (click)="show_productlist = true; processProductSearch(false)" class="flex gap-4 items-center text-txt-large pb-6 pt-3 cursor-pointer">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_122_1336)">
                              <path d="M15.5 23L30.5 23M23 15.5V30.5" stroke="#A6A6A6" stroke-width="1.32583" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              <circle cx="23" cy="23" r="22.5" stroke="black" stroke-opacity="0.08" stroke-dasharray="2 2"/>
                              <defs>
                              <clipPath id="clip0_122_1336">
                              <rect width="21.2132" height="21.2132" fill="white" transform="translate(23 8) rotate(45)"/>
                              </clipPath>
                              </defs>
                            </svg>
                            <span>Weiteres Produkt verknüpfen</span>
                          </div>
                          <div #product_container *ngIf="show_productlist" class="py-4 px-4 border border-grey-light bg-sand-light h-60 overflow-y-scroll rounded relative">
                            <input (input)="processProductSearch($event); product_container.scrollTop = 0" class="sticky top-0 left-0 mb-2" type="text" placeholder="Suchen ..." />
                            <ng-container *ngFor="let product of products">
                              <div *ngIf="!product.acf.search_excluded" (click)="addEventProduct(product.id)" class="py-2 grid grid-cols-[32px_auto] gap-4 items-center cursor-pointer">
                                <div>
                                  <img [src]="product.acf.image?.url ? product.acf.image?.url : 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='" class="h-8 w-8 flex-none rounded-full bg-gray-200 object-cover" />
                                </div>
                                <div class="text-txt-small">{{ product.acf.name }}</div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-6 flex items-center justify-end gap-x-6">
                      <button type="submit" class="primary" [disabled]="form_event.invalid" (click)="saveEvent(event_id)">Speichern</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="process_slide_over" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto w-screen max-w-5xl">
              <div class="flex h-full flex-col overflow-y-scroll bg-white p-6 shadow-xl">
                <div>
                  <div class="flex justify-end mb-2">
                    <a (click)="process_slide_over = false" type="button" class="cursor-pointer">
                      <span class="sr-only">Schließen</span>
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 22.5L22.5 7.5M7.5 7.5L22.5 22.5" stroke="#505050" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </a>
                  </div>
                  <div class="flex items-start justify-between mb-4">
                    <h2 id="slide-over-title">Prozess</h2>
                  </div>
                </div>
                <div>
                  <div class="mt-4" *ngIf="!event_id">
                    <label class="block text-sm font-medium leading-6 text-gray-900">Vorlage</label>
                    <div class="mt-2">
                      <select #selected_process class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option *ngFor="let process_template of process_templates" [value]="process_template.id">{{ process_template.acf.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-6 flex items-center justify-end gap-x-6">
                    <button type="submit" class="primary" (click)="addProcess()">Laden</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- produkte -->
  <div *ngIf="tab == 5" class="mt-6">
    <div *ngIf="!loading_products">
      <p>Gesamtpreis: {{ totals.price | number:"1.2-2":"de-DE" }} €</p>
      <app-list [data]="list_data_pc" (action)="processListActionProducts($event)"></app-list>
      <button type="submit" class="primary" (click)="openProductConnection(false)">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75V9.25H6.75C6.33579 9.25 6 9.58579 6 10C6 10.4142 6.33579 10.75 6.75 10.75H9.25V13.25C9.25 13.6642 9.58579 14 10 14C10.4142 14 10.75 13.6642 10.75 13.25V10.75H13.25C13.6642 10.75 14 10.4142 14 10C14 9.58579 13.6642 9.25 13.25 9.25H10.75V6.75Z" fill="#0F172A"/>
          </svg>
          <span>Produkt hinzufügen</span>
      </button>
    </div>
    <div *ngIf="loading_products" class="py-4">
      <div role="status">
        <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#333"/>
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="product_slide_over" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto w-screen max-w-5xl">
              <div class="flex h-full flex-col overflow-y-scroll bg-white p-6 shadow-xl">
                <div>
                  <div class="flex justify-end mb-2">
                    <a (click)="product_slide_over = false" type="button" class="cursor-pointer">
                      <span class="sr-only">Schließen</span>
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 22.5L22.5 7.5M7.5 7.5L22.5 22.5" stroke="#505050" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </a>
                  </div>
                  <div class="flex items-start justify-between mb-4">
                    <h2 id="slide-over-title">Produkt</h2>
                  </div>
                </div>
                <div class="rounded bg-signal-orange p-4 mb-4" *ngIf="form_product.value.bid || form_product.value.invoice">
                  <p class="text-white">Dieses Produkt kann aktuell nicht bearbeitet werden, da es Teil eines Kostenvoranschlags bzw. einer Rechnung ist.</p>
                </div>
                <div>
                  <form [formGroup]="form_product">
                    <div class="mt-4">
                      <label>Produkt</label>
                      <div class="mt-2">
                        <select formControlName="product" (change)="calcProductPrice(form_product.controls['amount'].value, 'product')">
                          <option *ngFor="let product of products" [value]="product.id">{{ product.acf.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-4">
                      <label>Anzahl</label>
                      <div class="mt-2">
                        <input formControlName="amount" (change)="calcProductPrice(form_product.controls['amount'].value, 'amount')" type="number">
                      </div>
                    </div>
                    <div class="mt-4">
                      <label>Einzelpreis (Netto)</label>
                      <div class="mt-2">
                        <input formControlName="price_single" (change)="calcProductPrice(form_product.controls['amount'].value, 'price')" type="number">
                      </div>
                    </div>
                    <div class="mt-4">
                      <label>Gesamtpreis (Netto)</label>
                      <div class="mt-2">
                        <input formControlName="price" type="number" readonly="true">
                      </div>
                    </div>
                    <div class="mt-4">
                      <label>Notiz</label>
                      <div class="mt-2">
                        <textarea formControlName="note" rows="4"></textarea>
                      </div>
                    </div>
                    <div class="mt-6 flex items-center justify-end gap-x-6">
                      <button type="submit" class="primary" [disabled]="form_product.invalid || form_product.value.bid || form_product.value.invoice" (click)="saveProductConnection(product_connection_id, form_product.value)">Speichern</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>