import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WpService } from '../../services/wp.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})

export class ProductComponent implements OnInit {
  // logged in user
  @Input() user: any = [];
  // product id
  product_id: String = this.route.snapshot.params?.['id'];
  // product
  product: any = [];
  // form
  form_product: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    image: new FormControl(),
    category: new FormControl('', Validators.required),
    unit: new FormControl('', Validators.required),
    price: new FormControl('', Validators.required),
    tax: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    desc: new FormControl(),
  });
  // show data helper
  show_data = false;
  // show error helper
  show_error = false;
  // loading helper
  loading = false;
  // data saved helper
  saved = false;
  // active tab
  tab = 1;
  // undertaker
  undertaker: any = [];

  constructor(
    private route: ActivatedRoute,
    private wp: WpService,
    private fb: FormBuilder,
    private router: Router) {
  }

  ngOnInit(): void {
    // get product data
    this.getProduct(this.product_id);
    // get undertaker data
    this.getUndertaker(this.user.acf.undertaker.ID);
  }

  // get decease
  getProduct(id : any) {
    // existing product
    if (id) {
      this.wp.getProduct(id).subscribe((response:any) => {
        // check if product belongs to current undertaker and is published
        if (this.user.acf.undertaker.ID == response.acf.undertaker.ID && response.status == 'publish') {
          // save product data
          this.product = response;
          // prefill form
          this.prefillFormProduct();
          // show data
          this.show_data = true;
          // activate tab
          this.tab = 1;
        }
        else {
          // show error
          this.show_error = true;
        }
      });
    }
    // new product
    else {
      // duplicate
      if (this.route.snapshot.queryParamMap.get('duplicate')) {
        // get product which should be duplicated
        this.getProduct(this.route.snapshot.queryParamMap.get('duplicate'));
      }
      // from scratch
      else {
        // show data
        this.show_data = true;
        // activate tab
        this.tab = 1;
      }
    }
  }

  // update product
  updateProduct(id: any) {
    // show loading
    this.loading = true;
    // prepare fields
    this.form_product.value.undertaker = this.user.acf.undertaker.ID;
    // this.form_product.value.price = this.form_product.value.price.replace(',', '.');
    // image change
    if (typeof this.form_product.value.image === 'object' && this.form_product.value.image !== null) {
      // upload image
      this.wp.uploadFile(this.form_product.value.image, this.form_product.value.image.name).subscribe((response:any) => {
        // set image id
        this.form_product.value.image = response.id;
        // prepare data
        var data = JSON.stringify({
          'title': this.form_product.value.name, 
          'fields': this.form_product.value,
          'status': 'publish',
        });
        // update
        if (id) {
          this.wp.updateProduct(id, data).subscribe((response:any) => {
            // update cached product data
            this.product = response;
            // reset loading state
            this.loading = false;
            // sucessfully saved
            this.saved = true;
            // update saved variable to automatically hide success notification
            setTimeout(() => {
                this.saved = false;
            }, 3000);
          });
        }
        // create
        else {
          this.wp.createProduct(data).subscribe((response:any) => {
            // reload product to have id within url
            this.router.navigate(['dashboard/product/' + response.id]);
          });
        }
      });
    }
    // no image change
    else {
      // prepare data
      var data = JSON.stringify({
        'title': this.form_product.value.name, 
        'fields': this.form_product.value,
        'status': 'publish',
      });
      // update
      if (id) {
        this.wp.updateProduct(id, data).subscribe((response:any) => {
          // update cached product data
          this.product = response;
          // reset loading state
          this.loading = false;
          // sucessfully saved
          this.saved = true;
          // update saved variable to automatically hide success notification
          setTimeout(() => {
              this.saved = false;
          }, 3000);
        });
      }
      // create
      else {
        this.wp.createProduct(data).subscribe((response:any) => {
          // reload product to have id within url
          this.router.navigate(['dashboard/product/' + response.id]);
        });
      }
    }
  }

  // delete product
  deleteProduct(id: any) {
    // show loading
    this.loading = true;
    // delete product
    this.wp.deleteProduct(id).subscribe((response:any) => {
      // redirect to deceases
      this.router.navigate(['dashboard/products']);
    });
  }

  // prefill decease form
  prefillFormProduct() {
    this.form_product.setValue({
      name: this.product.acf.name ? this.product.acf.name : '',
      image: this.product.acf.image ? this.product.acf.image.id : '',
      category: this.product.acf.category ? this.product.acf.category : '',
      unit: this.product.acf.unit ? this.product.acf.unit : '',
      price: this.product.acf.price ? this.product.acf.price : '',
      tax: this.product.acf.tax ? this.product.acf.tax : '',
      type: this.product.acf.type ? this.product.acf.type : '',
      desc: this.product.acf.desc ? this.product.acf.desc : '',
    })
  }

  // get undertaker
  getUndertaker(id : any) {
    // get undertaker data
    this.wp.getUndertaker(id).subscribe((response:any) => {
      // convert product categories string to array
      response.acf.product_categories = response.acf.product_categories.split(',');
      // convert product units string to array
      response.acf.product_units = response.acf.product_units.split(',');
      // save undertaker data
      this.undertaker = response;
    });
  }
  // on image file change
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form_product.patchValue({
        image: file
      });
    }
  }
}
