import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ViewService {
  // constructor
  constructor() {

  }
  // get initial view
  getInitView(view: any) {
    // empty view options
    if (view.sort.options.length == 0 && view.filter.options.length == 0) {
      // pass unprocessed data
      view.output = view.input
      // return unprocessed view
      return view;
    }
    // return processed view
    return this.processView(view, true);
  }
  // process view
  processView(view: any, init: boolean) {
    // view change
    if (init == false) {
      // iterate over all existing filter options
      for (let i = 0; i < view.filter.options.length; i++) {
        // update filter options
        view.filter.options[i].field = (<HTMLInputElement>document.getElementById('filter_option_field_' + i)).value;
        view.filter.options[i].operator = (<HTMLInputElement>document.getElementById('filter_option_operator_' + i)).value;
        view.filter.options[i].value = (<HTMLInputElement>document.getElementById('filter_option_value_' + i)).value;
      }
      // iterate over all existing sort options
      for (let i = 0; i < view.sort.options.length; i++) {
        // get html
        var field = (<HTMLSelectElement>document.getElementById('sort_option_field_' + i));
        var operator = (<HTMLSelectElement>document.getElementById('sort_option_operator_' + i));
        // update sort options
        view.sort.options[i].field = field.value;
        view.sort.options[i].operator = operator.value;
        view.sort.options[i].type = field.options[field.selectedIndex].getAttribute('data-type');
      }
    }
    // process filter
    view.output = this.filterView(view.input, view.filter.options);
    // process sort
    view.output = this.sortView(view.output, view.sort.options);
    // return view
    return view;
  }
  // filter view
  // TODO: OR condition
  filterView(array: any, filter: any) {
    // get filter keys
    const filterKeys = Object.keys(filter);
    // prociess filter
    return array.filter((item: any) => {
      // validates all filter criteria
      return filterKeys.every(key => {
        // empty filter
        if (filter[key].value == '') {
          return true; 
        }
        // existing filter
        else {
          // contains
          if (filter[key].operator == 'contains') {
            return item.acf[filter[key].field].includes(filter[key].value);
          }
          // not contains
          else {
             return !item.acf[filter[key].field].includes(filter[key].value);
         }
        }
      });
    });
  }
  // sort view
  sortView(array: any, sort: any) {
    // not default sorting
    if (sort[0].field != 'date') {
      // ascending order
      if (sort[0].operator == 'asc') {
        // nomeric sort
        if (sort[0].type == 'numeric') {
          array.sort((a: any, b: any) => a.acf[sort[0].field] - b.acf[sort[0].field]);
        }
        // string sort
        else {
          array.sort((a: any, b: any) => a.acf[sort[0].field].localeCompare(b.acf[sort[0].field]));
        }
      }
      // descending order
      else {
        // nomeric sort
        if (sort[0].type == 'numeric') {
          array.sort((a: any, b: any) => a.acf[sort[0].field] - b.acf[sort[0].field]).reverse();
        }
        // string sort
        else {
          array.sort((a: any, b: any) => a.acf[sort[0].field].localeCompare(b.acf[sort[0].field])).reverse();
        }
      }
    }
    // default sorting
    else {
      // ascending order
      if (sort[0].operator == 'asc') {
        // nomeric sort
        if (sort[0].type == 'numeric') {
          array.sort((a: any, b: any) => a[sort[0].field] - b[sort[0].field]);
        }
        // string sort
        else {
          array.sort((a: any, b: any) => a[sort[0].field].localeCompare(b[sort[0].field]));
        }
      }
      // descending order
      else {
        // nomeric sort
        if (sort[0].type == 'numeric') {
          array.sort((a: any, b: any) => a[sort[0].field] - b[sort[0].field]).reverse();
        }
        // string sort
        else {
          array.sort((a: any, b: any) => a[sort[0].field].localeCompare(b[sort[0].field])).reverse();
        }
      }
    }
    // return sorted array
    return array;
  }
}
