<button type="button" class="secondary" (click)="openView()">
  <svg *ngIf="viewInput.filter.options.length > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="mr-1 w-4 h-4">
    <path fill-rule="evenodd" d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z" clip-rule="evenodd" />
  </svg>
  <span>Ansichten</span>
</button>
<div *ngIf="view_popup" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
        <div class="pointer-events-auto w-screen max-w-5xl">
          <div class="flex h-full flex-col overflow-y-scroll bg-white p-6 shadow-xl">
            <div>
              <div class="flex justify-end mb-2">
                <a (click)="closeView()" type="button" class="cursor-pointer">
                  <span class="sr-only">Schließen</span>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 22.5L22.5 7.5M7.5 7.5L22.5 22.5" stroke="#505050" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </a>
              </div>
              <div class="flex items-start justify-between mb-4">
                <h2 id="slide-over-title">Sortierung</h2>
              </div>
              <div *ngFor="let option of viewInput.sort.options; let i = index">
                <!-- <p *ngIf="i != 0">{{ option.connection }}</p> -->
                <div class="flex justify-between gap-4">
                  <select attr.id="sort_option_field_{{i}}">
                    <option *ngFor="let field of viewInput.sort.fields" [attr.data-type]="field.type" [value]="field.field" [selected]="option.field == field.field">{{ field.name }}</option>
                  </select>
                  <select attr.id="sort_option_operator_{{i}}">
                    <option value="asc" [selected]="option.operator == 'sort_active'">aufsteigend</option>
                    <option value="desc" [selected]="option.operator == 'desc'">absteigend</option>
                  </select>
                </div>
              </div>
              <div class="flex items-start justify-between mt-12 mb-4">
                <h2 id="slide-over-title">Filter</h2>
              </div>
               <button *ngIf="viewInput.filter.options.length == 0" (click)="addFilterOption('')" type="submit" class="secondary">Hinzufügen</button>
              <button *ngIf="viewInput.filter.options.length > 0" (click)="removeFilterOptions()" type="submit" class="warning">Entfernen</button>
              <div *ngFor="let option of viewInput.filter.options; let i = index">
                <!-- <p *ngIf="i != 0">{{ option.connection }}</p> -->
                <div class="flex justify-between gap-4 my-4 pr-16 relative">
                  <select attr.id="filter_option_field_{{i}}">
                    <option *ngFor="let field of viewInput.filter.fields" [value]="field.field" [selected]="option.field == field.field">{{ field.name }}</option>
                  </select>
                  <select attr.id="filter_option_operator_{{i}}">
                    <option value="contains" [selected]="option.operator == 'contains'">enthält</option>
                    <option value="!contains" [selected]="option.operator == '!contains'">enthält nicht</option>
                  </select>
                  <input attr.id="filter_option_value_{{i}}" [value]="option.value" type="text">
                  <a *ngIf="i > 0" (click)="removeFilterOption(i)" type="submit" class="cursor-pointer absolute top-2/4 -translate-y-2/4 right-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-minus">
                      <path d="M5 12h14"/>
                    </svg>
                  </a>
                </div>
              </div>
              <div *ngIf="viewInput.filter.options.length > 0">
                <button (click)="addFilterOption('and')" type="button" class="tertiary">UND</button>
                 <!-- <button  (click)="addOption('or', false)" type="button" class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">ODER</button> -->
              </div>
              <div class="mt-6 flex items-center justify-end gap-x-2">
                <button (click)="resetView()" type="submit" class="secondary">Zurücksetzen</button>
                <button (click)="processView()" type="submit" class="primary">Anwenden</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>