import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { WpService } from './wp.service';
import { CookieService } from './cookie.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { catchError, throwError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private wp: WpService,
    private cookie: CookieService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    // auth check
    return this.authCheck();
  }

  // auth check
  authCheck() {
    // get token
    var token = this.cookie.get('funos_token');
    // validate token
    return this.wp.validateToken(token)
    .pipe(map(data => {
        // valid token
        if (data == true) {
          return true;
        }
        // invalid token
        else {
          // redirect to login page
          this.router.navigate(['/login']);
          return false;
        }
      })
    )
    .pipe(catchError(error => {
      // redirect to login page
      this.router.navigate(['/login']);
      return of(false);
    }));
  }
}