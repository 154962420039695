import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { WpService } from '../../services/wp.service';

@Component({
  selector: 'app-admin-billing',
  templateUrl: './admin-billing.component.html',
  styleUrls: ['./admin-billing.component.css']
})
export class AdminBillingComponent implements OnInit {
  // vars
  customers: any = [];
  billing_year = 2024;
  billing_date_range: any = {
    after: this.billing_year + '-01-01',
    before: this.billing_year + '-12-31',
  }
  decease_price = 9.99;
  loading_update = false;
  months: any = {
    1: {
      name: 'Jänner',
    },
    2: {
      name: 'Februar',
    },
    3: {
      name: 'März',
    },
    4: {
      name: 'April',
    },
    5: {
      name: 'Mai',
    },
    6: {
      name: 'Juni',
    },
    7: {
      name: 'Juli',
    },
    8: {
      name: 'August',
    },
    9: {
      name: 'September',
    },
    10: {
      name: 'Oktober',
    },
    11: {
      name: 'November',
    },
    12: {
      name: 'Dezember',
    },
  };  
  // constructor
  constructor(private wp: WpService) {
    this.prepareBilling();
  }
  // init
  ngOnInit(): void {
  
  }
  // prepare billing
  async prepareBilling() {
    // get customers
    this.customers = await firstValueFrom(this.wp.getCustomers());
    // iterate over all customers
    this.customers.forEach(async (element: any, index: any) => {
      // existing billing
      if (this.customers[index].acf.billing) {
        // get billing
        this.customers[index].acf.billing = JSON.parse( this.customers[index].acf.billing);
      }
      // no billing so far
      else {
        // set up billing
        this.customers[index].acf.billing = {};
      }
      // get deceases
      this.customers[index].acf.deceases = await firstValueFrom(this.wp.getDeceasesForBilling(element.id, this.billing_date_range));
      // get deceases month slit
      type DeceasesSplit = Record<any, any>;
      var deceases_split : DeceasesSplit = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [], 12: [] };
      this.customers[index].acf.deceases.forEach((element1: any, index1: any) => {
        var date = new Date(Date.parse(element1.date));
        var month = date.getMonth()+1;
        deceases_split[month as keyof typeof deceases_split].push(element1);
      });
      this.customers[index].acf.deceases_split = deceases_split;
      // update sums
      this.updateSums(this.customers[index]);
    });
  }

  // update sums
  updateSums(customer: any) {
    customer.acf.sum_all = customer.acf.deceases.length * this.decease_price;
    customer.acf.sum_billed = 0;
    if (customer.acf.billing[this.billing_year]) {
      Object.values(customer.acf.billing[this.billing_year]).forEach((value: any) => {
        customer.acf.sum_billed += parseInt(value) * this.decease_price;
      });
    }
  }

  // save billing
  saveBilling(customer: any, month: any, amount: any) {
    this.loading_update = true;
    var billing = customer.acf.billing;
    if (!billing[this.billing_year]) {
      billing[this.billing_year] = {};
    }
    billing[this.billing_year][month] = amount.target.value;
    var data = JSON.stringify({
      'fields': {
        'billing': JSON.stringify(billing),
      }
    });
    this.wp.updateUndertaker(customer.id, data).subscribe((response:any) => {
      // update sums
      this.updateSums(customer);
      // update loading
      this.loading_update = false;
    });
  }

  // check billing status for month
  checkMonthStatus(customer: any, month: any) {
    var billed = (customer.acf.billing[this.billing_year] && customer.acf.billing[this.billing_year][month]) ? customer.acf.billing[this.billing_year][month] : 0;
    if (customer.acf.deceases_split[month].length == billed) {
      return true;
    }
    return false;
  }

  // sort month object
  getMonthSort() {
    return Object.keys(this.months)
    .map(Number)
    .sort((a, b) => a - b);
  }
}
