import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../../services/cookie.service';
import { WpService } from '../../services/wp.service';
import { ViewService } from '../../services/view.service';
import { Router } from '@angular/router';
import { formatNumber } from '@angular/common'; 

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  @Input() user: any = [];
  products: any = [];
  loading = false;
  selectedProductDropdown = -1;
  // view data
  view_data = {
    input: [],
    output: [],
    filter: {
      fields: [
        {field: 'name', name: 'Name'},
        {field: 'category', name: 'Kategorie'},
        {field: 'unit', name: 'Einheit'},
        {field: 'price', name: 'Preis (Netto)'},
        {field: 'tax', name: 'Steuersatz'},
        {field: 'type', name: 'Typ'},
      ],
      options: [],
    },
    sort: {
      fields: [
        {field: 'name', name: 'Name', type: 'string'},
        {field: 'category', name: 'Kategorie', type: 'string'},
        {field: 'price', name: 'Preis (Netto)', type: 'numeric'},
      ],
      options: [],
    }
  };
  // list data
  list_data: any = {};
  // constructor
  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private wp: WpService,
    private view: ViewService,
    private router: Router) {

  }
  // on init
  ngOnInit(): void {
    // load products
    this.loadProducts();
  }
  // load products
  loadProducts() {
    // loading state update
    this.loading = true;
    // get products
    this.wp.getProducts(this.user.acf.undertaker.ID).subscribe((response:any) => {
      // set view input
      this.view_data.input = response;
      // set view options
      if (this.user.acf.view_products) {
        this.view_data.filter.options = JSON.parse(this.user.acf.view_products).filter;
        this.view_data.sort.options = JSON.parse(this.user.acf.view_products).sort;
      }
      // get initial view output
      response = this.view.getInitView(this.view_data).output;
      // set products
      this.products = response;
      // set list 
      this.setListData(this.products);
    });
  }
  // delete product
  deleteProduct(id: any) {
    // loading update
    this.loading = true;
    // delete product
    this.wp.deleteProduct(id).subscribe((response:any) => {
      // reload produccts
      this.loadProducts();
    });
  }
  // get view data
  getViewData(view: any) {
    // upate view options
    this.view_data.filter.options = view.filter.options;
    this.view_data.sort.options = view.sort.options;
    // update products
    this.products = view.output;
    // update list
    this.setListData(this.products);
    // update view options on user
    var view_options = {
      sort: view.sort.options,
      filter: view.filter.options
    }
    var data = JSON.stringify({
      'fields': {
        'view_products': JSON.stringify(view_options),
      },
    });
    this.wp.updateUserData(this.user.id, data).subscribe((response:any) => {
      this.user.acf.view_products = JSON.stringify(view_options);
    });
  }
  // set list data
  setListData(data: any) {
    // rest list data
    this.list_data = {
      entries: [],
    };
    // fill list
    data.forEach((entry: any) => {
      this.list_data.entries.push({
        id: entry.id,
        actions: [
          {
            name: 'Bearbeiten',
            trigger: 'edit',
          },
          {
            name: 'Duplizieren',
            trigger: 'duplicate',
          },
          {
            name: 'Löschen',
            trigger: 'delete',
          },
        ],
        image: {
          value: entry.acf.image,
          text: entry.acf.image,
          class: '',
        },
        headline: {
          value: entry.acf.name,
          text: entry.acf.name,
          class: '',
        },
        subline: {
          value: entry.acf.category,
          text: entry.acf.category,
          class: '',
        },
        meta_top: {
          value: entry.acf.price_total,
          text: formatNumber(entry.acf.price_total, 'de-DE', '1.2-2') + '€',
          class: '',
        },
        meta_bottom: {
          value: entry.acf.tax,
          text: 'inkl. ' + entry.acf.tax + '% Steuer',
          class: '',
        },
      })
    });
    // loading complete
    this.loading = false;
  }
  // process list action
  processListAction(action: any) {
    // click on entry || edit
    if (action.trigger == 'click' || action.trigger == 'edit') {
      // route to detail page
      this.router.navigate(['dashboard/product/' + action.id]);
    }
    // duplicate
    else if (action.trigger == 'duplicate') {
      // route to detail page
      this.router.navigate(
        ['dashboard/product/'],
        { queryParams: {duplicate: action.id}});
    }
    // delete
    else if (action.trigger == 'delete') {
      this.deleteProduct(action.id);
    }
  }
}
