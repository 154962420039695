import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})

export class DatepickerComponent implements OnInit {
  MONTH_NAMES = [
    'Jänner',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ];
  DAYS = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  showDatepicker = false;
  day!: number;
  month!: number; // !: mean promis it will not be null, and it will definitely be assigned
  year!: number;
  date!: Date;
  no_of_days = [] as number[];
  blankdays = [] as number[];
  datepicker_value!: any;
  @Input() controlName: string;
  @Input() index: string;
  @Input() value: string;
  @Input() time: string;
  time_on: boolean = false;
  hour: string;
  min: string;
  // constructor
  constructor(
    private parentForm: FormGroupDirective) {

  }
  // on init
  ngOnInit(): void {
    // init current date
    this.initDateNow();
    // default value given?
    if (this.value) {
      // set default value
      this.datepicker_value = this.value;
    }
  }
  // init current date
  initDateNow() {
    this.date = new Date();
    this.day = this.date.getDate();
    this.month = this.date.getMonth();
    this.year = this.date.getFullYear();
    this.hour = '';
    this.min = '';
    this.getNoOfDays(this.year, this.month);
  }
  // init date input
  initDateInput() {
    // part of formgroup
    if (this.controlName) {
      var date_value = this.parentForm.form.controls[this.controlName].value;
    }
    // standalone
    else {
      var date_value = this.datepicker_value;
    }
    // date regey
    var date_regex = new RegExp('(0[1-9]|1[0-9]|2[0-9]|3[01])\.(0[1-9]|1[012])\.(?:19|20)[0-9]{2}');
    // input is valid date
    if (date_regex.test(date_value)) {
      // split date string
      var [day, month, year_time] = date_value.split('.');
      // date includes time
      if (year_time.length > 4) {
        // split year and time
        var [year, time] = year_time.split(' ');
        // split hour and min
        var [hour, min] = time.split(':');
        // update time on
        this.time_on = true;
      }
      // date includes no time
      else {
        var year = year_time;
        var hour: any = '';
        var min: any = '';
      }
      // set date variales
      this.date = new Date(+year, +month - 1, +day);
      this.year = this.date.getFullYear();
      this.month = this.date.getMonth();
      this.day = this.date.getDate();
      this.hour = hour;
      this.min = min;
      // get number of days
      this.getNoOfDays(this.year, this.month);
    }
    // no valid date given
    else {
      // init current date instead
      this.initDateNow();
    }
  }
  // date is today?
  isToday(date: any) {
    const today = new Date();
    const d = new Date(this.year, this.month, date);
    return today.toDateString() === d.toDateString() ? true : false;
  }
  // date is the chosen one?
  isDate(date: any) {
    const d = new Date(this.year, this.month, date);
    return d.toDateString() === this.date.toDateString() ? true : false;
  } 
  // get date from datepicker
  getDateValue(date: any) {
    this.day = date;
    this.date = new Date(this.year, this.month, date);
    // part of form group
    if (this.controlName) {
      this.parentForm.form.patchValue({
        [this.controlName]: this.formatDate(this.date) + this.getTimeValue()
      });
    }
    // standalone
    else {
      this.datepicker_value = this.formatDate(this.date) + this.getTimeValue()
    }
    // if time not activated close datepicker on date click
    if (!this.time) {
      this.toggleDatepicker();
    }
  }
  // get time from time picker
  getTimeValue() {
    if (this.time_on) {
      return ' ' + this.hour + ':' + this.min;
    }
    return '';
  }
  // get number of days
  getNoOfDays(year: any, month: any) {
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    // find where to start calendar day of week
    let dayOfWeek = new Date(year, month).getDay();
    let blankdaysArray = [];
    for (var i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    let daysArray = [];
    for (var i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    this.blankdays = blankdaysArray;
    this.no_of_days = daysArray;
  }
  // format date
  formatDate(date: any) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');
  }
  // pad to 2 digits
  padTo2Digits(num: any) {
    return num.toString().padStart(2, '0');
  }
  // update date picker
  updateDatepicker(direction: any) {
    if (direction == '+') {
      if (this.month < 11) {
        this.month++;
      }
      else {
        this.month = 0;
        this.year++;
      }
    }
    else {
      if (this.month > 0) {
        this.month--;
      }
      else {
        this.month = 11;
        this.year--;
      }
    }
  }
  // hide/show date picker
  toggleDatepicker() {
    if (!this.showDatepicker) {
      this.initDateInput();
    }
    this.showDatepicker = !this.showDatepicker;
  }
  // hide/show time fields
  toggleTime() {
    this.time_on = !this.time_on;
    if (!this.time_on) {
      this.hour = '';
      this.min = '';
      this.getDateValue(this.day);
    }
  }

  trackByIdentity = (index: number, item: any) => item;
}
