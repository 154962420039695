import { Component, OnInit, Input, Output, EventEmitter, HostListener, Renderer2} from '@angular/core';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { WpService } from '../../services/wp.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  // list data
  @Input() data: any = [];
  // content
  fields: any = [];
  // action
  @Output() action: EventEmitter<void> = new EventEmitter<void>();
  // selected action dropdown
  selectedActionDropdown = -1;
  // constructor
  constructor(
    private renderer: Renderer2,
    private wp: WpService,) {
    // hide action dropdown on outside click
    this.renderer.listen('window', 'click', (e: Event) => {
      this.selectedActionDropdown = -1;
    });
  }
  // on init
  ngOnInit(): void {
    
  }
  // open action dropdown
  openActions(index: number) {
    // selected dropdown is not open
    if (this.selectedActionDropdown != index) {
      // open dropdown
      this.selectedActionDropdown = index;
    }
    // selectred dropdown is already open
    else {
      // close dropdown
      this.selectedActionDropdown = -1;
    }
  }
  // process action
  processAction(action: any, entry_id: any) {
    var action_object: any = {trigger: action, id: entry_id}
    this.action.emit(action_object);
  }
  // drop item
  dropItem(item: CdkDragDrop<string[]>) {
    // move event
    moveItemInArray(this.data.entries, item.previousIndex, item.currentIndex);
    // iterate over all events
    this.data.entries.forEach((event: any, index: any) => {
      // prepare data
      var data = JSON.stringify({
        'fields': {
          'sort': index,
        }
      });
      // update sort
      this.wp.updateEvent(event.id, data).subscribe();     
    });
  }
}
