<div *ngIf="user.admin != true">
  <div class="md:flex md:items-center md:justify-between">
  	<div class="min-w-0 flex-1">
  		<h1>Übersicht</h1>
  	</div>
  </div>
  <div>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div class="overflow-hidden rounded-lg bg-gray-100 px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Alle Sterbefälle</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-500">{{ (deceases_all > 0) ? deceases_all : '...' }}</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-gray-100 px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Aktiv</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-green-500">{{ (deceases_active > 0) ? deceases_active : '...' }}</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-gray-100 px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Archiv</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-red-500">{{ (deceases_archive > 0) ? deceases_archive : '...' }}</dd>
      </div>
    </dl>
  </div>
</div>
<div *ngIf="user.admin == true">
  <div class="md:flex md:items-center md:justify-between">
    <div class="min-w-0 flex-1">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Admin Dashboard</h2>
    </div>
  </div>
</div>