import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { WpService } from '../../services/wp.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  // user
  @Input() user: any = [];
  // undertaker
  undertaker: any = [];
  // loading
  loading_general = false;
  loading_events = false;
  loading_processes = false;
  // saved 
  saved = false;
  // tab
  tab = 1;
  // all events
  events: any = [];
  // event slide over
  event_slide_over = false;
  // current event id
  event_id = false;
  // all products
  products: any = [];
  // current products choice
  products_choice: any = [];
  // all processes
  processes: any = [];
  // process slide over
  process_slide_over = false;
  // current process id
  process_id = false;
   // current process events choice
  events_choice: any = [];
  // selected product
  @ViewChild('selected_product') selected_product!: ElementRef;
  // selected event
  // @ViewChild('selected_event') selected_event!: ElementRef;
  // form for general settings
  form_general: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    logo: new FormControl(),
    address: new FormControl('', Validators.required),
    bankdetails: new FormControl('', Validators.required),
    regions: new FormControl('', Validators.required),
    product_categories: new FormControl('', Validators.required),
    product_units: new FormControl('', Validators.required),
  });
  // form for event settings
  form_event: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    note: new FormControl(''),
  });
  // form for process settings
  form_process: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
  });
  // custom fields
  custom_fields: any = [];
  // processes list
  list_data_processes: any = {};
  // event list
  list_data_events: any = {};
  // show_eventlist
  show_eventlist = false;
  // show product list
  show_productlist = false;
  // constructor
  constructor(
    private wp: WpService) {

  }
  // init
  ngOnInit(): void {
    // get undertaker
    this.getUndertaker(this.user.acf.undertaker.ID);
    // get event templates
    this.getEventTemplates(this.user.acf.undertaker.ID);
    // get process templates
    this.getProcessTemplates(this.user.acf.undertaker.ID);
  }
  // get undertaker
  getUndertaker(id : any) {
    this.wp.getUndertaker(id).subscribe((response:any) => {
      // save data
      this.undertaker = response;
      // prefill form general
      this.prefillFormGeneral();
    });
  }
  // update undertaker
  updateUndertaker(id: any) {
    // update loading
    this.loading_general = true;
    // logo change
    if (this.form_general.value.logo.name) {
      // upload logo
      this.wp.uploadFile(this.form_general.value.logo, this.form_general.value.logo.name).subscribe((response:any) => {
        // set logo id
        this.form_general.value.logo = response.id;
        // prepare data
        var data = JSON.stringify({
          'title': this.form_general.value.name,
          'fields': this.form_general.value,
          'status': 'publish',
        });
        // update undertaker
        this.updateUndertakerWp(id, data);
      });
    }
    // no logo change
    else {
      // prepare data
      var data = JSON.stringify({
        'title': this.form_general.value.name,
        'fields': this.form_general.value,
        'status': 'publish',
      });
      // update undertaker
      this.updateUndertakerWp(id, data);
    }
  }
  // update undertaker wp
  updateUndertakerWp(id: any, data: any) {
    // update
    this.wp.updateUndertaker(id, data).subscribe((response:any) => {
      // reset loading state
      this.loading_general = false;
      // sucessfully saved
      this.saved = true;
      // update undertaker data
      this.getUndertaker(this.user.acf.undertaker.ID);
      // update saved variable to automatically hide success notification
      setTimeout(() => {
          this.saved = false;
      }, 3000);
    });
  }
  // build form general
  prefillFormGeneral() {
    this.form_general.setValue({
      name: this.undertaker.acf.name ? this.undertaker.acf.name : '',
      logo: this.undertaker.acf.logo ? this.undertaker.acf.logo.id : '',
      address: this.undertaker.acf.address ? this.undertaker.acf.address : '',
      bankdetails: this.undertaker.acf.bankdetails ? this.undertaker.acf.bankdetails : '',
      regions: this.undertaker.acf.regions ? this.undertaker.acf.regions : '',
      product_categories: this.undertaker.acf.product_categories ? this.undertaker.acf.product_categories : '',
      product_units: this.undertaker.acf.product_units ? this.undertaker.acf.product_units : '',
    })
  }
  // get event templates
  getEventTemplates(undertaker : any) {
    // update loading
    this.loading_events = true;
    // get templates
    this.wp.getEventTemplates(undertaker).subscribe((response:any) => {
      // save data
      this.events = response;
      // set up list
      this.setListDataEvents(this.events);
      // update loading
      this.loading_events = false;
    });
  }
  // open event template details
  openEventTemplate(id: any) {
    // update event id
    this.event_id = id;
    // existing event
    if (id != false) {
      // get product data as clone
      var event = JSON.parse(JSON.stringify(this.events.find((x: any) => x.id === id)));
      // set product choice for current event
      if (event.acf.products) {
        this.products_choice = event.acf.products;
      }
      else {
        this.products_choice = [];
      }
      // set custom fields for current event
      if (event.acf.custom_fields) {
        this.custom_fields = JSON.parse(event.acf.custom_fields);
        this.custom_fields.forEach((value: any, index: any) => {
          value['toggle'] = false;
        });
      }
      else {
        this.custom_fields = [];
      }
      // prefill form event
      this.form_event.setValue({
        name: event.acf.name ? event.acf.name : '',
        note: event.acf.note ? event.acf.note : '',
      });
    }
    // new event
    else {
      // reset products choice
      this.products_choice = [];
      // reset custom fields amount
      this.custom_fields = [];
      // empty form event values
      this.form_event.setValue({
        name: '',
        note: '',
      });
    }
    // hide product list
    this.show_productlist = false;
    // show slide over
    this.event_slide_over = true;
  }
  // update event template
  updateEventTemplate(id: any) {
    // update loading
    this.loading_events = true;
    // set undertaker
    this.form_event.value.undertaker = this.user.acf.undertaker.ID;
    // set product ids
    var product_ids: any = [];
    this.products_choice.forEach((element: any) => {
      if (element.ID) {
        product_ids.push(element.ID);
      }
      else {
        product_ids.push(element.id);
      }
    });
    this.form_event.value.products = product_ids;
    // set custom fields
    var custom_fields: any = [];
    for (let i = 0; i < this.custom_fields.length; i++) {
      var custom_field = {
        name: this.custom_fields[i].name,
        type: this.custom_fields[i].type,
        value: this.custom_fields[i].value,
        display: this.custom_fields[i].display,
      }
      custom_fields.push(custom_field);
    }
    this.form_event.value.custom_fields = JSON.stringify(custom_fields);
    // prepare data
    var data = JSON.stringify({
      'title': this.form_event.value.name + ' (' + this.user.acf.undertaker.post_title + ')', 
      'fields': this.form_event.value,
      'status': 'publish',
    });
    // update event
    if (id != false) {
      this.wp.updateEventTemplate(id, data).subscribe((response:any) => {
        // reload event list
        this.getEventTemplates(this.user.acf.undertaker.ID);
      });
    }
    // create event
    else {
      this.wp.createEventTemplate(data).subscribe((response:any) => {
        // reload event list
        this.getEventTemplates(this.user.acf.undertaker.ID);
      });
    }
    // hide slide over
    this.event_slide_over = false;
  }
  // delete event template
  deleteEventTemplate(id: any) {
    // update loading
    this.loading_events = true;
    // delete template
    this.wp.deleteEventTemplate(id).subscribe((response:any) => {
      // reload event list
      this.getEventTemplates(this.user.acf.undertaker.ID);
    });
  }
  // get all available products
  getProducts() {
    this.wp.getProducts(this.user.acf.undertaker.ID).subscribe((response:any) => {
      this.products = response;
    });
  }
  // add product to event template
  addProduct(product_id: any) {
    this.products_choice.push(this.products.find((x: any) => x.id === product_id));
    // hide product list
    this.show_productlist = false;
  }
  // remove product from event template
  removeProduct(product_id: any) {
    var selected_product_id = parseInt(product_id);
    this.products_choice.splice(this.products_choice.findIndex((x: any) => x.ID === selected_product_id), 1);
  }
  // add custom field to event template
  addCustomField() {
    this.custom_fields.push({
      name: '',
      type: 'text',
      value: '',
      display: '1',
      toggle: true,
    });
  }
  // remove custom field from event template
  removeCustomField(index: any) {
    this.custom_fields.splice(parseInt(index), 1);
  }
  // remove all custom fieldss
  removeAllCustomFields() {
    this.custom_fields = [];
  }
  // get process templates
  getProcessTemplates(undertaker : any) {
    // update loading
    this.loading_processes = true;
    // get templates
    this.wp.getProcessTemplates(undertaker).subscribe((response:any) => {
      // save data
      this.processes = response;
      // set up list
      this.setListDataProcesses(this.processes);
      // update loading
      this.loading_processes = false;
    });
  }
  // open process template details
  openProcessTemplate(id: any) {
    // update process id
    this.process_id = id;
    // existing process
    if (id != false) {
      // get process data as clone
      var proc = JSON.parse(JSON.stringify(this.processes.find((x: any) => x.id === id)));
      // set event choice for current process
      if (proc.acf.events) {
        this.events_choice = proc.acf.events;
      }
      else {
        this.events_choice = [];
      }
      // prefill form process
      this.form_process.setValue({
        name: proc.acf.name ? proc.acf.name : '',
      });
    }
    // new process
    else {
      // reset events choice
      this.events_choice = [];
      // empty form event values
      this.form_process.setValue({
        name: '',
      });
    }
    // hide show_eventlist
    this.show_eventlist = false;
    // show slide over
    this.process_slide_over = true;
  }
  // update process template
  updateProcessTemplate(id: any) {
    // update loading
    this.loading_processes = true;
    // set undertaker
    this.form_process.value.undertaker = this.user.acf.undertaker.ID;
    // set event ids
    var event_ids: any = [];
    this.events_choice.forEach((element: any) => {
      if (element.ID) {
        event_ids.push(element.ID);
      }
      else {
        event_ids.push(element.id);
      }
    });
    this.form_process.value.events = event_ids;
    // prepare data
    var data = JSON.stringify({
      'title': this.form_process.value.name + ' (' + this.user.acf.undertaker.post_title + ')', 
      'fields': this.form_process.value,
      'status': 'publish',
    });
    // update process
    if (id != false) {
      this.wp.updateProcessTemplate(id, data).subscribe((response:any) => {
        // reload event list
        this.getProcessTemplates(this.user.acf.undertaker.ID);
      });
    }
    // create process
    else {
      this.wp.createProcessTemplate(data).subscribe((response:any) => {
        // reload process list
        this.getProcessTemplates(this.user.acf.undertaker.ID);
      });
    }
    // hide slide over
    this.process_slide_over = false;
  }
  // delete process template
  deleteProcessTemplate(id: any) {
    // update loading
    this.loading_processes = true;
    // delete template
    this.wp.deleteProcessTemplate(id).subscribe((response:any) => {
      // reload process list
      this.getProcessTemplates(this.user.acf.undertaker.ID);
    });
  }
  // remove event from process template
  removeEventFromProcess(event_id: any) {
    var selected_event_id = parseInt(event_id);
    this.events_choice.splice(this.events_choice.findIndex((x: any) => x.ID === selected_event_id), 1);
  }
  // add event to process template
  // addEventToProcess() {
  //   var selected_event_id = parseInt(this.selected_event.nativeElement.value);
  //   this.events_choice.push(this.events.find((x: any) => x.id === selected_event_id));
  // }
  // add event to process template
  addEventToProcess(event_id: any) {
    this.events_choice.push(this.events.find((x: any) => x.id === event_id));
    this.show_eventlist = false;
  }
  // on logo file change
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form_general.patchValue({
        logo: file
      });
    }
  }
  // set list data for processes
  setListDataProcesses(data: any) {
    // reset list
    this.list_data_processes = {
      entries: [],
    };
    // fill list
    data.forEach((entry: any) => {
      // set up list
      this.list_data_processes.entries.push({
        id: entry.id,
        actions: [
          {
            name: 'Bearbeiten',
            trigger: 'edit',
          },
          {
            name: 'Löschen',
            trigger: 'delete',
          },
        ],
        image: {
          value: 'none',
        },
        headline: {
          value: entry.acf.name,
          text: entry.acf.name,
        },
        subline: {
          value: false,
        },
        meta_top: {
          value: false,
        },
        meta_bottom: {
          value: false,
        },
      })
    });
    // loading complete
    this.loading_events = false;
  }
  // process list action for processes
  processListActionProcesses(action: any) {
    // click on entry || edit
    if (action.trigger == 'click' || action.trigger == 'edit') {
      // open process template
      this.openProcessTemplate(action.id);
    }
    // delete
    else if (action.trigger == 'delete') {
      this.deleteProcessTemplate(action.id);
    }
  }
  // set list data for events
  setListDataEvents(data: any) {
    // reset list
    this.list_data_events = {
      entries: [],
    };
    // fill list
    data.forEach((entry: any) => {
      // set up list
      this.list_data_events.entries.push({
        id: entry.id,
        actions: [
          {
            name: 'Bearbeiten',
            trigger: 'edit',
          },
          {
            name: 'Löschen',
            trigger: 'delete',
          },
        ],
        image: {
          value: 'none',
        },
        headline: {
          value: entry.acf.name,
          text: entry.acf.name,
        },
        subline: {
          value: entry.acf.note,
          text: entry.acf.note,
        },
        meta_top: {
          value: false,
        },
        meta_bottom: {
          value: false,
        },
      })
    });
    // loading complete
    this.loading_events = false;
  }
  // process list action for events
  processListActionEvents(action: any) {
    // click on entry || edit
    if (action.trigger == 'click' || action.trigger == 'edit') {
      // open event template
      this.openEventTemplate(action.id);
    }
    // delete
    else if (action.trigger == 'delete') {
      this.deleteEventTemplate(action.id);
    }
  }
  // process regions
  processListField(data: any, listfield: any) {
    if (listfield == 'regions') {
     this.form_general.patchValue({
        regions: data.join(','),
      });
    }
    else if (listfield == 'product_categories') {
     this.form_general.patchValue({
        product_categories: data.join(','),
      });
    }
    else if (listfield == 'product_units') {
     this.form_general.patchValue({
        product_units: data.join(','),
      });
    }
  }
  // process custom field value
  processCustomFieldValue(data: any, index: any) {
    this.custom_fields[index].value = data.join(',');
  }
  // process custom field name
  processCustomFieldName(event: any, index: any) {
    this.custom_fields[index].name = event.target.value;
  }
  // process custom field type
  processCustomFieldType(event: any, index: any) {
    this.custom_fields[index].type = event.target.value;
  }
}
