import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeceasesComponent } from './dashboard/deceases/deceases.component';
import { OverviewComponent } from './dashboard/overview/overview.component';
import { AuthGuard } from './services/auth.guard';
import { DeceaseComponent } from './dashboard/decease/decease.component';
import { SettingsComponent } from './dashboard/settings/settings.component';
import { ProductsComponent } from './dashboard/products/products.component';
import { ProductComponent } from './dashboard/product/product.component';
import { DocumentComponent } from './dashboard/document/document.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { ViewComponent } from './components/view/view.component';
import { ListComponent } from './components/list/list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomselectComponent } from './components/customselect/customselect.component';
import { ListfieldComponent } from './components/listfield/listfield.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { AdminBillingComponent } from './dashboard/admin-billing/admin-billing.component';
import { FinanceComponent } from './dashboard/finance/finance.component';
import { CalendarComponent } from './dashboard/calendar/calendar.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      { path: 'overview', component: OverviewComponent },
      { path: 'deceases', component: DeceasesComponent },
      { path: 'decease', component: DeceaseComponent },
      { path: 'decease/:id', component: DeceaseComponent },
      { path: 'products', component: ProductsComponent },
      { path: 'product', component: ProductComponent },
      { path: 'product/:id', component: ProductComponent },
      { path: 'finance', component: FinanceComponent },
      { path: 'calendar', component: CalendarComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'document/:id', component: DocumentComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'admin-billing', component: AdminBillingComponent },
    ]
  },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    DeceasesComponent,
    OverviewComponent,
    DeceaseComponent,
    SettingsComponent,
    ProductsComponent,
    ProductComponent,
    DocumentComponent,
    DatepickerComponent,
    ViewComponent,
    ListComponent,
    CustomselectComponent,
    ListfieldComponent,
    ProfileComponent,
    AdminBillingComponent,
    FinanceComponent,
    CalendarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Nl2BrPipeModule,
    RouterModule.forRoot(appRoutes),
    DragDropModule,
  ],
  providers: [
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
