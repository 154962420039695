<div>
  <!-- mobile menu sidebar -->
  <div *ngIf="showMobileNav" class="menu_sidebar relative z-50 lg:hidden" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-900/80"></div>
    <div class="fixed inset-0 flex">
      <div class="relative mr-16 flex w-full max-w-xs flex-1">
        <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
          <button (click)="showMobileNav = !showMobileNav" type="button" class="-m-2.5 p-2.5">
            <span class="sr-only">Close sidebar</span>
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-sand px-6 pb-4">
          <div class="flex h-16 shrink-0 items-center">
            <img src="./assets/images/logo.svg" alt="Logo Funos">
          </div>
          <nav *ngIf="user.admin != true && !loading" class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li>
                    <a [routerLink]="['overview']" routerLinkActive="active" (click)="showMobileNav = !showMobileNav">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.125 5C3.125 3.96447 3.96447 3.125 5 3.125H6.875C7.91053 3.125 8.75 3.96447 8.75 5V6.875C8.75 7.91053 7.91053 8.75 6.875 8.75H5C3.96447 8.75 3.125 7.91053 3.125 6.875V5Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.125 13.125C3.125 12.0895 3.96447 11.25 5 11.25H6.875C7.91053 11.25 8.75 12.0895 8.75 13.125V15C8.75 16.0355 7.91053 16.875 6.875 16.875H5C3.96447 16.875 3.125 16.0355 3.125 15V13.125Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.25 5C11.25 3.96447 12.0895 3.125 13.125 3.125H15C16.0355 3.125 16.875 3.96447 16.875 5V6.875C16.875 7.91053 16.0355 8.75 15 8.75H13.125C12.0895 8.75 11.25 7.91053 11.25 6.875V5Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.25 13.125C11.25 12.0895 12.0895 11.25 13.125 11.25H15C16.0355 11.25 16.875 12.0895 16.875 13.125V15C16.875 16.0355 16.0355 16.875 15 16.875H13.125C12.0895 16.875 11.25 16.0355 11.25 15V13.125Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Dashboard
                    </a>
                  </li>
                  <li>
                    <a [routerLink]="['deceases']" routerLinkActive="active" [class.active]="router.isActive('/dashboard/decease', false) || router.isActive('/dashboard/document', false)" (click)="showMobileNav = !showMobileNav">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 15.9396C13.1941 16.1417 13.9281 16.25 14.6875 16.25C15.9198 16.25 17.0854 15.9647 18.122 15.4565C18.124 15.4088 18.125 15.3607 18.125 15.3125C18.125 13.414 16.5859 11.875 14.6875 11.875C13.5058 11.875 12.4633 12.4713 11.8446 13.3793M12.5 15.9396V15.9375C12.5 15.0101 12.2624 14.1382 11.8446 13.3793M12.5 15.9396C12.5 15.9693 12.4997 15.999 12.4992 16.0286C10.9478 16.9627 9.1304 17.5 7.1875 17.5C5.2446 17.5 3.4272 16.9627 1.87577 16.0286C1.87526 15.9983 1.875 15.9679 1.875 15.9375C1.875 13.0035 4.25349 10.625 7.1875 10.625C9.1941 10.625 10.9409 11.7375 11.8446 13.3793M10 5.3125C10 6.8658 8.7408 8.125 7.1875 8.125C5.6342 8.125 4.375 6.8658 4.375 5.3125C4.375 3.7592 5.6342 2.5 7.1875 2.5C8.7408 2.5 10 3.7592 10 5.3125ZM16.875 7.1875C16.875 8.39562 15.8956 9.375 14.6875 9.375C13.4794 9.375 12.5 8.39562 12.5 7.1875C12.5 5.97938 13.4794 5 14.6875 5C15.8956 5 16.875 5.97938 16.875 7.1875Z" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Sterbefälle
                    </a>
                  </li>
                  <li>
                    <a [routerLink]="['products']" routerLinkActive="active" [class.active]="router.isActive('/dashboard/product', false)" (click)="showMobileNav = !showMobileNav">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 6.25L10 1.875L2.5 6.25M17.5 6.25L10 10.625M17.5 6.25V13.75L10 18.125M2.5 6.25L10 10.625M2.5 6.25V13.75L10 18.125M10 10.625V18.125" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Produkte
                    </a>
                  </li>
                  <li>
                    <a href="#" routerLinkActive="active" (click)="showMobileNav = !showMobileNav">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.875 6.875H18.125M1.875 7.5H18.125M4.375 11.875H9.375M4.375 13.75H6.875M3.75 16.25H16.25C17.2855 16.25 18.125 15.4105 18.125 14.375V5.625C18.125 4.58947 17.2855 3.75 16.25 3.75H3.75C2.71447 3.75 1.875 4.58947 1.875 5.625V14.375C1.875 15.4105 2.71447 16.25 3.75 16.25Z" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Finanzen
                    </a>
                  </li>
                </ul>
              </li>
              <li class="mt-auto">
                <a [routerLink]="['settings']" routerLinkActive="active" (click)="showMobileNav = !showMobileNav">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.89506 2.62668C6.95534 2.26504 7.26823 1.99998 7.63486 1.99998H8.36417C8.7308 1.99998 9.04369 2.26504 9.10396 2.62668L9.20327 3.22256C9.25042 3.50541 9.45903 3.73224 9.72386 3.8422C9.98881 3.9522 10.293 3.93751 10.5265 3.77077L11.0182 3.41954C11.3165 3.20644 11.7252 3.24026 11.9845 3.49951L12.5002 4.01521C12.7594 4.27446 12.7932 4.68313 12.5801 4.98147L12.2288 5.47336C12.0621 5.70673 12.0474 6.01087 12.1573 6.27574C12.2673 6.54051 12.494 6.74907 12.7768 6.7962L13.3728 6.89553C13.7345 6.95581 13.9995 7.2687 13.9995 7.63533V8.36464C13.9995 8.73127 13.7345 9.04416 13.3728 9.10443L12.7769 9.20375C12.4941 9.25089 12.2673 9.4595 12.1573 9.72433C12.0473 9.98926 12.062 10.2935 12.2287 10.5269L12.5799 11.0185C12.793 11.3169 12.7592 11.7256 12.4999 11.9848L11.9842 12.5005C11.725 12.7597 11.3163 12.7936 11.018 12.5805L10.5262 12.2292C10.2928 12.0625 9.98868 12.0478 9.72379 12.1578C9.459 12.2677 9.25042 12.4945 9.20329 12.7773L9.10396 13.3733C9.04369 13.7349 8.7308 14 8.36417 14H7.63486C7.26823 14 6.95534 13.7349 6.89506 13.3733L6.79575 12.7774C6.74861 12.4946 6.53999 12.2677 6.27517 12.1578C6.01023 12.0478 5.70601 12.0625 5.47258 12.2292L4.98083 12.5805C4.68249 12.7936 4.27382 12.7597 4.01457 12.5005L3.49887 11.9848C3.23963 11.7255 3.2058 11.3169 3.4189 11.0185L3.77026 10.5266C3.93695 10.2933 3.95165 9.98911 3.84168 9.72424C3.73176 9.45947 3.50497 9.25089 3.22219 9.20376L2.62621 9.10443C2.26457 9.04416 1.99951 8.73127 1.99951 8.36464V7.63533C1.99951 7.2687 2.26457 6.95581 2.62621 6.89553L3.22208 6.79622C3.50493 6.74908 3.73177 6.54046 3.84172 6.27563C3.95173 6.01068 3.93704 5.70645 3.7703 5.47301L3.41916 4.98143C3.20607 4.68309 3.23989 4.27441 3.49913 4.01517L4.01483 3.49947C4.27408 3.24022 4.68275 3.2064 4.98109 3.4195L5.47282 3.77073C5.70621 3.93744 6.01036 3.95213 6.27525 3.84216C6.54003 3.73223 6.7486 3.50544 6.79573 3.22265L6.89506 2.62668Z" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M9.99998 8C9.99998 9.10457 9.10455 10 7.99998 10C6.89541 10 5.99998 9.10457 5.99998 8C5.99998 6.89543 6.89541 6 7.99998 6C9.10455 6 9.99998 6.89543 9.99998 8Z" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  Einstellungen
                </a>
              </li>
            </ul>
          </nav>
          <nav *ngIf="user.admin == true && !loading" class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li>
                    <a [routerLink]="['overview']" routerLinkActive="active">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.125 5C3.125 3.96447 3.96447 3.125 5 3.125H6.875C7.91053 3.125 8.75 3.96447 8.75 5V6.875C8.75 7.91053 7.91053 8.75 6.875 8.75H5C3.96447 8.75 3.125 7.91053 3.125 6.875V5Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.125 13.125C3.125 12.0895 3.96447 11.25 5 11.25H6.875C7.91053 11.25 8.75 12.0895 8.75 13.125V15C8.75 16.0355 7.91053 16.875 6.875 16.875H5C3.96447 16.875 3.125 16.0355 3.125 15V13.125Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.25 5C11.25 3.96447 12.0895 3.125 13.125 3.125H15C16.0355 3.125 16.875 3.96447 16.875 5V6.875C16.875 7.91053 16.0355 8.75 15 8.75H13.125C12.0895 8.75 11.25 7.91053 11.25 6.875V5Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.25 13.125C11.25 12.0895 12.0895 11.25 13.125 11.25H15C16.0355 11.25 16.875 12.0895 16.875 13.125V15C16.875 16.0355 16.0355 16.875 15 16.875H13.125C12.0895 16.875 11.25 16.0355 11.25 15V13.125Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Dashboard
                    </a>
                  </li>
                  <li>
                    <a [routerLink]="['admin-billing']" routerLinkActive="active">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.875 6.875H18.125M1.875 7.5H18.125M4.375 11.875H9.375M4.375 13.75H6.875M3.75 16.25H16.25C17.2855 16.25 18.125 15.4105 18.125 14.375V5.625C18.125 4.58947 17.2855 3.75 16.25 3.75H3.75C2.71447 3.75 1.875 4.58947 1.875 5.625V14.375C1.875 15.4105 2.71447 16.25 3.75 16.25Z" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Abrechnung
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!-- desktop menu sidebar -->
  <div class="menu_sidebar hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-sand px-6 pb-4">
      <div class="flex h-16 shrink-0 items-center">
        <img src="./assets/images/logo.svg" alt="Funos Logo">
      </div>
      <nav *ngIf="user.admin != true && !loading" class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li>
                <a [routerLink]="['overview']" routerLinkActive="active">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.125 5C3.125 3.96447 3.96447 3.125 5 3.125H6.875C7.91053 3.125 8.75 3.96447 8.75 5V6.875C8.75 7.91053 7.91053 8.75 6.875 8.75H5C3.96447 8.75 3.125 7.91053 3.125 6.875V5Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.125 13.125C3.125 12.0895 3.96447 11.25 5 11.25H6.875C7.91053 11.25 8.75 12.0895 8.75 13.125V15C8.75 16.0355 7.91053 16.875 6.875 16.875H5C3.96447 16.875 3.125 16.0355 3.125 15V13.125Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.25 5C11.25 3.96447 12.0895 3.125 13.125 3.125H15C16.0355 3.125 16.875 3.96447 16.875 5V6.875C16.875 7.91053 16.0355 8.75 15 8.75H13.125C12.0895 8.75 11.25 7.91053 11.25 6.875V5Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.25 13.125C11.25 12.0895 12.0895 11.25 13.125 11.25H15C16.0355 11.25 16.875 12.0895 16.875 13.125V15C16.875 16.0355 16.0355 16.875 15 16.875H13.125C12.0895 16.875 11.25 16.0355 11.25 15V13.125Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  Dashboard
                </a>
              </li>
              <li>
                <a [routerLink]="['deceases']" routerLinkActive="active" [class.active]="router.isActive('/dashboard/decease', false) || router.isActive('/dashboard/document', false)">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 15.9396C13.1941 16.1417 13.9281 16.25 14.6875 16.25C15.9198 16.25 17.0854 15.9647 18.122 15.4565C18.124 15.4088 18.125 15.3607 18.125 15.3125C18.125 13.414 16.5859 11.875 14.6875 11.875C13.5058 11.875 12.4633 12.4713 11.8446 13.3793M12.5 15.9396V15.9375C12.5 15.0101 12.2624 14.1382 11.8446 13.3793M12.5 15.9396C12.5 15.9693 12.4997 15.999 12.4992 16.0286C10.9478 16.9627 9.1304 17.5 7.1875 17.5C5.2446 17.5 3.4272 16.9627 1.87577 16.0286C1.87526 15.9983 1.875 15.9679 1.875 15.9375C1.875 13.0035 4.25349 10.625 7.1875 10.625C9.1941 10.625 10.9409 11.7375 11.8446 13.3793M10 5.3125C10 6.8658 8.7408 8.125 7.1875 8.125C5.6342 8.125 4.375 6.8658 4.375 5.3125C4.375 3.7592 5.6342 2.5 7.1875 2.5C8.7408 2.5 10 3.7592 10 5.3125ZM16.875 7.1875C16.875 8.39562 15.8956 9.375 14.6875 9.375C13.4794 9.375 12.5 8.39562 12.5 7.1875C12.5 5.97938 13.4794 5 14.6875 5C15.8956 5 16.875 5.97938 16.875 7.1875Z" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  Sterbefälle
                </a>
              </li>
              <li>
                <a [routerLink]="['products']" routerLinkActive="active" [class.active]="router.isActive('/dashboard/product', false)">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 6.25L10 1.875L2.5 6.25M17.5 6.25L10 10.625M17.5 6.25V13.75L10 18.125M2.5 6.25L10 10.625M2.5 6.25V13.75L10 18.125M10 10.625V18.125" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  Produkte
                </a>
              </li>
              <li>
                <a href="#" routerLinkActive="active">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.875 6.875H18.125M1.875 7.5H18.125M4.375 11.875H9.375M4.375 13.75H6.875M3.75 16.25H16.25C17.2855 16.25 18.125 15.4105 18.125 14.375V5.625C18.125 4.58947 17.2855 3.75 16.25 3.75H3.75C2.71447 3.75 1.875 4.58947 1.875 5.625V14.375C1.875 15.4105 2.71447 16.25 3.75 16.25Z" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  Finanzen
                </a>
              </li>
            </ul>
          </li>
          <li class="mt-auto">
            <a [routerLink]="['settings']" routerLinkActive="active">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.89506 2.62668C6.95534 2.26504 7.26823 1.99998 7.63486 1.99998H8.36417C8.7308 1.99998 9.04369 2.26504 9.10396 2.62668L9.20327 3.22256C9.25042 3.50541 9.45903 3.73224 9.72386 3.8422C9.98881 3.9522 10.293 3.93751 10.5265 3.77077L11.0182 3.41954C11.3165 3.20644 11.7252 3.24026 11.9845 3.49951L12.5002 4.01521C12.7594 4.27446 12.7932 4.68313 12.5801 4.98147L12.2288 5.47336C12.0621 5.70673 12.0474 6.01087 12.1573 6.27574C12.2673 6.54051 12.494 6.74907 12.7768 6.7962L13.3728 6.89553C13.7345 6.95581 13.9995 7.2687 13.9995 7.63533V8.36464C13.9995 8.73127 13.7345 9.04416 13.3728 9.10443L12.7769 9.20375C12.4941 9.25089 12.2673 9.4595 12.1573 9.72433C12.0473 9.98926 12.062 10.2935 12.2287 10.5269L12.5799 11.0185C12.793 11.3169 12.7592 11.7256 12.4999 11.9848L11.9842 12.5005C11.725 12.7597 11.3163 12.7936 11.018 12.5805L10.5262 12.2292C10.2928 12.0625 9.98868 12.0478 9.72379 12.1578C9.459 12.2677 9.25042 12.4945 9.20329 12.7773L9.10396 13.3733C9.04369 13.7349 8.7308 14 8.36417 14H7.63486C7.26823 14 6.95534 13.7349 6.89506 13.3733L6.79575 12.7774C6.74861 12.4946 6.53999 12.2677 6.27517 12.1578C6.01023 12.0478 5.70601 12.0625 5.47258 12.2292L4.98083 12.5805C4.68249 12.7936 4.27382 12.7597 4.01457 12.5005L3.49887 11.9848C3.23963 11.7255 3.2058 11.3169 3.4189 11.0185L3.77026 10.5266C3.93695 10.2933 3.95165 9.98911 3.84168 9.72424C3.73176 9.45947 3.50497 9.25089 3.22219 9.20376L2.62621 9.10443C2.26457 9.04416 1.99951 8.73127 1.99951 8.36464V7.63533C1.99951 7.2687 2.26457 6.95581 2.62621 6.89553L3.22208 6.79622C3.50493 6.74908 3.73177 6.54046 3.84172 6.27563C3.95173 6.01068 3.93704 5.70645 3.7703 5.47301L3.41916 4.98143C3.20607 4.68309 3.23989 4.27441 3.49913 4.01517L4.01483 3.49947C4.27408 3.24022 4.68275 3.2064 4.98109 3.4195L5.47282 3.77073C5.70621 3.93744 6.01036 3.95213 6.27525 3.84216C6.54003 3.73223 6.7486 3.50544 6.79573 3.22265L6.89506 2.62668Z" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.99998 8C9.99998 9.10457 9.10455 10 7.99998 10C6.89541 10 5.99998 9.10457 5.99998 8C5.99998 6.89543 6.89541 6 7.99998 6C9.10455 6 9.99998 6.89543 9.99998 8Z" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Einstellungen
            </a>
          </li>
        </ul>
      </nav>
      <nav *ngIf="user.admin == true && !loading" class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li>
                <a [routerLink]="['overview']" routerLinkActive="active">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.125 5C3.125 3.96447 3.96447 3.125 5 3.125H6.875C7.91053 3.125 8.75 3.96447 8.75 5V6.875C8.75 7.91053 7.91053 8.75 6.875 8.75H5C3.96447 8.75 3.125 7.91053 3.125 6.875V5Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.125 13.125C3.125 12.0895 3.96447 11.25 5 11.25H6.875C7.91053 11.25 8.75 12.0895 8.75 13.125V15C8.75 16.0355 7.91053 16.875 6.875 16.875H5C3.96447 16.875 3.125 16.0355 3.125 15V13.125Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.25 5C11.25 3.96447 12.0895 3.125 13.125 3.125H15C16.0355 3.125 16.875 3.96447 16.875 5V6.875C16.875 7.91053 16.0355 8.75 15 8.75H13.125C12.0895 8.75 11.25 7.91053 11.25 6.875V5Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.25 13.125C11.25 12.0895 12.0895 11.25 13.125 11.25H15C16.0355 11.25 16.875 12.0895 16.875 13.125V15C16.875 16.0355 16.0355 16.875 15 16.875H13.125C12.0895 16.875 11.25 16.0355 11.25 15V13.125Z" stroke="#505050" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  Dashboard
                </a>
              </li>
              <li>
                <a [routerLink]="['admin-billing']" routerLinkActive="active">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.875 6.875H18.125M1.875 7.5H18.125M4.375 11.875H9.375M4.375 13.75H6.875M3.75 16.25H16.25C17.2855 16.25 18.125 15.4105 18.125 14.375V5.625C18.125 4.58947 17.2855 3.75 16.25 3.75H3.75C2.71447 3.75 1.875 4.58947 1.875 5.625V14.375C1.875 15.4105 2.71447 16.25 3.75 16.25Z" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  Abrechnung
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!-- main area -->
  <div class="lg:pl-72">
    <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <!-- mobile burger menu -->
      <button (click)="showMobileNav = !showMobileNav" type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden">
        <span class="sr-only">Open sidebar</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
      <!-- separator -->
      <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"></div>
      <!-- top nav -->
      <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <!-- search -->
        <form class="relative flex flex-1" action="#" method="GET">
          <label for="search-field" class="sr-only">Suche</label>
          <svg class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clip-rule="evenodd" />
          </svg>
          <input id="search-field" class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Suchen ..." type="search" name="search">
        </form>
        <!-- profile info -->
        <div class="flex items-center gap-x-4 lg:gap-x-6">
          <div class="relative">
            <button (click)="showUserDropdown = !showUserDropdown" type="button" class="-m-1.5 flex items-center p-1.5 gap-4" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">User Menü öffnen</span>
              <span class="hidden lg:flex lg:items-center">
                <span class="" aria-hidden="true">{{ user?.name }}</span>
              </span>
              <ng-container *ngIf="user?.acf?.profilbild">
                <img class="h-10 w-10 rounded-full bg-gray-50 object-cover" [src]="user?.acf?.profilbild" alt="">
              </ng-container>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.41083 6.91076C4.73626 6.58533 5.2639 6.58533 5.58934 6.91076L10.0001 11.3215L14.4108 6.91076C14.7363 6.58533 15.2639 6.58533 15.5893 6.91076C15.9148 7.2362 15.9148 7.76384 15.5893 8.08928L10.5893 13.0893C10.2639 13.4147 9.73627 13.4147 9.41083 13.0893L4.41083 8.08928C4.08539 7.76384 4.08539 7.2362 4.41083 6.91076Z" fill="#333333"/>
              </svg>
            </button>
            <div *ngIf="showUserDropdown" class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
              <a (click)="showUserDropdown = false" [routerLink]="['profile']" class="block px-3 py-1">Mein Profil</a>
              <a (click)="logout()" href="#" class="block px-3 py-1">Abmelden</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- dynamic content area -->
    <main class="py-10">
      <div class="px-4 sm:px-6 lg:px-8">
        <ng-container *ngIf="!loading">
          <router-outlet (activate)="onActivate($event)"></router-outlet>
        </ng-container>
      </div>
    </main>
  </div>
</div>
