import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from '../services/cookie.service';
import { WpService } from '../services/wp.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  user: String = '';
  password: String = '';
  token: String = '';
  error = false;
  loading = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookie: CookieService,
    private wp: WpService) {
    
  }

  ngOnInit(): void {
    
  }

  submitLogin() {
    // loading start
    this.loading = true;
    // get token
    this.wp.getToken(this.user, this.password).subscribe(
      (response: any) => {
      if (response['token']) {
        // store token as cookie
        this.cookie.set('funos_token', response['token']);
        // redirect to dashboard
        this.router.navigate(['/dashboard']);
        // loading end
        this.loading = false;
      }
    },
    (error: any) => {
      // set error
      this.error = error.error.message;
      // loading end
      this.loading = false;
    });
  }
}
