import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../services/cookie.service';
import { WpService } from '../services/wp.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  user: any = [];
  deceases: any = [];
  showUserDropdown = false;
  showMobileNav = false;
  loading = true;

  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private wp: WpService,
    public router: Router) {
    // get user
    this.wp.getUserData().subscribe(
      (result: any) => {
        this.user = result;
        this.user.admin = this.isAdmin();
        this.loading = false;
    });
  }

  ngOnInit(): void {
    
  }

  isAdmin() {
    if (this.user.user_role.indexOf('administrator') !== -1) {
      return true;
    }
    return false;
  }

  onActivate(component: any) {
    component.user = this.user;
  }

  // logout
  logout() {
    // delete token cookie
    this.cookie.remove('funos_token');
    // redirect to login
    this.router.navigate(['/login']);
  }
}