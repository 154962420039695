<div class="md:flex md:items-center md:justify-between">
	<div class="min-w-0 flex gap-4 items-center">
		<h1>Alle Sterbefälle</h1>
		<button type="button" class="simple" (click)="archive = !archive; loadDeceases(archive)">
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			  <path d="M2.49999 6.51735C2.57468 6.50593 2.65131 6.5 2.7295 6.5H13.2705C13.3487 6.5 13.4253 6.50593 13.5 6.51735M2.49999 6.51735C1.70265 6.6392 1.12654 7.38588 1.24458 8.21213L1.816 12.2121C1.92157 12.9511 2.55445 13.5 3.30093 13.5H12.699C13.4455 13.5 14.0784 12.9511 14.184 12.2121L14.7554 8.21213C14.8734 7.38588 14.2973 6.6392 13.5 6.51735M2.49999 6.51735V4C2.49999 3.17157 3.17156 2.5 3.99999 2.5H6.58577C6.85099 2.5 7.10534 2.60536 7.29288 2.79289L8.70709 4.20711C8.89463 4.39464 9.14898 4.5 9.4142 4.5H12C12.8284 4.5 13.5 5.17157 13.5 6V6.51735" stroke="#A6A6A6" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
			<span>{{ archive == false ? 'Archiv öffnen' : 'Archiv schließen' }}</span>
		</button>
	</div>
 	<div class="mt-4 flex md:ml-4 md:mt-0 gap-2">
		<app-view [viewInput]="view_data" (viewOutput)="getViewData($event)"></app-view>
		<button [routerLink]="['../decease']" type="button" class="primary">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75V9.25H6.75C6.33579 9.25 6 9.58579 6 10C6 10.4142 6.33579 10.75 6.75 10.75H9.25V13.25C9.25 13.6642 9.58579 14 10 14C10.4142 14 10.75 13.6642 10.75 13.25V10.75H13.25C13.6642 10.75 14 10.4142 14 10C14 9.58579 13.6642 9.25 13.25 9.25H10.75V6.75Z" fill="#0F172A"/>
      </svg>
			<span>Erstellen</span>
		</button>
	</div>
</div>
<div *ngIf="loading" class="py-4">
  <div role="status">
    <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#333333"/>
    </svg>
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div *ngIf="deceases.length == 0 && !loading">Keine Sterbefälle gefunden!</div>
<app-list *ngIf="deceases.length > 0 && !loading" [data]="list_data" (action)="processListAction($event)"></app-list>