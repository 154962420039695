import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { WpService } from '../../services/wp.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  // vars
  loading: boolean = true;
  appointments: any = [];
  appointment_slide_over: boolean = false;
  @Input() user: any = [];
  deceases: any = [];
  // form for product
  form_appointment: FormGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
    description: new FormControl(),
    decease: new FormControl('', Validators.required),
  });
  // constructor
  constructor(
    private wp: WpService) {

  }
  // init
  ngOnInit(): void {
    this.loadAppointments()
  }
  // load appointments
  loadAppointments() {
    this.loading = true;
    this.appointment_slide_over = false;
    this.wp.getAppointments(this.user.acf.undertaker.ID).subscribe((response:any) => {
      this.appointments = response;
      this.loading = false;
    });
  }
  // open appointment
  openAppointment() {
    // open slide over
    this.appointment_slide_over = true;
    // get deceases
    if (this.deceases.length == 0) {
      this.getDeceases();
    }
  }
  // get deceases
  async getDeceases() {
    // get deceases
    this.deceases = await firstValueFrom(this.wp.getDeceases(this.user.acf.undertaker.ID, false));
  }
  // save appointment
  saveAppointment(data: any, appointment_id: any) {
    this.loading = true;
    this.appointment_slide_over = false;
    // set undertaker
    data.undertaker = this.user.acf.undertaker.ID;
    // set data
    var data_json = JSON.stringify({
      'title': this.user.acf.undertaker.acf.name + ' - ' + data.date + ' - ' + data.title, 
      'fields': data,
      'status': 'publish',
    });
    // create
    if (appointment_id == false) {
      this.wp.createAppointment(data_json).subscribe((response:any) => {
        // reload
        this.loadAppointments();
      });
    }
    // update
    else {
      this.wp.updateAppointment(appointment_id, data_json).subscribe((response:any) => {
        // reload
        this.loadAppointments();
      });
    }
  }
}
