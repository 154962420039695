import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-listfield',
  templateUrl: './listfield.component.html',
  styleUrls: ['./listfield.component.css']
})
export class ListfieldComponent implements OnInit {
  // input unique id
  @Input() unique_id: String;
  // input data
  @Input() input_data: any = [];
  // output data
  @Output() output_data: EventEmitter<void> = new EventEmitter<void>();
  // working data
  data: any = [];
  // constructor
  constructor() {

  }
  // init
  ngOnInit(): void {
    // get input data
    this.data = this.input_data;
  }
  // add field
  addField() {
    // add empty entry
    this.data.push('');
  }
  // delete field
  deleteField(index: any, type: any) {
    this.data.splice(index, 1);
    setTimeout(() =>  {
      this.updateListfield();
    }, 1000);
  }
  // delete all fields
  deleteAllFields() {
    this.data = [];
    setTimeout(() =>  {
      this.updateListfield();
    }, 1000);
  }
  // update listfield
  updateListfield() {
    var update_data: any = [];
    this.data.forEach((value: any, index: any) => {
      update_data.push((<HTMLInputElement>document.getElementById(this.unique_id + '_listfield_entry_' + index)).value);
    });
    this.output_data.emit(update_data);
  }
}
