<div *ngIf="!loading && type == 'bid_new'">
	<!-- header -->
  <div class="py-8 md:flex md:items-center md:justify-between">
  	<div class="min-w-0 flex-1">
  		<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}
      </h2>
			<p class="text-sand mt-2">Sterbetag: {{ decease.acf?.deathday }}</p>
  	</div>
   	<div class="mt-4 flex gap-2 md:ml-4 md:mt-0">
   		<button class="secondary" [routerLink]="['/dashboard/decease/' + decease.id]">Zurück</button>
    </div>
  </div>
  <div class="p-8 border border-grey-light rounded bg-white custom-shadow">
  	<div class="flex grow gap-x-4 items-center">
			<img class="h-16 w-16 flex-none rounded-full bg-gray-200 object-cover" [src]="'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='" alt="">
			<div class="min-w-0 flex-auto">
				<p class="font-bold text-txt-large">Kostenvoranschlag</p>
				<p class="text-sand mt-1">{{ document.bid_counter }} - {{ (document.timestamp | date:"dd.MM.YYYY") }}</p>
			</div>
		</div>
		<div class="flex items-start justify-start gap-6 mt-8">
			<div class="bg-grey-light rounded p-6">
				<div #pdfContainer class="pdf_container bg-white">
					<table class="header_table" data-pdfmake="{'widths':[230,'*'], 'layout':'noBorders'}" >
						<tr>
							<td style="width: 50%;"></td>
							<td style="width: 50%;" class="text-right">
								<p *ngIf="!decease.acf.undertaker.acf?.logo?.url">{{ user.acf.undertaker.post_title }}</p>
								<p *ngIf="decease.acf.undertaker.acf?.logo?.url"><img [src]="decease.acf.undertaker.acf.logo.url" width="200" /></p>
							</td>
						</tr>
					</table>
					<table class="header_table" data-pdfmake="{'widths':[230,'*'], 'layout':'noBorders', &quot;margin&quot;:[0,80,0,0]}" style="margin: 80pt 0 0 0;">
						<tr>
							<td>
								<div class="recipient">
									<p class="text-sm text-size-small">
										{{ document.recipient.acf.firstname }} {{ document.recipient.acf.lastname }}<br>
										{{ document.recipient.acf.address }}<br>
										{{ document.recipient.acf.zip }} {{ document.recipient.acf.city }}
									</p>
								</div>
							</td>
							<td class="align-bottom">
									<div class="meta text-sm text-right text-size-small">
										Angebotsnummer: {{ document.bid_counter }}<br>
										Angebotsdatum: {{ (document.timestamp | date:"dd.MM.YYYY") }}
									</div>
							</td>
					</table>
					<div class="content" style="margin: 40pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,40,0,0]}">
						<span class="text-lg font-bold text-size-large">Kostenvoranschlag</span>
						<p *ngIf="decease.acf?.gender == 'männlich'" class="text-sm text-size-small">Kostenvoranschlag für den am {{ (decease.acf?.deathday | date:"dd.MM.YYYY") }} verstorbenen {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}.</p> 
						<p *ngIf="decease.acf?.gender == 'weiblich'" class="text-sm text-size-small">Kostenvoranschlag für die am {{ (decease.acf?.deathday | date:"dd.MM.YYYY") }} verstorbene {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}.</p>
						<div  *ngFor="let key of Object.keys(document.products_block)" style="margin: 25pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,25,0,0]}">
							<div *ngIf="document.products_block[key].products.length">
								<span class="text-m text-size-medium">{{ document.products_block[key].type }}</span>
								<table data-pdfmake="{'widths':[30,150,50,'*','*','*'], 'layout':'noBorders', &quot;margin&quot;:[0,5,0,5]}" width="100" style="margin: 5pt 0 5pt 0;">
									<tr>
										<td class="text-sm text-size-small" style="width: 5%;"><strong>Pos</strong></td>
										<td class="text-sm text-size-small" style="width: 35%;"><strong>Beschreibung</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Menge</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Steuer</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Einzelpreis</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Gesamtpreis</strong></td>
									</tr>
								</table>
								<table data-pdfmake="{'widths':[30,150,50,'*','*','*'], 'layout':'noBorders'}" width="100">
									<tr *ngFor="let product of document.products_block[key].products; let i = index">
										<td class="text-sm text-size-small" style="width: 5%;">{{ i+1 }}</td>
										<td class="product text-sm text-size-small" style="width: 35%;">
											{{ product.name }}
											<div *ngIf="product.desc" class="desc text-sm text-gray-500">
												{{ product.desc }}
											</div>
										</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ product.amount }}</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ product.tax }}%</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ (product.price_single | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ (product.price | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
								</table>
								<table data-pdfmake="{'widths':['*','auto','auto','auto','auto','150'], 'layout':'noBorders'}" width="100">
									<tr>
										<td colspan="5" class="text-right text-sm text-size-small" style="width: 70%;">Netto</td>
										<td class="text-right text-sm text-size-small">{{ (document.products_block[key].sum | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
									<tr *ngFor="let tax_key of Object.keys(document.products_block[key].tax)">
										<td colspan="5" class="text-right text-sm text-size-small">{{ tax_key }}% Steuer von {{ (document.products_block[key].tax[tax_key].price | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small">{{ (document.products_block[key].tax[tax_key].tax | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
									<tr>
										<td colspan="5" class="text-right text-sm text-size-small"><strong>Gesamt</strong></td>
										<td class="text-right text-sm text-size-small"><strong>{{ (document.products_block[key].sum_total | number:"1.2-2":"de-DE") + " €" }}</strong></td>
									</tr>
								</table>
							</div>
						</div>
						<div style="margin: 25pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,25,0,0]}">
							<span class="text-m text-size-medium">Gesamtkosten</span>
							<table data-pdfmake="{'widths':[ '*','auto'], 'layout':'lightHorizontalLines'}" width="100">
								<tr>
									<td class="text-sm text-size-small">Nettopreis</td>
									<td class="text-right text-sm text-size-small">{{ (document.totals.netto| number:"1.2-2":"de-DE") + " €" }}</td>
								</tr>
								<ng-container *ngFor="let tax_key of Object.keys(document.totals)">
									<tr *ngIf="tax_key !== 'netto' && tax_key !== 'brutto'">
										<td class="text-sm text-size-small">{{ tax_key }}% Steuer von {{ (document.totals[tax_key].price | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small">{{ (document.totals[tax_key].tax | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
								</ng-container>
								<tr>
									<td class="text-sm text-size-small"><strong>Gesamtpreis</strong></td>
									<td class="text-right text-sm text-size-small"><strong>{{ (document.totals.brutto| number:"1.2-2":"de-DE") + " €" }}</strong></td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div #pdfFooter class="pdf_footer" style="font-size: 10pt;">
					<table class="footer_table color-white" data-pdfmake="{'widths':[48,'*','*',48], 'layout':'noBorders'}">
						<tr>
							<td>Leer</td>
							<td>
								Leer
							</td>
							<td>
								Leer
							</td>
							<td>Leer</td>
						</tr>
						<tr>
							<td></td>
							<td class="footer-td text-sm desc" style="width: 50%;">
								<strong>Anschrift</strong><br>
								<span [innerHTML]="decease.acf.undertaker.post_title | nl2br"></span><br/>
								<span [innerHTML]="decease.acf.undertaker.acf.address | nl2br"></span>
							</td>
							<td class="footer-td text-sm desc" style="width: 50%;">
								<strong>Bankdaten</strong><br>
								<span [innerHTML]="decease.acf.undertaker.acf.bankdetails | nl2br"></span>
							</td>
							<td></td>
						</tr>
					</table>
				</div>
			</div>
			<div class="flex-grow">
				<div>
					<p class="text-hl-small font-bold mb-4">Empfänger</p>
					<select (change)="changeRecipient($event)">
						<option *ngFor="let recipient of recipients, let i = index" [value]="i" [selected]="document.recipient.id == recipient.id">{{ recipient.acf.firstname }} {{ recipient.acf.lastname }}</option>
					</select>
					<p class="text-hl-small font-bold my-4">Produkte</p>
					<div *ngFor="let product of product_connections" class="relative flex items-baseline">
			      <div class="flex h-6 items-center">
			        <div>
			          <input type="checkbox" [value]="product.id" (change)="toggleProduct($event)" [checked]="checkProductVisibility(product.id)">
			        </div>
			      </div>
			      <div class="ml-3 text-sm leading-6">
			        <label>{{ product.acf.product.acf.name }}</label>
			      </div>
			    </div>
			    <button type="submit" class="primary mt-8" (click)="saveBid()">
		      	<span>Erstellen</span>
		      </button>
				</div>
			</div>
		</div>
  </div>
</div>
<div *ngIf="!loading && type == 'bid'">
	<!-- header -->
  <div class="py-8 md:flex md:items-center md:justify-between">
  	<div class="min-w-0 flex-1">
  		<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}
      </h2>
			<p class="text-sand mt-2">Sterbetag: {{ decease.acf?.deathday }}</p>
  	</div>
   	<div class="mt-4 flex gap-2 md:ml-4 md:mt-0">
   		<button class="secondary" [routerLink]="['/dashboard/decease/' + decease.id]">Zurück</button>
      <button type="submit" class="primary" (click)="downloadAsPDF()">
      	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				  <path d="M10.75 2.75C10.75 2.33579 10.4142 2 10 2C9.58579 2 9.25 2.33579 9.25 2.75V11.3636L6.29526 8.23503C6.01085 7.93389 5.53617 7.92033 5.23503 8.20474C4.9339 8.48915 4.92033 8.96383 5.20474 9.26497L9.45474 13.765C9.59642 13.915 9.79366 14 10 14C10.2063 14 10.4036 13.915 10.5453 13.765L14.7953 9.26497C15.0797 8.96383 15.0661 8.48915 14.765 8.20474C14.4638 7.92033 13.9892 7.93389 13.7047 8.23503L10.75 11.3636V2.75Z" fill="#333333"/>
				  <path d="M3.5 12.75C3.5 12.3358 3.16421 12 2.75 12C2.33579 12 2 12.3358 2 12.75V15.25C2 16.7688 3.23122 18 4.75 18H15.25C16.7688 18 18 16.7688 18 15.25V12.75C18 12.3358 17.6642 12 17.25 12C16.8358 12 16.5 12.3358 16.5 12.75V15.25C16.5 15.9404 15.9404 16.5 15.25 16.5H4.75C4.05964 16.5 3.5 15.9404 3.5 15.25V12.75Z" fill="#333333"/>
				</svg>
      	<span>Download</span>
      </button>
    </div>
  </div>
  <div class="p-8 border border-grey-light rounded bg-white custom-shadow">
  	<div class="flex items-center justify-between">
	  	<div class="flex gap-x-4 items-center">
				<img class="h-16 w-16 flex-none rounded-full bg-gray-200 object-cover" [src]="'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='" alt="">
				<div class="min-w-0 flex-auto">
					<p class="font-bold text-txt-large">Kostenvoranschlag</p>
					<p class="text-sand mt-1">{{ document.bid_counter }} - {{ (document.timestamp | date:"dd.MM.YYYY") }}</p>
				</div>
			</div>
			<div class="w-32">
				<dd class="w-full flex-none tracking-tight mt-1">
          <select (change)="updateDocumentStatus($event)" [value]="document.status" [attr.data-value]="document.status" class="document_status text-hl-small border-0 p-0">
              <option value="1">Erstellt</option>
              <option value="2">Genehmigt</option>
              <option value="3">Abgelehnt</option>
            </select>
        </dd>
			</div>
		</div>
		<div class="flex items-start justify-start gap-6 mt-8">
			<div class="bg-grey-light rounded p-6">
				<div #pdfContainer class="pdf_container bg-white">
					<table class="header_table" data-pdfmake="{'widths':[230,'*'], 'layout':'noBorders'}" >
						<tr>
							<td style="width: 50%;"></td>
							<td style="width: 50%;" class="text-right">
								<p *ngIf="!decease.acf.undertaker.acf?.logo?.url">{{ user.acf.undertaker.post_title }}</p>
								<p *ngIf="decease.acf.undertaker.acf?.logo?.url"><img [src]="decease.acf.undertaker.acf.logo.url" width="200" /></p>
							</td>
						</tr>
					</table>
					<table class="header_table" data-pdfmake="{'widths':[230,'*'], 'layout':'noBorders', &quot;margin&quot;:[0,80,0,0]}" style="margin: 80pt 0 0 0;">
						<tr>
							<td>
								<div class="recipient">
									<p class="text-sm text-size-small">
										{{ document.recipient.acf.firstname }} {{ document.recipient.acf.lastname }}<br>
										{{ document.recipient.acf.address }}<br>
										{{ document.recipient.acf.zip }} {{ document.recipient.acf.city }}
									</p>
								</div>
							</td>
							<td class="align-bottom">
									<div class="meta text-sm text-right text-size-small">
										Angebotsnummer: {{ document.bid_counter }}<br>
										Angebotsdatum: {{ (document.timestamp | date:"dd.MM.YYYY") }}
									</div>
							</td>
					</table>
					<div class="content" style="margin: 40pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,40,0,0]}">
						<span class="text-lg font-bold text-size-large">Kostenvoranschlag</span>
						<p *ngIf="decease.acf?.gender == 'männlich'" class="text-sm text-size-small">Kostenvoranschlag für den am {{ (decease.acf?.deathday | date:"dd.MM.YYYY") }} verstorbenen {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}.</p> 
						<p *ngIf="decease.acf?.gender == 'weiblich'" class="text-sm text-size-small">Kostenvoranschlag für die am {{ (decease.acf?.deathday | date:"dd.MM.YYYY") }} verstorbene {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}.</p>
						<div  *ngFor="let key of Object.keys(document.products_block)" style="margin: 25pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,25,0,0]}">
							<div *ngIf="document.products_block[key].products.length">
								<span class="text-m text-size-medium">{{ document.products_block[key].type }}</span>
								<table data-pdfmake="{'widths':[30,150,50,'*','*','*'], 'layout':'noBorders', &quot;margin&quot;:[0,5,0,5]}" width="100" style="margin: 5pt 0 5pt 0;">
									<tr>
										<td class="text-sm text-size-small" style="width: 5%;"><strong>Pos</strong></td>
										<td class="text-sm text-size-small" style="width: 35%;"><strong>Beschreibung</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Menge</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Steuer</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Einzelpreis</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Gesamtpreis</strong></td>
									</tr>
								</table>
								<table data-pdfmake="{'widths':[30,150,50,'*','*','*'], 'layout':'noBorders'}" width="100">
									<tr *ngFor="let product of document.products_block[key].products; let i = index">
										<td class="text-sm text-size-small" style="width: 5%;">{{ i+1 }}</td>
										<td class="product text-sm text-size-small" style="width: 35%;">
											{{ product.name }}
											<div *ngIf="product.desc" class="desc text-sm text-gray-500">
												{{ product.desc }}
											</div>
										</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ product.amount }}</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ product.tax }}%</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ (product.price_single | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ (product.price | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
								</table>
								<table data-pdfmake="{'widths':['*','auto','auto','auto','auto','150'], 'layout':'noBorders'}" width="100">
									<tr>
										<td colspan="5" class="text-right text-sm text-size-small" style="width: 70%;">Netto</td>
										<td class="text-right text-sm text-size-small">{{ (document.products_block[key].sum | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
									<tr *ngFor="let tax_key of Object.keys(document.products_block[key].tax)">
										<td colspan="5" class="text-right text-sm text-size-small">{{ tax_key }}% Steuer von {{ (document.products_block[key].tax[tax_key].price | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small">{{ (document.products_block[key].tax[tax_key].tax | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
									<tr>
										<td colspan="5" class="text-right text-sm text-size-small"><strong>Gesamt</strong></td>
										<td class="text-right text-sm text-size-small"><strong>{{ (document.products_block[key].sum_total | number:"1.2-2":"de-DE") + " €" }}</strong></td>
									</tr>
								</table>
							</div>
						</div>
						<div style="margin: 25pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,25,0,0]}">
							<span class="text-m text-size-medium">Gesamtkosten</span>
							<table data-pdfmake="{'widths':[ '*','auto'], 'layout':'lightHorizontalLines'}" width="100">
								<tr>
									<td class="text-sm text-size-small">Nettopreis</td>
									<td class="text-right text-sm text-size-small">{{ (document.totals.netto| number:"1.2-2":"de-DE") + " €" }}</td>
								</tr>
								<ng-container *ngFor="let tax_key of Object.keys(document.totals)">
									<tr *ngIf="tax_key !== 'netto' && tax_key !== 'brutto'">
										<td class="text-sm text-size-small">{{ tax_key }}% Steuer von {{ (document.totals[tax_key].price | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small">{{ (document.totals[tax_key].tax | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
								</ng-container>
								<tr>
									<td class="text-sm text-size-small"><strong>Gesamtpreis</strong></td>
									<td class="text-right text-sm text-size-small"><strong>{{ (document.totals.brutto| number:"1.2-2":"de-DE") + " €" }}</strong></td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div #pdfFooter class="pdf_footer" style="font-size: 10pt;">
					<table class="footer_table color-white" data-pdfmake="{'widths':[48,'*','*',48], 'layout':'noBorders'}">
						<tr>
							<td>Leer</td>
							<td>
								Leer
							</td>
							<td>
								Leer
							</td>
							<td>Leer</td>
						</tr>
						<tr>
							<td></td>
							<td class="footer-td text-sm desc" style="width: 50%;">
								<strong>Anschrift</strong><br>
								<span [innerHTML]="decease.acf.undertaker.post_title | nl2br"></span><br/>
								<span [innerHTML]="decease.acf.undertaker.acf.address | nl2br"></span>
							</td>
							<td class="footer-td text-sm desc" style="width: 50%;">
								<strong>Bankdaten</strong><br>
								<span [innerHTML]="decease.acf.undertaker.acf.bankdetails | nl2br"></span>
							</td>
							<td></td>
						</tr>
					</table>
				</div>
			</div>
			<div class="flex-grow" *ngIf="document.status != 3">
				<div>
					<p class="text-hl-small font-bold mb-4">Empfänger</p>
					<select (change)="changeRecipient($event)">
						<option *ngFor="let recipient of recipients, let i = index" [value]="i" [selected]="document.recipient.id == recipient.id">{{ recipient.acf.firstname }} {{ recipient.acf.lastname }}</option>
					</select>
					<p class="text-hl-small font-bold my-4">Produkte</p>
					<div *ngFor="let product of product_connections" class="relative flex items-baseline">
			      <div class="flex h-6 items-center">
			        <div>
			          <input type="checkbox" [value]="product.id" (change)="toggleProduct($event)" [checked]="checkProductVisibility(product.id)">
			        </div>
			      </div>
			      <div class="ml-3 text-sm leading-6">
			        <label>{{ product.acf.product.acf.name }}</label>
			      </div>
			    </div>
			    <button type="submit" class="primary mt-8" (click)="updateBid()">
		      	<span>Aktualisieren</span>
		      </button>
				</div>
			</div>
		</div>
  </div>
</div>
<div *ngIf="!loading && type == 'invoice_new'">
	<!-- header -->
  <div class="py-8 md:flex md:items-center md:justify-between">
  	<div class="min-w-0 flex-1">
  		<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}
      </h2>
			<p class="text-sand mt-2">Sterbetag: {{ decease.acf?.deathday }}</p>
  	</div>
   	<div class="mt-4 flex gap-2 md:ml-4 md:mt-0">
   		<button class="secondary" [routerLink]="['/dashboard/decease/' + decease.id]">Zurück</button>
    </div>
  </div>
  <div class="p-8 border border-grey-light rounded bg-white custom-shadow">
  	<div class="flex grow gap-x-4 items-center">
			<img class="h-16 w-16 flex-none rounded-full bg-gray-200 object-cover" [src]="'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='" alt="">
			<div class="min-w-0 flex-auto">
				<p class="font-bold text-txt-large">Rechnung</p>
				<p class="text-sand mt-1">{{ document.invoice_counter }} - {{ (document.timestamp | date:"dd.MM.YYYY") }}</p>
			</div>
		</div>
		<div class="flex items-start justify-start gap-6 mt-8">
			<div class="bg-grey-light rounded p-6">
				<div #pdfContainer class="pdf_container bg-white">
					<table class="header_table" data-pdfmake="{'widths':[230,'*'], 'layout':'noBorders'}" >
						<tr>
							<td style="width: 50%;"></td>
							<td style="width: 50%;" class="text-right">
								<p *ngIf="!decease.acf.undertaker.acf?.logo?.url">{{ user.acf.undertaker.post_title }}</p>
								<p *ngIf="decease.acf.undertaker.acf?.logo?.url"><img [src]="decease.acf.undertaker.acf.logo.url" width="200" /></p>
							</td>
						</tr>
					</table>
					<table class="header_table" data-pdfmake="{'widths':[230,'*'], 'layout':'noBorders', &quot;margin&quot;:[0,80,0,0]}" style="margin: 80pt 0 0 0;">
						<tr>
							<td>
								<div class="recipient">
									<p class="text-sm text-size-small">
										{{ document.recipient.acf.firstname }} {{ document.recipient.acf.lastname }}<br>
										{{ document.recipient.acf.address }}<br>
										{{ document.recipient.acf.zip }} {{ document.recipient.acf.city }}
									</p>
								</div>
							</td>
							<td class="align-bottom">
									<div class="meta text-sm text-right text-size-small">
										Rechnungsnummer: {{ document.invoice_counter }}<br>
										Angebotsnummer: {{ (document.timestamp | date:"dd.MM.YYYY") }}
									</div>
							</td>
					</table>
					<div class="content" style="margin: 40pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,40,0,0]}">
						<span class="text-lg font-bold text-size-large">Rechnung</span>
						<p *ngIf="decease.acf?.gender == 'männlich'" class="text-sm text-size-small">Rechnung für den am {{ (decease.acf?.deathday | date:"dd.MM.YYYY") }} verstorbenen {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}.</p> 
						<p *ngIf="decease.acf?.gender == 'weiblich'" class="text-sm text-size-small">Rechnung für die am {{ (decease.acf?.deathday | date:"dd.MM.YYYY") }} verstorbene {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}.</p>
						<div  *ngFor="let key of Object.keys(document.products_block)" style="margin: 25pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,25,0,0]}">
							<div *ngIf="document.products_block[key].products.length">
								<span class="text-m text-size-medium">{{ document.products_block[key].type }}</span>
								<table data-pdfmake="{'widths':[30,150,50,'*','*','*'], 'layout':'noBorders', &quot;margin&quot;:[0,5,0,5]}" width="100" style="margin: 5pt 0 5pt 0;">
									<tr>
										<td class="text-sm text-size-small" style="width: 5%;"><strong>Pos</strong></td>
										<td class="text-sm text-size-small" style="width: 35%;"><strong>Beschreibung</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Menge</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Steuer</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Einzelpreis</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Gesamtpreis</strong></td>
									</tr>
								</table>
								<table data-pdfmake="{'widths':[30,150,50,'*','*','*'], 'layout':'noBorders'}" width="100">
									<tr *ngFor="let product of document.products_block[key].products; let i = index">
										<td class="text-sm text-size-small" style="width: 5%;">{{ i+1 }}</td>
										<td class="product text-sm text-size-small" style="width: 35%;">
											{{ product.name }}
											<div *ngIf="product.desc" class="desc text-sm text-gray-500">
												{{ product.desc }}
											</div>
										</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ product.amount }}</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ product.tax }}%</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ (product.price_single | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ (product.price | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
								</table>
								<table data-pdfmake="{'widths':['*','auto','auto','auto','auto','150'], 'layout':'noBorders'}" width="100">
									<tr>
										<td colspan="5" class="text-right text-sm text-size-small" style="width: 70%;">Netto</td>
										<td class="text-right text-sm text-size-small">{{ (document.products_block[key].sum | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
									<tr *ngFor="let tax_key of Object.keys(document.products_block[key].tax)">
										<td colspan="5" class="text-right text-sm text-size-small">{{ tax_key }}% Steuer von {{ (document.products_block[key].tax[tax_key].price | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small">{{ (document.products_block[key].tax[tax_key].tax | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
									<tr>
										<td colspan="5" class="text-right text-sm text-size-small"><strong>Gesamt</strong></td>
										<td class="text-right text-sm text-size-small"><strong>{{ (document.products_block[key].sum_total | number:"1.2-2":"de-DE") + " €" }}</strong></td>
									</tr>
								</table>
							</div>
						</div>
						<div style="margin: 25pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,25,0,0]}">
							<span class="text-m text-size-medium">Gesamtkosten</span>
							<table data-pdfmake="{'widths':[ '*','auto'], 'layout':'lightHorizontalLines'}" width="100">
								<tr>
									<td class="text-sm text-size-small">Nettopreis</td>
									<td class="text-right text-sm text-size-small">{{ (document.totals.netto| number:"1.2-2":"de-DE") + " €" }}</td>
								</tr>
								<ng-container *ngFor="let tax_key of Object.keys(document.totals)">
									<tr *ngIf="tax_key !== 'netto' && tax_key !== 'brutto'">
										<td class="text-sm text-size-small">{{ tax_key }}% Steuer von {{ (document.totals[tax_key].price | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small">{{ (document.totals[tax_key].tax | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
								</ng-container>
								<tr>
									<td class="text-sm text-size-small"><strong>Gesamtpreis</strong></td>
									<td class="text-right text-sm text-size-small"><strong>{{ (document.totals.brutto| number:"1.2-2":"de-DE") + " €" }}</strong></td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div #pdfFooter class="pdf_footer" style="font-size: 10pt;">
					<table class="footer_table color-white" data-pdfmake="{'widths':[48,'*','*',48], 'layout':'noBorders'}">
						<tr>
							<td>Leer</td>
							<td>
								Leer
							</td>
							<td>
								Leer
							</td>
							<td>Leer</td>
						</tr>
						<tr>
							<td></td>
							<td class="footer-td text-sm desc" style="width: 50%;">
								<strong>Anschrift</strong><br>
								<span [innerHTML]="decease.acf.undertaker.post_title | nl2br"></span><br/>
								<span [innerHTML]="decease.acf.undertaker.acf.address | nl2br"></span>
							</td>
							<td class="footer-td text-sm desc" style="width: 50%;">
								<strong>Bankdaten</strong><br>
								<span [innerHTML]="decease.acf.undertaker.acf.bankdetails | nl2br"></span>
							</td>
							<td></td>
						</tr>
					</table>
				</div>
			</div>
			<div class="flex-grow">
				<div>
					<p class="text-hl-small font-bold mb-4">Empfänger</p>
					<select (change)="changeRecipient($event)">
						<option *ngFor="let recipient of recipients, let i = index" [value]="i" [selected]="document.recipient.id == recipient.id">{{ recipient.acf.firstname }} {{ recipient.acf.lastname }}</option>
					</select>
					<p class="text-hl-small font-bold my-4">Produkte</p>
					<p>Kostenvoranschlag</p>
					<select (change)="changeBidBasis($event)" class="mb-4">
						<ng-container *ngFor="let bid of bids">
							<option [value]="bid.id">{{ bid.acf.content.bid_counter }} ({{ bid.acf.content.recipient.acf.firstname }} {{ bid.acf.content.recipient.acf.lastname }})</option>
						</ng-container>
					</select>
					<div *ngFor="let product of product_connections" class="relative flex items-baseline">
			      <div class="flex h-6 items-center">
			        <div>
			          <input type="checkbox" [value]="product.id" (change)="toggleProduct($event)" [checked]="checkProductVisibility(product.id)">
			        </div>
			      </div>
			      <div class="ml-3 text-sm leading-6">
			        <label>{{ product.acf.product.acf.name }}</label>
			      </div>
			    </div>
			    <button type="submit" class="primary mt-8" (click)="saveInvoice()">
		      	<span>Erstellen</span>
		      </button>
				</div>
			</div>
		</div>
  </div>
</div>
<div *ngIf="!loading && type == 'invoice'">
	<!-- header -->
  <div class="py-8 md:flex md:items-center md:justify-between">
  	<div class="min-w-0 flex-1">
  		<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}
      </h2>
			<p class="text-sand mt-2">Sterbetag: {{ decease.acf?.deathday }}</p>
  	</div>
   	<div class="mt-4 flex gap-2 md:ml-4 md:mt-0">
   		<button class="secondary" [routerLink]="['/dashboard/decease/' + decease.id]">Zurück</button>
      <button type="submit" class="primary" (click)="downloadAsPDF()">
      	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				  <path d="M10.75 2.75C10.75 2.33579 10.4142 2 10 2C9.58579 2 9.25 2.33579 9.25 2.75V11.3636L6.29526 8.23503C6.01085 7.93389 5.53617 7.92033 5.23503 8.20474C4.9339 8.48915 4.92033 8.96383 5.20474 9.26497L9.45474 13.765C9.59642 13.915 9.79366 14 10 14C10.2063 14 10.4036 13.915 10.5453 13.765L14.7953 9.26497C15.0797 8.96383 15.0661 8.48915 14.765 8.20474C14.4638 7.92033 13.9892 7.93389 13.7047 8.23503L10.75 11.3636V2.75Z" fill="#333333"/>
				  <path d="M3.5 12.75C3.5 12.3358 3.16421 12 2.75 12C2.33579 12 2 12.3358 2 12.75V15.25C2 16.7688 3.23122 18 4.75 18H15.25C16.7688 18 18 16.7688 18 15.25V12.75C18 12.3358 17.6642 12 17.25 12C16.8358 12 16.5 12.3358 16.5 12.75V15.25C16.5 15.9404 15.9404 16.5 15.25 16.5H4.75C4.05964 16.5 3.5 15.9404 3.5 15.25V12.75Z" fill="#333333"/>
				</svg>
      	<span>Download</span>
      </button>
    </div>
  </div>
  <div class="p-8 border border-grey-light rounded bg-white custom-shadow">
  	<div class="flex items-center justify-between">
	  	<div class="flex gap-x-4 items-center">
				<img class="h-16 w-16 flex-none rounded-full bg-gray-200 object-cover" [src]="'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='" alt="">
				<div class="min-w-0 flex-auto">
					<p class="font-bold text-txt-large">Rechnung</p>
					<p class="text-sand mt-1">{{ document.invoice_counter }} - {{ (document.timestamp | date:"dd.MM.YYYY") }}</p>
				</div>
			</div>
			<div class="w-32">
				<dd class="w-full flex-none tracking-tight mt-1">
          <select (change)="updateDocumentStatus($event)" [value]="document.status" [attr.data-value]="document.status" class="document_status text-hl-small border-0 p-0">
              <option value="1">Erstellt</option>
              <option value="2">Genehmigt</option>
              <option value="3">Abgelehnt</option>
            </select>
        </dd>
			</div>
		</div>
		<div class="flex items-start justify-start gap-6 mt-8">
			<div class="bg-grey-light rounded p-6">
				<div #pdfContainer class="pdf_container bg-white">
					<table class="header_table" data-pdfmake="{'widths':[230,'*'], 'layout':'noBorders'}" >
						<tr>
							<td style="width: 50%;"></td>
							<td style="width: 50%;" class="text-right">
								<p *ngIf="!decease.acf.undertaker.acf?.logo?.url">{{ user.acf.undertaker.post_title }}</p>
								<p *ngIf="decease.acf.undertaker.acf?.logo?.url"><img [src]="decease.acf.undertaker.acf.logo.url" width="200" /></p>
							</td>
						</tr>
					</table>
					<table class="header_table" data-pdfmake="{'widths':[230,'*'], 'layout':'noBorders', &quot;margin&quot;:[0,80,0,0]}" style="margin: 80pt 0 0 0;">
						<tr>
							<td>
								<div class="recipient">
									<p class="text-sm text-size-small">
										{{ document.recipient.acf.firstname }} {{ document.recipient.acf.lastname }}<br>
										{{ document.recipient.acf.address }}<br>
										{{ document.recipient.acf.zip }} {{ document.recipient.acf.city }}
									</p>
								</div>
							</td>
							<td class="align-bottom">
									<div class="meta text-sm text-right text-size-small">
										Rechnungsnummer: {{ document.invoice_counter }}<br>
										Rechnungsdatum: {{ (document.timestamp | date:"dd.MM.YYYY") }}
									</div>
							</td>
					</table>
					<div class="content" style="margin: 40pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,40,0,0]}">
						<span class="text-lg font-bold text-size-large">Rechnung</span>
						<p *ngIf="decease.acf?.gender == 'männlich'" class="text-sm text-size-small">Rechnung für den am {{ (decease.acf?.deathday | date:"dd.MM.YYYY") }} verstorbenen {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}.</p> 
						<p *ngIf="decease.acf?.gender == 'weiblich'" class="text-sm text-size-small">Rechnung für die am {{ (decease.acf?.deathday | date:"dd.MM.YYYY") }} verstorbene {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}.</p>
						<div  *ngFor="let key of Object.keys(document.products_block)" style="margin: 25pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,25,0,0]}">
							<div *ngIf="document.products_block[key].products.length">
								<span class="text-m text-size-medium">{{ document.products_block[key].type }}</span>
								<table data-pdfmake="{'widths':[30,150,50,'*','*','*'], 'layout':'noBorders', &quot;margin&quot;:[0,5,0,5]}" width="100" style="margin: 5pt 0 5pt 0;">
									<tr>
										<td class="text-sm text-size-small" style="width: 5%;"><strong>Pos</strong></td>
										<td class="text-sm text-size-small" style="width: 35%;"><strong>Beschreibung</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Menge</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Steuer</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Einzelpreis</strong></td>
										<td class="text-right text-sm text-size-small" style="width: 15%;"><strong>Gesamtpreis</strong></td>
									</tr>
								</table>
								<table data-pdfmake="{'widths':[30,150,50,'*','*','*'], 'layout':'noBorders'}" width="100">
									<tr *ngFor="let product of document.products_block[key].products; let i = index">
										<td class="text-sm text-size-small" style="width: 5%;">{{ i+1 }}</td>
										<td class="product text-sm text-size-small" style="width: 35%;">
											{{ product.name }}
											<div *ngIf="product.desc" class="desc text-sm text-gray-500">
												{{ product.desc }}
											</div>
										</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ product.amount }}</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ product.tax }}%</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ (product.price_single | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small" style="width: 15%;">{{ (product.price | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
								</table>
								<table data-pdfmake="{'widths':['*','auto','auto','auto','auto','150'], 'layout':'noBorders'}" width="100">
									<tr>
										<td colspan="5" class="text-right text-sm text-size-small" style="width: 70%;">Netto</td>
										<td class="text-right text-sm text-size-small">{{ (document.products_block[key].sum | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
									<tr *ngFor="let tax_key of Object.keys(document.products_block[key].tax)">
										<td colspan="5" class="text-right text-sm text-size-small">{{ tax_key }}% Steuer von {{ (document.products_block[key].tax[tax_key].price | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small">{{ (document.products_block[key].tax[tax_key].tax | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
									<tr>
										<td colspan="5" class="text-right text-sm text-size-small"><strong>Gesamt</strong></td>
										<td class="text-right text-sm text-size-small"><strong>{{ (document.products_block[key].sum_total | number:"1.2-2":"de-DE") + " €" }}</strong></td>
									</tr>
								</table>
							</div>
						</div>
						<div style="margin: 25pt 0 0 0;" data-pdfmake="{&quot;margin&quot;:[0,25,0,0]}">
							<span class="text-m text-size-medium">Gesamtkosten</span>
							<table data-pdfmake="{'widths':[ '*','auto'], 'layout':'lightHorizontalLines'}" width="100">
								<tr>
									<td class="text-sm text-size-small">Nettopreis</td>
									<td class="text-right text-sm text-size-small">{{ (document.totals.netto| number:"1.2-2":"de-DE") + " €" }}</td>
								</tr>
								<ng-container *ngFor="let tax_key of Object.keys(document.totals)">
									<tr *ngIf="tax_key !== 'netto' && tax_key !== 'brutto'">
										<td class="text-sm text-size-small">{{ tax_key }}% Steuer von {{ (document.totals[tax_key].price | number:"1.2-2":"de-DE") + " €" }}</td>
										<td class="text-right text-sm text-size-small">{{ (document.totals[tax_key].tax | number:"1.2-2":"de-DE") + " €" }}</td>
									</tr>
								</ng-container>
								<tr>
									<td class="text-sm text-size-small"><strong>Gesamtpreis</strong></td>
									<td class="text-right text-sm text-size-small"><strong>{{ (document.totals.brutto| number:"1.2-2":"de-DE") + " €" }}</strong></td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div #pdfFooter class="pdf_footer" style="font-size: 10pt;">
					<table class="footer_table color-white" data-pdfmake="{'widths':[48,'*','*',48], 'layout':'noBorders'}">
						<tr>
							<td>Leer</td>
							<td>
								Leer
							</td>
							<td>
								Leer
							</td>
							<td>Leer</td>
						</tr>
						<tr>
							<td></td>
							<td class="footer-td text-sm desc" style="width: 50%;">
								<strong>Anschrift</strong><br>
								<span [innerHTML]="decease.acf.undertaker.post_title | nl2br"></span><br/>
								<span [innerHTML]="decease.acf.undertaker.acf.address | nl2br"></span>
							</td>
							<td class="footer-td text-sm desc" style="width: 50%;">
								<strong>Bankdaten</strong><br>
								<span [innerHTML]="decease.acf.undertaker.acf.bankdetails | nl2br"></span>
							</td>
							<td></td>
						</tr>
					</table>
				</div>
			</div>
			<div class="flex-grow" *ngIf="document.status != 3">
				<div>
					<p class="text-hl-small font-bold mb-4">Empfänger</p>
					<select (change)="changeRecipient($event)">
						<option *ngFor="let recipient of recipients, let i = index" [value]="i" [selected]="document.recipient.id == recipient.id">{{ recipient.acf.firstname }} {{ recipient.acf.lastname }}</option>
					</select>
					<p class="text-hl-small font-bold my-4">Produkte</p>
					<div *ngFor="let product of product_connections" class="relative flex items-baseline">
			      <div class="flex h-6 items-center">
			        <div>
			          <input type="checkbox" [value]="product.id" (change)="toggleProduct($event)" [checked]="checkProductVisibility(product.id)">
			        </div>
			      </div>
			      <div class="ml-3 text-sm leading-6">
			        <label>{{ product.acf.product.acf.name }}</label>
			      </div>
			    </div>
			    <button type="submit" class="primary mt-8" (click)="updateInvoice()">
		      	<span>Aktualisieren</span>
		      </button>
				</div>
			</div>
		</div>
  </div>
</div>
<div *ngIf="!loading && type == 'decease'">
	<!-- header -->
  <div class="py-8 md:flex md:items-center md:justify-between">
  	<div class="min-w-0 flex-1">
  		<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}
      </h2>
			<p class="text-sand mt-2">Sterbetag: {{ decease.acf?.deathday }}</p>
  	</div>
   	<div class="mt-4 flex gap-2 md:ml-4 md:mt-0">
   		<button class="secondary" [routerLink]="['/dashboard/decease/' + decease.id]">Zurück</button>
      <button type="submit" class="primary" (click)="downloadAsPDF()">
      	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				  <path d="M10.75 2.75C10.75 2.33579 10.4142 2 10 2C9.58579 2 9.25 2.33579 9.25 2.75V11.3636L6.29526 8.23503C6.01085 7.93389 5.53617 7.92033 5.23503 8.20474C4.9339 8.48915 4.92033 8.96383 5.20474 9.26497L9.45474 13.765C9.59642 13.915 9.79366 14 10 14C10.2063 14 10.4036 13.915 10.5453 13.765L14.7953 9.26497C15.0797 8.96383 15.0661 8.48915 14.765 8.20474C14.4638 7.92033 13.9892 7.93389 13.7047 8.23503L10.75 11.3636V2.75Z" fill="#333333"/>
				  <path d="M3.5 12.75C3.5 12.3358 3.16421 12 2.75 12C2.33579 12 2 12.3358 2 12.75V15.25C2 16.7688 3.23122 18 4.75 18H15.25C16.7688 18 18 16.7688 18 15.25V12.75C18 12.3358 17.6642 12 17.25 12C16.8358 12 16.5 12.3358 16.5 12.75V15.25C16.5 15.9404 15.9404 16.5 15.25 16.5H4.75C4.05964 16.5 3.5 15.9404 3.5 15.25V12.75Z" fill="#333333"/>
				</svg>
      	<span>Download</span>
      </button>
    </div>
  </div>
  <div class="p-8 border border-grey-light rounded bg-white custom-shadow">
  	<div class="flex grow gap-x-4 items-center">
			<img class="h-16 w-16 flex-none rounded-full bg-gray-200 object-cover" [src]="'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='" alt="">
			<div class="min-w-0 flex-auto">
				<p class="font-bold text-txt-large">Sterbefall</p>
				<p class="text-sand mt-1">Übersicht</p>
			</div>
		</div>
		<div class="flex items-start justify-start gap-6 mt-8">
			<div class="bg-grey-light rounded p-6">
				<div #pdfContainer class="pdf_container decease bg-white">
					<h1 class="mt-4 mb-4 text-2xl">Sterbefall: {{ decease.acf?.firstname }} {{ decease.acf?.lastname }}</h1>
					<div *ngIf="decease_doc_content.decease">
						<h3 class="mt-4 mb-4 text-xl">Verstorbener</h3>
						<table class="decease" data-pdfmake="{'widths':[110,110,110,'*']}" style="font-size: 10pt;">
							<tr>
								<td>Vorname</td>
								<td><strong>{{ decease.acf?.firstname }}</strong></td>
								<td>Nachname</td>
								<td><strong>{{ decease.acf?.lastname }}</strong></td>
							</tr>
							<tr>
								<td>Geburtstag</td>
								<td><strong>{{ decease.acf?.birthday }}</strong></td>
								<td>Sterbetag</td>
								<td><strong>{{ decease.acf?.deathday }}</strong></td>
							</tr>
							<tr>
								<td>Gebiet</td>
								<td><strong>{{ decease.acf?.region }}</strong></td>
								<td>Adresse</td>
								<td><strong>{{ decease.acf?.address }}</strong></td>
							</tr>
							<tr>
								<td>PLZ</td>
								<td><strong>{{ decease.acf?.zip }}</strong></td>
								<td>Ort</td>
								<td><strong>{{ decease.acf?.city }}</strong></td>
							</tr>
							<tr>
								<td>Religion</td>
								<td><strong>{{ decease.acf?.religion }}</strong></td>
								<td>Familienstand</td>
								<td><strong>{{ decease.acf?.maritalstatus }}</strong></td>
							</tr>
							<tr>
								<td>Todesursache</td>
								<td><strong>{{ decease.acf?.causeofdeath }}</strong></td>
								<td>Freigabe Totenschau</td>
								<td><strong>{{ decease.acf?.deathinspection }}</strong></td>
							</tr>
							<tr>
								<td>Sterbeort</td>
								<td><strong>{{ decease.acf?.placeofdeath }}</strong></td>
								<td>Geschlecht</td>
								<td><strong>{{ decease.acf?.gender }}</strong></td>
							</tr>
						</table>
					</div>
					<div *ngIf="decease_doc_content.client">
						<h3 class="mt-4 mb-4 text-xl">Auftraggeber</h3>
						<table class="client" data-pdfmake="{'widths':[110,110,110,'*']}" style="font-size: 10pt;">
							<tr>
								<td>Vorname</td>
								<td><strong>{{ decease.acf?.client_firstname }}</strong></td>
								<td>Nachname</td>
								<td><strong>{{ decease.acf?.client_lastname }}</strong></td>
							</tr>
							<tr>
								<td>Telefon</td>
								<td><strong>{{ decease.acf?.client_phone }}</strong></td>
								<td>Email</td>
								<td><strong>{{ decease.acf?.client_email }}</strong></td>
							</tr>
							<tr>
								<td>Adresse</td>
								<td><strong>{{ decease.acf?.client_address }}</strong></td>
								<td>Ort</td>
								<td><strong>{{ decease.acf?.client_zip }} {{ decease.acf?.client_city }}</strong></td>
							</tr>
							<tr>
								<td>Beziehung</td>
								<td><strong>{{ decease.acf?.client_connection }}</strong></td>
								<td></td>
								<td><strong></strong></td>
							</tr>
						</table>
					</div>
					<div *ngIf="decease_doc_content.products">
						<h3 class="mt-4 mb-4 text-xl">Produkte</h3>
						<table data-pdfmake="{'widths':['auto','*']}" style="font-size: 10pt;">
							<tr *ngFor="let product of product_connections">
								<td>{{ product.acf?.amount }}</td>
								<td>{{ product.acf?.product.post_title }}</td>
							</tr>
						</table>
					</div>
					<div *ngIf="decease_doc_content.documents">
						<h3 class="mt-4 mb-4 text-xl">Dokumente</h3>
						<table data-pdfmake="{'widths':['auto','*']}" style="font-size: 10pt;">
							<tr *ngFor="let document of needed_documents | keyvalue">
								<td>{{ document.value.name }}</td>
								<td>{{ (document.value.exists) ? "OK" : "-" }}</td>
							</tr>
						</table>
					</div>
					<div *ngIf="decease_doc_content.events">
						<h3 class="mt-4 mb-4 text-xl">Ablauf</h3>
						<table data-pdfmake="{'widths':['auto','*']}" style="font-size: 10pt;">
							<ng-container *ngFor="let event of events">
								<tr *ngIf="event.acf.show">
									<td>{{ event.acf?.name }}</td>
									<td>
										<div *ngIf="event.acf.custom_fields.length > 0">
	                  	<div *ngFor="let custom_field of event.acf.custom_fields; let i = index" class="mb-4">
	                      <p><strong>{{ custom_field.name }}</strong></p>
	                      <div *ngIf="custom_field.type == 'text' || custom_field.type == 'date' || custom_field.type == 'dropdown'">
	                      	<p>{{ custom_field.content ? custom_field.content : '-' }}</p>
	                      </div>
	                      <div *ngIf="custom_field.type == 'checkbox'">
	                      	<ng-container *ngFor="let checkbox of custom_field.value; let j = index">
	                      		<p *ngIf="custom_field.content[j] == true">{{ checkbox }}</p>
	                      	</ng-container>
	                      </div>
	                    </div>
	                  </div>
									</td>
								</tr>
							</ng-container>
						</table>
					</div>
				</div>
				<div #pdfFooter></div>
			</div>
			<div class="flex-grow">
				<div>
					<p class="text-hl-small font-bold mb-4">Konfiguration</p>
					<div class="relative flex items-baseline">
			      <div class="flex h-6 items-center">
			        <div>
			          <input type="checkbox" (change)="decease_doc_content.decease = !decease_doc_content.decease" checked="true">
			        </div>
			      </div>
			      <div class="ml-3 text-sm leading-6">
			        <label>Daten Verstorbener</label>
			      </div>
			    </div>
			    <div class="relative flex items-baseline">
			      <div class="flex h-6 items-center">
			        <div>
			          <input type="checkbox" (change)="decease_doc_content.client = !decease_doc_content.client"  checked="true">
			        </div>
			      </div>
			      <div class="ml-3 text-sm leading-6">
			        <label>Daten Auftraggeber</label>
			      </div>
			    </div>
			    <div class="relative flex items-baseline">
			      <div class="flex h-6 items-center">
			        <div>
			          <input type="checkbox" (change)="decease_doc_content.products = !decease_doc_content.products"  checked="true">
			        </div>
			      </div>
			      <div class="ml-3 text-sm leading-6">
			        <label>Produktliste</label>
			      </div>
			    </div>
			    <div class="relative flex items-baseline">
			      <div class="flex h-6 items-center">
			        <div>
			          <input type="checkbox" (change)="decease_doc_content.documents = !decease_doc_content.documents"  checked="true">
			        </div>
			      </div>
			      <div class="ml-3 text-sm leading-6">
			        <label>Übersicht Dokumente</label>
			      </div>
			    </div>
			    <div class="relative flex items-baseline">
			      <div class="flex h-6 items-center">
			        <div>
			          <input type="checkbox" (change)="decease_doc_content.events = !decease_doc_content.events"  checked="true">
			        </div>
			      </div>
			      <div class="ml-3 text-sm leading-6">
			        <label>Ablauf</label>
			      </div>
			    </div>
			    <div class="rounded border border-grey-light mt-4 p-4" *ngIf="decease_doc_content.events">
			    	<p class="font-bold mb-2">Ereignisse</p>
			    	<div *ngFor="let event of events" class="relative flex items-baseline">
				      <div class="flex h-6 items-center">
				        <div>
				          <input (change)="event.acf.show = !event.acf.show" type="checkbox" [checked]="event.acf.show">
				        </div>
				      </div>
				      <div class="ml-3 text-sm leading-6">
				        <label>{{ event.acf.name }}</label>
				      </div>
				    </div>
			    </div>
				</div>
			</div>
		</div>
  </div>
</div>
<div *ngIf="loading" class="py-4">
  <div role="status">
    <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#333"/>
    </svg>
    <span class="sr-only">Loading...</span>
  </div>
</div>