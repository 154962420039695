import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { WpService } from '../../services/wp.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {
  // vars
  loading: boolean = true;
  invoices: any = [];
  totals: any = {
    sum_open: 0,
    numb_open: 0,
    sum_paid: 0,
    numb_paid: 0,
  };
  invoices_slide_over: boolean = false;
  invoice_filter: number = 0;
  // user
  @Input() user: any = [];
  // constructor
  constructor(
    private wp: WpService) {

  }
  // init
  async ngOnInit() {
    // get documents
    this.invoices = await firstValueFrom(this.wp.getInvoices(this.user.acf.undertaker.ID));
    // prepare data
    this.prepareData();
    // update loading
    this.loading = false;
  }
  // prepare invoice data
  prepareData() {
    this.invoices.forEach((entry: any) => {
      if (entry.acf.status == '1') {
        this.totals.sum_open += entry.acf.content.totals.brutto;
        this.totals.numb_open++;
      }
      else if (entry.acf.status == '2') {
        var year_invoice = new Date(entry.acf.timestamp).getFullYear();
        var year_current = new Date().getFullYear();
        if (year_invoice == year_current) {
          this.totals.sum_paid += entry.acf.content.totals.brutto;
          this.totals.numb_paid++;
        }
      }
    });
  }
  // open invoice list
  openInvoiceList(filter: number) {
    this.invoices_slide_over = true;
    this.invoice_filter = filter;
  }
  // change invoice list filter
  changeInvoiceListFilter(event: Event) {
    this.invoice_filter = parseInt((event.target as HTMLSelectElement).value);
  }
  // filter invoices
  filterInvoices(filter: number) {
    return this.invoices.filter((item: any) => item.acf.status == filter);
  }
}
