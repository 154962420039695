import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WpService } from '../../services/wp.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { formatNumber } from '@angular/common';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-decease',
  templateUrl: './decease.component.html',
  styleUrls: ['./decease.component.css']
})

export class DeceaseComponent implements OnInit {
  // logged in user
  @Input() user: any = [];
  // decease id
  decease_id: String = this.route.snapshot.params?.['id'];
  // decease
  decease: any = [];
  // form for decease
  form_decease: FormGroup = new FormGroup({
    gender: new FormControl(),
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    birthday: new FormControl('', Validators.required),
    deathday: new FormControl('', Validators.required),
    region: new FormControl('', Validators.required),
    address: new FormControl(),
    zip: new FormControl(),
    city: new FormControl(),
    religion: new FormControl(),
    maritalstatus: new FormControl(),
    deathinspection: new FormControl(),
    placeofdeath: new FormControl(),
    image: new FormControl(),
    pensioninsurance: new FormControl(),
  });
  // form for client
  form_contact: FormGroup = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    address: new FormControl(),
    zip: new FormControl(),
    city: new FormControl(),
    email: new FormControl(),
    phone: new FormControl(),
    connection: new FormControl(),
  });
  // form for event
  form_event: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    note: new FormControl(''),
  });
  // form for document
  form_document: FormGroup = new FormGroup({
    file: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
  });
  // show data helper
  show_data = false;
  // show error helper
  show_error = false;
  // loading helper (decease)
  loading_decease = false;
  // loading helper (products)
  loading_products = false;
  // loading helper (events)
  loading_events = false;
  // data saved helper
  saved = false;
  // active tab
  tab = 1;
  // undertaker
  undertaker: any = [];
  // slide over element visibility
  product_slide_over = false;
  event_slide_over = false;
  document_slide_over = false;
  process_slide_over = false;
  contact_slide_over = false;
  // event id
  event_id = false;
  // events
  events: any = [];
  // available products
  products: any = [];
  // current products choice
  products_choice: any = [];
  products_choice_add: any = [];
  products_choice_delete: any = [];
  // selected product
  // @ViewChild('selected_product') selected_product!: ElementRef;
  // selected process
  @ViewChild('selected_process') selected_process!: ElementRef;
  // available event templates
  event_templates: any = [];
  // available Process templates
  process_templates: any = [];
  // form for product
  form_product: FormGroup = new FormGroup({
    product: new FormControl('', Validators.required),
    amount: new FormControl('', Validators.required),
    price_single: new FormControl('', Validators.required),
    price: new FormControl('', Validators.required),
    note: new FormControl(),
    bid: new FormControl(),
    invoice: new FormControl(),
  });
  // product connections
  product_connections: any = [];
  // current product connection
  product_connection_id = false;
  // custom fields
  custom_fields: any = [];
  // totals
  totals: any = [];
  // bid
  bid: any = [];
  // loading helper (invoice)
  loading_invoice = false;
  // list data for product connections
  list_data_pc: any = {};
  // documents
  documents: any = [];
  // loading helper (documents)
  loading_documents = false;
  // list data for documents
  list_data_doc: any = {};
  // needed documents
  needed_documents = {
    birth_certificate: false,
    death_certificate: false,
    dsgvo_confirmation: false,
  };
  // status color
  status_color: String = 'text-grey';
  // list data for events
  list_data_events: any = {};
  // show product list
  show_productlist = false;
  // loading helper (contacts)
  loading_contacts = false;
  // contacts
  contacts: any = [];
  // list data for contacts
  list_data_contacts: any = {};
  // contact id
  contact_id = false;
  // constructor
  constructor(
    private route: ActivatedRoute,
    private wp: WpService,
    private fb: FormBuilder,
    private router: Router) {
  }
  // init
  ngOnInit(): void {
    // get decease data
    this.getDecease(this.decease_id);
    // get undertaker data
    this.getUndertaker(this.user.acf.undertaker.ID);
    // load product connections
    this.loadProductConnections();
    // load documents
    this.loadDocuments();
  }

  // get decease
  getDecease(id : any) {
    // existing decease
    if (id) {
      this.wp.getDecease(id).subscribe((response:any) => {
        // check if decease belongs to current undertaker and is published
        if (this.user.acf.undertaker.ID == response.acf.undertaker.ID && response.status == 'publish') {
          // save decease data
          this.decease = response;
          // prefill forms with decease data
          this.prefillFormDecease();
          // show data
          this.show_data = true;
          // activate tab 1
          this.tab = 1;
          // set decease status color
          switch (this.decease.acf.status) {
            case '1':
              this.status_color = 'text-signal-red';
              break;
            case '2':
              this.status_color = 'text-signal-orange';
              break;
            case '3':
              this.status_color = 'text-signal-green';
              break;
          }
        }
        else {
          // show error
          this.show_error = true;
        }
      });
    }
    // new decease
    else {
      // show data
      this.show_data = true;
      // activate tab 2
      this.tab = 2;
    }
  }

  // update decease
  updateDecease(id: any) {
    // show loading
    this.loading_decease = true;
    // prepare fields
    this.form_decease.value.undertaker = this.user.acf.undertaker.ID;
    // image change
    if (typeof this.form_decease.value.image === 'object' && this.form_decease.value.image !== null) {
      // upload image
      this.wp.uploadFile(this.form_decease.value.image, this.form_decease.value.image.name).subscribe((response:any) => {
        // set image id
        this.form_decease.value.image = response.id;
        // update
        if (id) {
          // prepare data
          var data = JSON.stringify({
            'title': this.form_decease.value.firstname + ' ' + this.form_decease.value.lastname,
            'fields': this.form_decease.value,
            'status': 'publish',
          });
          // update
          this.wp.updateDecease(id, data).subscribe((response:any) => {
            // update cached decease data
            this.decease = response;
            // reset loading state
            this.loading_decease = false;
            // sucessfully saved
            this.saved = true;
            // update saved variable to automatically hide success notification
            setTimeout(() => {
                this.saved = false;
            }, 3000);
          });
        }
        // create
        else {
          // set status for new decease
          this.form_decease.value.status = 1;
          // prepare data
          var data = JSON.stringify({
            'title': this.form_decease.value.firstname + ' ' + this.form_decease.value.lastname,
            'fields': this.form_decease.value,
            'status': 'publish',
          });
          // update
          this.wp.createDecease(data).subscribe((response:any) => {
            // reload decease to have id within url
            this.router.navigate(['dashboard/decease/' + response.id]);
          });
        }
      });
    }
    // no image change
    else {
      // update
      if (id) {
        // prepare data
        var data = JSON.stringify({
          'title': this.form_decease.value.firstname + ' ' + this.form_decease.value.lastname,
          'fields': this.form_decease.value,
          'status': 'publish',
        });
        // update
        this.wp.updateDecease(id, data).subscribe((response:any) => {
          // update cached decease data
          this.decease = response;
          // reset loading state
          this.loading_decease = false;
          // sucessfully saved
          this.saved = true;
          // update saved variable to automatically hide success notification
          setTimeout(() => {
              this.saved = false;
          }, 3000);
        });
      }
      // create
      else {
        // set status for new decease
        this.form_decease.value.status = 1;
        // prepare data
        var data = JSON.stringify({
          'title': this.form_decease.value.firstname + ' ' + this.form_decease.value.lastname, 
          // 'fields': Object.assign(this.form_decease.value, this.form_client.value),
          'fields': this.form_decease.value,
          'status': 'publish',
        });
        // update
        this.wp.createDecease(data).subscribe((response:any) => {
          // reload decease to have id within url
          this.router.navigate(['dashboard/decease/' + response.id]);
        });
      }
    }
  }

  // update decease status
  updateDeceaseStatus(decease_id: any, event: any) {
    // set data
    var data = JSON.stringify({
      'fields': {
        'status': event.target.value,
      },
    });
    // update decease status
    this.wp.updateDecease(decease_id, data).subscribe((response:any) => {
      // get updated decease
      this.getDecease(decease_id);
    });
  }

  // delete decease
  deleteDecease(id: any) {
    // show loading
    this.loading_decease = true;
    // delete decease
    this.wp.deleteDecease(id).subscribe((response:any) => {
      // redirect to deceases
      this.router.navigate(['dashboard/deceases']);
    });
  }

  // prefill decease form
  prefillFormDecease() {
    this.form_decease.setValue({
      gender: this.decease.acf.gender ? this.decease.acf.gender : '',
      firstname: this.decease.acf.firstname ? this.decease.acf.firstname : '',
      lastname: this.decease.acf.lastname ? this.decease.acf.lastname : '',
      birthday: this.decease.acf.birthday ? this.decease.acf.birthday : '',
      deathday: this.decease.acf.deathday ? this.decease.acf.deathday : '',
      region: this.decease.acf.region ? this.decease.acf.region : '',
      address: this.decease.acf.address ? this.decease.acf.address : '',
      zip: this.decease.acf.zip ? this.decease.acf.zip : '',
      city: this.decease.acf.city ? this.decease.acf.city : '',
      religion: this.decease.acf.religion ? this.decease.acf.religion : '',
      maritalstatus: this.decease.acf.maritalstatus ? this.decease.acf.maritalstatus : '',
      deathinspection: this.decease.acf.deathinspection ? this.decease.acf.deathinspection : '',
      placeofdeath: this.decease.acf.placeofdeath ? this.decease.acf.placeofdeath : '',
      image: this.decease.acf.image ? this.decease.acf.image.id : '',
      pensioninsurance: this.decease.acf.pensioninsurance ? this.decease.acf.pensioninsurance : '',
    })
  }

  // get undertaker
  getUndertaker(id : any) {
    // get undertaker data
    this.wp.getUndertaker(id).subscribe((response:any) => {
      // convert region string to array
      response.acf.regions = response.acf.regions.split(',');
      // save undertaker data
      this.undertaker = response;
    });
  }

  // load products
  loadProducts() {
    this.wp.getProducts(this.user.acf.undertaker.ID).subscribe((response:any) => {
      this.products = response;
    });
  }

  // open product connection
  openProductConnection(id: any) {
    // update product connection id
    this.product_connection_id = id;
    // existing product
    if (id != false) {
      // get product connection data
      var product_connection = this.product_connections.find((x: any) => x.id === id);
      // prefill values
      this.form_product.setValue({
        product: product_connection.acf.product.ID ? product_connection.acf.product.ID : '',
        amount: product_connection.acf.amount ? product_connection.acf.amount : '',
        price_single: product_connection.acf.price_single ? product_connection.acf.price_single : '',
        price: product_connection.acf.price ? product_connection.acf.price : '',
        note: product_connection.acf.note ? product_connection.acf.note : '',
        bid: product_connection.acf.bid ? true : false,
        invoice: product_connection.acf.invoice ? true : false,
      });
    }
    // new product
    else {
      // empty values
      this.form_product.setValue({
        product: '',
        amount: 1,
        price_single: '',
        price: '',
        note: '',
        bid: false,
        invoice: false,
      });
    }
    // show slide over
    this.product_slide_over = true;
  }

  calcProductPrice(amount: any, type: any) {
    // product already set?
    if (this.form_product.value.product != false) {
      // get single price from form
      var price_single = this.form_product.value.price_single;
      // product change?
      if (type == 'product') {
        // get product
        var product = this.products.find((x: any) => x.id === parseInt(this.form_product.value.product));
        // get single price from product
        price_single = parseFloat(product.acf.price.replace(',', '.'));
      }
      // set form price fields
      this.form_product.patchValue({
        price_single: parseFloat(price_single.toString().replace(',', '.')),
        price: parseFloat(price_single.toString().replace(',', '.')) * parseFloat(amount),
      });
    }
  }

  // save product connection
  saveProductConnection(pconnection_id: any, data: any) {
    this.loading_products = true;
    // set decease id
    data.decease = this.decease_id;
    // update product connection
    if (pconnection_id != false) {
      // set data
      var data_json = JSON.stringify({
        'title': data.product + ' (' + this.user.acf.undertaker.post_title + ')', 
        'fields': data,
        'status': 'publish',
      });
      // update
      this.wp.updateProductConnection(pconnection_id, data_json).subscribe((response:any) => {
        // reload product connection list
        this.loadProductConnections();
      });
    }
    // create product connection
    else {
      // set data
      var data_json = JSON.stringify({
        'title': data.product + ' (' + this.user.acf.undertaker.post_title + ')', 
        'fields': data,
        'status': 'publish',
      });
      // create
      this.wp.createProductConnection(data_json).subscribe((response:any) => {
        // reload product connection list
        this.loadProductConnections();
      });
    }
    // hide slide over
    this.product_slide_over = false;
  }

  // get product connections
  loadProductConnections() {
    // loadeing update
    this.loading_products = true;
    // get product connections
    this.wp.getProductConnections(this.decease_id).subscribe((response:any) => {
      // save response
      this.product_connections = response;
      // reset total price
      this.totals['price'] = 0;
      // iterate over all product connections
      this.product_connections.forEach((element: any, index: any) => {
        // calculate total price
        this.totals['price'] += parseFloat(element.acf.price_total);
      });
      this.totals['price'] = Math.round(this.totals['price'] * 100) / 100;
      // update product conneciton list
      this.setListDataProducts(this.product_connections);
    });
  }

  // delete product connection
  deleteProductConnection(pconnection_id: any) {
    this.loading_products = true;
    this.wp.deleteProductConnection(pconnection_id).subscribe((response:any) => {
      // reload product connection list
      this.loadProductConnections();
    });
  }

  // load events templates
  loadEventTemplates() {
    this.wp.getEventTemplates(this.user.acf.undertaker.ID).subscribe((response:any) => {
      this.event_templates = response;
    });
  }

  loadProcessTemplates() {
    this.wp.getProcessTemplates(this.user.acf.undertaker.ID).subscribe((response:any) => {
      this.process_templates = response;
    });
  }

  // open event
  openEvent(id: any) {
    // set event id
    this.event_id = id;
     // reset form
    this.form_event.reset();
    // reset products
    this.products_choice = [];
    this.products_choice_delete = [];
    this.products_choice_add = [];
    // reset custom fields
    this.custom_fields = [];
    // existing event
    if (id != false) {
      // prefill event
      this.prefillEvent(id);
    }
    // new event
    else {
      
    }
    // show slide over
    this.event_slide_over = true;
    // hide show_productlist
    this.show_productlist = false;
  }

  // prefill event template
  prefillEventTemplate(event: any) {
    // event tempplate id
    var event_template_id = parseInt(event.target.value);
    // use template
    if (event_template_id != 0) {
      // get event template
      var event_template = JSON.parse(JSON.stringify(this.event_templates.find((x: any) => x.id === parseInt(event.target.value))));
      // prefill form
      this.form_event.setValue({
        name: event_template.acf.name,
        note: event_template.acf.note,
      });
      // prefill products
      this.products_choice = event_template.acf.products;
      // set custom fields
      if (event_template.acf.custom_fields) {
        this.custom_fields = JSON.parse(event_template.acf.custom_fields);
        this.custom_fields.forEach((element: any, index: any) => {
          this.custom_fields[index].value = this.custom_fields[index].value.split(',');
        });
      }
      else {
        this.custom_fields = [];
      }
    }
    // do not use template
    else {
      // reset form
      this.form_event.reset();
      // reset products
      this.products_choice = [];
    }
  }

  // prefill event
  prefillEvent(event_id: any) {
    // get event data
    var event = this.events.find((x: any) => x.id === event_id);
    // prefill form
    this.form_event.setValue({
      name: event.acf.name? event.acf.name : '',
      note: event.acf.note? event.acf.note : '',
    });
    // prefill products
    event.acf.pconnections.forEach((element: any) => {
      element.acf.product.pconnection_id = element.id;
      this.products_choice.push(element.acf.product);
    });
    // prefill custom fields
    if (event.acf.custom_fields) {
      this.custom_fields = JSON.parse(event.acf.custom_fields);
    }
  }

  // add event product
  addEventProduct(product_id: any) {
    this.products_choice.push(this.products.find((x: any) => x.id === product_id));
    this.products_choice_add.push(this.products.find((x: any) => x.id === product_id));
    this.show_productlist = false;
  }

  // remove event product
  removeEventProduct(product: any) {
    if (product.ID) {
      var selected_product_id = parseInt(product.ID);
      this.products_choice_delete.push(this.products_choice.find((x: any) => x.ID === selected_product_id));
      this.products_choice.splice(this.products_choice.findIndex((x: any) => x.ID === selected_product_id), 1);
    }
    else {
      var selected_product_id = parseInt(product.id);
      this.products_choice_delete.push(this.products_choice.find((x: any) => x.id === selected_product_id));
      this.products_choice.splice(this.products_choice.findIndex((x: any) => x.id === selected_product_id), 1);
    }
  }

  // load events
  loadEvents() {
    // update loading
    this.loading_events = true;
    // get events
    return this.wp.getEvents(this.decease_id).subscribe((response:any) => {
      // save events
      this.events = response;
      // update loading
      this.loading_events = false;
    });
  }

  // update event
  saveEvent(id: any) {
    // update loading
    this.loading_events = true;
    // set decease
    this.form_event.value.decease = this.decease_id;
    // set undertaker
    this.form_event.value.undertaker = this.user.acf.undertaker.ID;
    // set custom fields
    for (let i = 0; i < this.custom_fields.length; i++) {
      if (this.custom_fields[i].type != 'checkbox') {
        this.custom_fields[i].content = (<HTMLInputElement>document.getElementById('custom_field_content_' + i)).value;
        // check if date
        if (this.custom_fields[i].type == 'date') {
          // set appointment
          this.custom_fields[i].appointment = (<HTMLInputElement>document.getElementById('custom_field_content_' + i + '_appointment')).checked;
        }
      }
      else {
        this.custom_fields[i].content = [];
        for (let j = 0; j < this.custom_fields[i].value.length; j++) {
          this.custom_fields[i].content.push((<HTMLInputElement>document.getElementById('custom_field_content_' + i + '_' + j)).checked);
        }
      }
    }
    this.form_event.value.custom_fields = JSON.stringify(this.custom_fields);
    // prepare data
    var data = JSON.stringify({
      'title': this.form_event.value.name + ' (' + this.user.acf.undertaker.post_title + ')',
      'fields': this.form_event.value,
      'status': 'publish',
    });
    // update event
    if (id != false) {
      this.wp.updateEvent(id, data).subscribe((response:any) => {
        // remove product connections
        this.products_choice_delete.forEach((element: any) => {
          // delete existing product connection
          if (element.pconnection_id) {
            this.deleteProductConnection(element.pconnection_id);
          }
          // delete freshly added and removed product connection
          else {
            if (element.ID) {
              this.products_choice_add.splice(this.products_choice_add.findIndex((x: any) => x.ID === element.ID), 1);
            }
            else {
              this.products_choice_add.splice(this.products_choice_add.findIndex((x: any) => x.id === element.id), 1);
            }
          }
        });
        // add product connections
        this.products_choice_add.forEach((element: any) => {
          // product connection data
          var data = {
            product: (element.ID) ? element.ID : element.id,
            amount: 1,
            price_single: element.acf.price,
            price: element.acf.price,
            note: '',
            event: response.id,
          };
          // save product connection
          this.saveProductConnection(false, data);
        });
        // reload process
        this.loadProcess();
      });
    }
    // create event
    else {
      // create new event
      this.wp.createEvent(data).subscribe((response:any) => {
        // iterate over event products
        this.products_choice.forEach((element: any) => {
          // product connection data
          var data = {
            product: (element.ID) ? element.ID : element.id,
            amount: 1,
            price_single: element.acf.price,
            price: element.acf.price,
            note: '',
            event: response.id,
          };
          // save product connection
          this.saveProductConnection(false, data);
        });
        // reload process
        this.loadProcess();
      });
    }
    // create appointments
    this.custom_fields.forEach((element: any) => {
      if (element.appointment) {
        var data = {
          title: element.name,
          date: element.content,
          description: '',
          decease: this.decease_id,
          undertaker: this.user.acf.undertaker.ID,
        }
        this.saveAppointment(data, false);
      }
    });
    // hide slide over
    this.event_slide_over = false;
  }

  // delete event
  deleteEvent(event_id: any) {
    // loading update
    this.loading_events = true;
    // delete event
    this.wp.deleteEvent(event_id).subscribe((response: any) => {
      // reload events
      this.loadProcess();
    });
    // delete related product connections
    this.product_connections.forEach((element: any) => {
      if (element.acf.event) {
        if (element.acf.event.ID == event_id) {
          this.deleteProductConnection(element.id);
        }
      }
    });
  }

  // open process
  openProcess(id: any) {
    // show slide over
    this.process_slide_over = true;
  }

  // add process
  addProcess() {
    // start loading
    this.loading_events = true;
    // get process template
    this.wp.getProcessTemplate(this.selected_process.nativeElement.value).subscribe((response: any) => {
      // iterate over all connected events
      response.acf.events.forEach((entry: any, index: any) => {
        // prepare custom fields
        var custom_fields: any = [];
        if (entry.acf.custom_fields) {
          custom_fields = JSON.parse(entry.acf.custom_fields);
          custom_fields.forEach((element: any, index: any) => {
            if (custom_fields[index].value != '') {
              custom_fields[index].value = custom_fields[index].value.split(',');
            }
          });
        }
        // set up event data
        var data = JSON.stringify({
          'title': entry.acf.name + ' (' + this.user.acf.undertaker.post_title + ')',
          'fields': {
            'custom_fields': JSON.stringify(custom_fields),
            'decease': this.decease_id,
            'name': entry.acf.name,
            'note': entry.acf.note,
            'undertaker': this.user.acf.undertaker.ID,
            'sort' : Date.now() + parseInt(index),
          },
          'status': 'publish',
        });
        // create new event
        this.wp.createEvent(data).subscribe((response:any) => {
          // iterate over event products
          entry.acf.products.forEach((product: any) => {
            // product connection data
            var data = {
              product: (product.ID) ? product.ID : product.id,
              amount: 1,
              price_single: product.acf.price,
              price: product.acf.price,
              note: '',
              event: response.id,
            };
            // save product connection
            this.saveProductConnection(false, data);
          });
          // reload process
          this.loadProcess();
        });
      });
    });
    // hide slide over
    this.process_slide_over = false;
  }

  // load process
  async loadProcess() {
    this.loading_events = true;
    this.loadEventTemplates();
    this.loadProcessTemplates();
    this.loadProducts();
    this.events = await firstValueFrom(this.wp.getEvents(this.decease_id));
    this.product_connections = await firstValueFrom(this.wp.getProductConnections(this.decease_id));

    this.events.forEach((event: any, index: any) => {
      this.events[index].acf.pconnections = [];
      this.events[index].acf.custom_fields_parsed = JSON.parse(this.events[index].acf.custom_fields);
      this.product_connections.forEach((pconnection: any) => {
        if (pconnection.acf.event) {
          if (pconnection.acf.event.ID == event.id) {
            this.events[index].acf.pconnections.push(pconnection);
          }
        }
      });
    });
    // update event list
    this.setListDataEvents(this.events);
  }
  // on image file change
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form_decease.patchValue({
        image: file
      });
    }
  }
  // set list data for product connections
  setListDataProducts(data: any) {
    // empty list
    this.list_data_pc = {
      entries: [],
    };
    // fill list
    data.forEach((entry: any) => {
      var actions = [
        {
          name: 'Bearbeiten',
          trigger: 'edit',
        },
      ];
      if (entry.acf.bid == false && entry.acf.bid == '' && entry.acf.invoice == false && entry.acf.invoice == '' ) {
        actions.push(
          {
            name: 'Löschen',
            trigger: 'delete',
          },
        );
      }
      this.list_data_pc.entries.push({
        id: entry.id,
        actions: actions,
        image: {
          value: entry.acf.product.acf.image,
          text: entry.acf.product.acf.image,
          class: '',
        },
        headline: {
          value: entry.acf.product.post_title,
          text: entry.acf.product.post_title,
          class: '',
        },
        subline: {
          value: entry.acf.amount,
          text: 'Anzahl: ' + entry.acf.amount,
          class: '',
        },
        meta_top: {
          value: entry.acf.price_total,
          text: formatNumber(entry.acf.price_total, 'de-DE', '1.2-2') + '€',
          class: '',
        },
        meta_bottom: {
          value: entry.acf.price_total,
          text: 'inkl. ' + entry.acf.product.acf.tax + '% Steuer',
          class: '',
        },
      })
    });
    // loading complete
    this.loading_products = false;
  }
  // process list action for product connection
  processListActionProducts(action: any) {
    // click on entry || edit
    if (action.trigger == 'click' || action.trigger == 'edit') {
      // open product conntection
      this.openProductConnection(action.id)
    }
    // delete
    else if (action.trigger == 'delete') {
      this.deleteProductConnection(action.id);
    }
  }
  // load documents
  async loadDocuments() {
    // upadte loading
    this.loading_documents = true;
    // get documents
    this.documents = await firstValueFrom(this.wp.getDocuments(this.decease_id));
    // add decease document
    this.documents.push({
      id: this.decease_id,
      acf: {
        type: 'decease',
        timestamp: new Date(),
      }
    });
    // reset bid sum
    this.totals['bid'] = 0;
    // iterate over all documents
    this.documents.forEach((element: any) => {
      // calc bid sum
      if (element.acf.type == 'bid' && parseInt(element.acf.status) != 3) {
        this.totals['bid'] += element.acf.content.totals.brutto;
      }
    });
    // round bid sum
    this.totals['bid'] = Math.round(this.totals['bid'] * 100) / 100;
    // reset invoice sum
    this.totals['invoice'] = 0;
    // iterate over all documents
    this.documents.forEach((element: any) => {
      // calc invoice sum
      if (element.acf.type == 'invoice' && parseInt(element.acf.status) != 3) {
        this.totals['invoice'] += element.acf.content.totals.brutto;
      }
    });
    // round invoice sum
    this.totals['invoice'] = Math.round(this.totals['invoice'] * 100) / 100;
    // check needed documents
    for (const [key, value] of Object.entries(this.needed_documents)) {
      if (this.documents.find((x:any) => x.acf.type == key) !== undefined) {
        this.needed_documents[key as keyof typeof this.needed_documents] = true;
      }
      else {
        this.needed_documents[key as keyof typeof this.needed_documents] = false;
      }
    }
    // update list
    this.setListDataDocuments(this.documents);
  }
  // set list data for documents
  setListDataDocuments(data: any) {
    // empty list
    this.list_data_doc = {
      entries: [],
    };
    // fill list
    data.forEach((entry: any) => {
      // prepare document data
      var actions = [
          {
            name: 'Öffnen',
            trigger: 'open',
          },
          {
            name: 'Löschen',
            trigger: 'delete',
          },
      ];
      switch (entry.acf.type) {
        case 'decease':
          entry.acf.name = 'Sterbefall';
          entry.id = 'decease_' + entry.id;
          entry.subline = {
            value: entry.acf.timestamp,
            text: formatDate(entry.acf.timestamp, 'dd.MM.YYYY, HH:mm', 'de-DE') + ' Uhr',
            class: '',
          },
          entry.meta_top = {
            value: false,
          };
         entry.meta_top = {
            value: false,
          };
          actions = [
              {
                name: 'Öffnen',
                trigger: 'open',
              },
          ];
          break;
        case 'invoice':
          entry.acf.name = 'Rechnung';
          entry.id = 'document_' + entry.id;
          entry.subline = {
            value: entry.acf.timestamp,
            text: formatDate(entry.acf.timestamp, 'dd.MM.YYYY, HH:mm', 'de-DE') + ' Uhr, ' + entry.acf.content.recipient.acf.firstname + ' ' + entry.acf.content.recipient.acf.lastname,
            class: '',
          },
          entry.meta_top = {
            value: entry.acf.content.invoice_counter,
            text: entry.acf.content.invoice_counter,
            class: '',
          };
          entry.meta_bottom = {
            value: entry.acf.status,
            text: this.getDocumentStatusDetails(entry.acf.status).text,
            class: 'invoice_status',
          };
          actions = [
              {
                name: 'Öffnen',
                trigger: 'open',
              },
          ];
          break;
        case 'bid':
          entry.acf.name = 'Kostenvoranschlag';
          entry.id = 'document_' + entry.id;
          entry.subline = {
            value: entry.acf.timestamp,
            text: formatDate(entry.acf.timestamp, 'dd.MM.YYYY, HH:mm', 'de-DE') + ' Uhr, ' + entry.acf.content.recipient.acf.firstname + ' ' + entry.acf.content.recipient.acf.lastname,
            class: '',
          },
          entry.meta_top = {
            value: entry.acf.content.bid_counter,
            text: entry.acf.content.bid_counter,
            class: '',
          };
          entry.meta_bottom = {
            value: entry.acf.status,
            text: this.getDocumentStatusDetails(entry.acf.status).text,
            class: 'bid_status',
          };
          actions = [
              {
                name: 'Öffnen',
                trigger: 'open',
              },
          ];
          break;
        case 'birth_certificate':
          entry.acf.name = 'Geburtsurkunde';
          entry.id = 'file_' + entry.id;
          entry.subline = {
            value: entry.acf.timestamp,
            text: formatDate(entry.acf.timestamp, 'dd.MM.YYYY, HH:mm', 'de-DE') + ' Uhr',
            class: '',
          },
          entry.meta_top = {
            value: false,
          };
          entry.meta_bottom = {
            value: false,
          };
          break;
        case 'marriage_certificate':
          entry.acf.name = 'Heiratsurkunde';
          entry.id = 'file_' + entry.id;
          entry.subline = {
            value: entry.acf.timestamp,
            text: formatDate(entry.acf.timestamp, 'dd.MM.YYYY, HH:mm', 'de-DE') + ' Uhr',
            class: '',
          },
          entry.meta_top = {
            value: false,
          };
          entry.meta_bottom = {
            value: false,
          };
          break;
        case 'death_certificate':
          entry.acf.name = 'Sterbeurkunde';
          entry.id = 'file_' + entry.id;
          entry.subline = {
            value: entry.acf.timestamp,
            text: formatDate(entry.acf.timestamp, 'dd.MM.YYYY, HH:mm', 'de-DE') + ' Uhr',
            class: '',
          },
          entry.meta_top = {
            value: false,
          };
          entry.meta_bottom = {
            value: false,
          };
          break;
        case 'dsgvo_confirmation':
          entry.acf.name = 'Datenschutzerklärung';
          entry.id = 'file_' + entry.id;
          entry.subline = {
            value: entry.acf.timestamp,
            text: formatDate(entry.acf.timestamp, 'dd.MM.YYYY, HH:mm', 'de-DE') + ' Uhr',
            class: '',
          },
          entry.meta_top = {
            value: false,
          };
          entry.meta_bottom = {
            value: false,
          };
          break;
        default:
          entry.acf.name = entry.acf.file.name;
          entry.id = 'file_' + entry.id;
          entry.subline = {
            value: entry.acf.timestamp,
            text: formatDate(entry.acf.timestamp, 'dd.MM.YYYY, HH:mm', 'de-DE') + ' Uhr',
            class: '',
          },
          entry.meta_top = {
            value: false,
          };
          entry.meta_bottom = {
            value: false,
          };
          break;
      }
      // set list data
      this.list_data_doc.entries.push({
        id: entry.id,
        actions: actions,
        image: {
          value: 'none',
        },
        headline: {
          value: entry.acf.name,
          text: entry.acf.name,
          class: '',
        },
        subline: entry.subline,
        meta_top: entry.meta_top,
        meta_bottom: entry.meta_bottom,
      })
    });
    // loading complete
    this.loading_documents = false;
  }
  // open document slide over
  openDocument(id: any, type: any) {
    // prefill form
    if (type != false) {
      this.form_document.patchValue({
        type: type,
      });
    }
    else {
       this.form_document.patchValue({
        type: 'other',
      });
    }
    // process document type change
    this.processDocumentTypeChange();
    // show slide over
    this.document_slide_over = true;
  }
  // save document
  saveDocument(id: any) {
    // start loading
    this.loading_documents = true;
    // bid
    if (this.form_document.value.type == 'bid' || id == 'bid') {
      this.router.navigate(
        ['dashboard/document/bid'],
        { queryParams: {id: this.decease_id}},
      );
    }
    // invoice
    else if (this.form_document.value.type == 'invoice' || id == 'invoice') {
      this.router.navigate(
        ['dashboard/document/invoice'],
        { queryParams: {id: this.decease_id}},
      );
    }
    // other
    else {
      // upload file
      this.wp.uploadFile(this.form_document.value.file, this.form_document.value.file.name).subscribe((response:any) => {
        // prepare document data
        var data = JSON.stringify({
          'title': 'Datei (' + this.form_document.value.type + '): ' + this.decease.acf.firstname + ' ' + this.decease.acf.lastname + ' (' + this.user.acf.undertaker.post_title+ ')',
          'fields': {
            'type': this.form_document.value.type,
            'timestamp': new Date(),
            'content': '',
            'file': response.id,
            'undertaker': this.user.acf.undertaker.ID,
            'decease_id': this.decease_id,
          },
          'status': 'publish',
        });
        // create document
        this.wp.createDocument(data).subscribe((response:any) => {
          // reload documents
          this.loadDocuments();
        });
      });
    }
    // hide slide over
    this.document_slide_over = false;
  }
  // delete document
  deleteDocument(id: any) {
    // show loading
    this.loading_documents = true;
    // delete document
    this.wp.deleteDocument(id).subscribe((response:any) => {
      // reload documents
      this.loadDocuments();
    });
  }
  // process list action for product connection
  processListActionDocuments(action: any) {
    // click or open
    if (action.trigger == 'click' || action.trigger == 'open') {
      // decease
      if (action.id.toString().startsWith('decease_')) {
        // get decease id
        action.id = action.id.toString().replace('decease_', '');
        // route to document details page
        this.router.navigate(
          ['dashboard/document/decease'],
          { queryParams: {id: action.id}}
        );
      }
      // docuemnt
      else if (action.id.toString().startsWith('document_')) {
        // get document id
        action.id = action.id.toString().replace('document_', '');
        // route to document detail page
        this.router.navigate(['dashboard/document/' + action.id]);
      } 
      // file
      else if (action.id.toString().startsWith('file_')) {
        // get file url
        var file_url = this.documents.find((x:any) => x.id == action.id).acf.file.url;
        // open file in new tab
        window.open(file_url, '_blank')
      }
    }
    // delete
    else if (action.trigger == 'delete') {
      // get document id
      action.id = action.id.toString().replace('file_', '');
      // delete document
      this.deleteDocument(action.id);
    }
  }
  // on image file change
  onDocumentFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form_document.patchValue({
        file: file
      });
    }
  }
  // process document type change
  processDocumentTypeChange() {
    // switch based on selected type
    switch (this.form_document.value.type) {
      // bid or invoice
      case 'bid':
      case 'invoice':
        // remove required status for file
        this.form_document.get('file')?.clearValidators();
        this.form_document.get('file')?.updateValueAndValidity();
        // break
        break;
      // other
      default:
         // add required status for file
        this.form_document.get('file')?.setValidators([Validators.required]);
        this.form_document.get('file')?.updateValueAndValidity();
        // break
        break;
    }
  }
  // set list data for product connections
  setListDataEvents(data: any) {
    // reset list
    this.list_data_events = {
      entries: [],
      settings: {
        drag: true,
      }
    };
    // fill list
    data.forEach((entry: any) => {
      // prepare custom fields
      var custom_fields: any = [];
      entry.acf.custom_fields_parsed.forEach((field: any) => {
        if (field.display == '1') {
          var content = field.content;
          if (field.type == 'checkbox') {
            content = '';
            field.content.forEach((value: any, index: any) => {
              if (value == true) {
                content+= field.value[index] + ', ';
              }
            });
            content = content.substring(0, content.length - 2);
          }
          custom_fields.push({
            val1: field.name,
            val2: content,
          });
        }
      });
      // set up list
      this.list_data_events.entries.push({
        id: entry.id,
        actions: [
          {
            name: 'Bearbeiten',
            trigger: 'edit',
          },
          {
            name: 'Löschen',
            trigger: 'delete',
          },
        ],
        image: {
          value: 'none',
        },
        headline: {
          value: entry.acf.name,
          text: entry.acf.name,
        },
        subline: {
          data: custom_fields,
        },
        meta_top: {
          value: false,
        },
        meta_bottom: {
          value: false,
        },
      })
    });
    // loading complete
    this.loading_events = false;
  }
  // process list action for events
  processListActionEvents(action: any) {
    // click on entry || edit
    if (action.trigger == 'click' || action.trigger == 'edit') {
      // open product conntection
      this.openEvent(action.id);
    }
    // delete
    else if (action.trigger == 'delete') {
      this.deleteEvent(action.id);
    }
  }
  // process product search
  processProductSearch(event: any) {
    // get serch term
    var term = (event != false) ? event.target.value : '';
    // iterate over all available products
    this.products.forEach((element: any, index: any) => {
      // product does not match search term
      if (!element.acf.name.toLowerCase().includes(term.toLowerCase())) {
        element.acf.search_excluded = true;
      }
      // product matches with search term
      else {
        element.acf.search_excluded = false;
      }
    });
  }
  // load contacts
  async loadContacts() {
    // upadte loading
    this.loading_contacts = true;
    // get contacts
    this.contacts = await firstValueFrom(this.wp.getContacts(this.decease_id));
    // update list
    this.setListDataContacts(this.contacts);
  }
  // set list data for contacts
  setListDataContacts(data: any) {
    // empty list
    this.list_data_contacts = {
      entries: [],
    };
    // fill list
    data.forEach((entry: any) => {
      // set list data
      this.list_data_contacts.entries.push({
        id: entry.id,
        actions: [
          {
            name: 'Bearbeiten',
            trigger: 'edit',
          },
          {
            name: 'Löschen',
            trigger: 'delete',
          },
        ],
        image: {
          value: 'none',
        },
        headline: {
          value: entry.acf.firstname + ' ' + entry.acf.lastname,
          text: entry.acf.firstname + ' ' + entry.acf.lastname,
        },
        subline: false,
        meta_top: false,
        meta_bottom: false,
      })
    });
    // loading complete
    this.loading_contacts = false;
  }
  // open contact
  openContact(id: any) {
    // set contact id
    this.contact_id = id;
     // reset form
    this.form_contact.reset();
    // existing contact
    if (id != false) {
      // prefill contact
      this.prefillFormContact(id);
    }
    // new contact
    else {
      
    }
    // show slide over
    this.contact_slide_over = true;
  }
  // prefill contact form
  prefillFormContact(contact_id: any) {
    // get contact data
    var contact = this.contacts.find((x: any) => x.id === contact_id);
    // prefill form
    this.form_contact.setValue({
      firstname: contact.acf.firstname ? contact.acf.firstname : '',
      lastname: contact.acf.lastname ? contact.acf.lastname : '',
      address: contact.acf.address ? contact.acf.address : '',
      zip: contact.acf.zip ? contact.acf.zip : '',
      city: contact.acf.city ? contact.acf.city : '',
      email: contact.acf.email ? contact.acf.email : '',
      phone: contact.acf.phone ? contact.acf.phone : '',
      connection: contact.acf.connection ? contact.acf.connection : '',
    })
  }
  // save contact
  saveContact(id: any) {
    // start loading
    this.loading_contacts = true;
    // hide slide over
    this.contact_slide_over = false;
    // prepare data
    this.form_contact.value.decease_id = this.decease_id;
    var data = JSON.stringify({
      'title': this.form_contact.value.firstname + ' ' + this.form_contact.value.lastname + ' (' + this.user.acf.undertaker.post_title + ')',
      'fields': this.form_contact.value,
      'status': 'publish',
    });
    // update
    if (id != false) {
      // update contact
      this.wp.updateContact(id, data).subscribe((response:any) => {
        // reload contacts
        this.loadContacts();
      });
    }
    // create
    else {
      // create contact
      this.wp.createContact(data).subscribe((response:any) => {
        // reload contacts
        this.loadContacts();
      });
    }
  }
  // process list action for contacts
  processListActionContacts(action: any) {
    // click on entry || edit
    if (action.trigger == 'click' || action.trigger == 'edit') {
      // open product conntection
      this.openContact(action.id)
    }
    // delete
    else if (action.trigger == 'delete') {
      this.deleteContact(action.id);
    }
  }
  // delete contact
  deleteContact(contact_id: any) {
    // loading update
    this.loading_contacts = true;
    // delete contact
    this.wp.deleteContact(contact_id).subscribe((response: any) => {
      // reload contacts
      this.loadContacts();
    });
  }
  // get document status details
  getDocumentStatusDetails(status_id: any) {
    var status_details: any = {
      'text': false,
      'color': false,
    }
    // set bid status
    switch (status_id) {
      case '1':
        status_details.text = 'Erstellt';
        status_details.color = 'text-signal-orange';
        break;
      case '2':
        status_details.text = 'Genehmigt';
        status_details.color = 'text-signal-green';
        break;
      case '3':
        status_details.text = 'Abgelehnt';
        status_details.color = 'text-signal-red';
        break;
      default:
        status_details.text = '';
        status_details.color = 'text-grey';
        break;
    }
    return status_details;
  }
  // save appointment
  saveAppointment(data: any, appointment_id: any) {
    // set data
    var data_json = JSON.stringify({
      'title': this.user.acf.undertaker.acf.name + ' - ' + data.date + ' - ' + data.title, 
      'fields': data,
      'status': 'publish',
    });
    // create
    if (appointment_id == false) {
      this.wp.createAppointment(data_json).subscribe((response:any) => {
        return response.id;
      });
    }
    // update
    else {
      this.wp.updateAppointment(appointment_id, data_json).subscribe((response:any) => {
        return response.id;
      });
    }
  }
}
