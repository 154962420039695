import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../../services/cookie.service';
import { WpService } from '../../services/wp.service';
import { ViewService } from '../../services/view.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-deceases',
  templateUrl: './deceases.component.html',
  styleUrls: ['./deceases.component.css']
})
export class DeceasesComponent implements OnInit {
  // user
  @Input() user: any = [];
  // deceases
  deceases: any = [];
  // loading state
  loading = false;
  // archive state
  archive = false;
  // view data
  view_data = {
    input: [],
    output: [],
    filter: {
      fields: [
        {field: 'gender', name: 'Geschlecht'},
        {field: 'firstname', name: 'Vorname'},
        {field: 'lastname', name: 'Nachname'},
        {field: 'birthday', name: 'Geburtstag'},
        {field: 'deathday', name: 'Sterbetag'},
        {field: 'region', name: 'Gebiet'},
        {field: 'religion', name: 'Religion'},
        {field: 'maritalstatus', name: 'Familienstand'},
      ],
      options: [],
    },
    sort: {
      fields: [
        {field: 'lastname', name: 'Nachname', type: 'string'},
        {field: 'firstname', name: 'Vorname', type: 'string'},
        {field: 'region', name: 'Region', type: 'string'},
      ],
      options: [],
    }
  };
  // list data
  list_data: any = {};
  // constructor
  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private wp: WpService,
    private view: ViewService,
    private router: Router) {
  }
  // on init
  ngOnInit(): void {
    // load deceases
    this.loadDeceases(this.archive);
  }
  // load deceases
  loadDeceases(archive: boolean) {
    // loading state update
    this.loading = true;
    // get deceases
    this.wp.getDeceases(this.user.acf.undertaker.ID, archive).subscribe((response:any) => {
      // set view input
      this.view_data.input = response;
      // set view options
      if (this.user.acf.view_deceases) {
        this.view_data.filter.options = JSON.parse(this.user.acf.view_deceases).filter;
        this.view_data.sort.options = JSON.parse(this.user.acf.view_deceases).sort;
      }
      // get initial view output
      response = this.view.getInitView(this.view_data).output;
      // set deceases
      this.deceases = response;
      // set list
      this.setListData(this.deceases);
    });
  }
  // get view data
  getViewData(view: any) {
    // upate view options
    this.view_data.filter.options = view.filter.options;
    this.view_data.sort.options = view.sort.options;
    // update deceases
    this.deceases = view.output;
    // update list
    this.setListData(this.deceases);
    // update view options on user
    var view_options = {
      sort: view.sort.options,
      filter: view.filter.options
    }
    var data = JSON.stringify({
      'fields': {
        'view_deceases': JSON.stringify(view_options),
      },
    });
    this.wp.updateUserData(this.user.id, data).subscribe((response:any) => {
      this.user.acf.view_deceases = JSON.stringify(view_options);
    });
  }
  // set list data
  setListData(data: any) {
     // reset list
    this.list_data = {
      entries: [],
    };
    // fill list
    data.forEach((entry: any) => {
      this.list_data.entries.push({
        id: entry.id,
        actions: [
          {
            name: 'Bearbeiten',
            trigger: 'click',
          },
        ],
        image: {
          value: entry.acf.image,
          text: entry.acf.image,
          class: '',
        },
        headline: {
          value: entry.acf.firstname + ' ' + entry.acf.lastname,
          text: entry.acf.firstname + ' ' + entry.acf.lastname,
          class: '',
        },
        subline: {
          value: entry.acf.deathday,
          text: entry.acf.deathday,
          class: '',
        },
        meta_top: {
          value: entry.acf.region,
          text: entry.acf.region,
          class: '',
        },
        meta_bottom: {
          value: entry.acf.status,
          text: entry.acf.status_text,
          class: 'decease_status',
        },
      })
    });
    // loading complete
    this.loading = false;
  }
  // process list action
  processListAction(action: any) {
    // click on entry
    if (action.trigger == 'click') {
      // route to detail page
      this.router.navigate(['dashboard/decease/' + action.id]);
    }
  }
}
