<!-- header -->
<div class="py-8 md:flex md:items-center md:justify-between">
	<div class="min-w-0 flex-1">
		<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Einstellungen</h2>
	</div>
	<div class="mt-4 flex md:ml-4 md:mt-0"></div>
</div>
<!-- tabs -->
<div class="border-b border-gray-200 pb-5 sm:pb-0">
  <div class="mt-3 sm:mt-4">
    <div class="sm:hidden">
      <label for="current-tab" class="sr-only">Select a tab</label>
      <select id="current-tab" name="current-tab" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
        <option>Allgemein</option>
        <option>Ereignisse</option>
        <option>Prozesse</option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav class="-mb-px flex space-x-8">
        <a (click)="tab = 1" class="tab" [class.active]="tab == 1">Allgemein</a>
        <a (click)="tab = 2; getProducts()" class="tab" [class.active]="tab == 2">Ereignisse</a>
        <a (click)="tab = 3" class="tab" [class.active]="tab == 3">Prozesse</a>
      </nav>
    </div>
  </div>
  </div>
<!-- allgemein -->
<div *ngIf="tab == 1">
  <form [formGroup]="form_general">
    <div class="space-y-12">
      <div class="pb-12">
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <label for="first-name">Firmenname</label>
            <div class="mt-2">
              <input formControlName="name" type="text">
            </div>
          </div>
          <div class="sm:col-span-6">
            <label for="first-name">Logo</label>
            <ng-container *ngIf="undertaker.acf?.logo?.url">
              <img [src]="undertaker.acf.logo.url" width="200" class="mt-2" />
            </ng-container>
            <div class="mt-2">
              <input (change)="onFileChange($event)" id="file_input" type="file">
            </div>
          </div>
          <div class="sm:col-span-6 sm:grid sm:grid-cols-2 sm:gap-x-6">
            <div class="">
              <label for="first-name">Adresse</label>
              <div class="mt-2">
                <textarea formControlName="address" rows="4"></textarea>
              </div>
            </div>
            <div class="">
              <label for="first-name">Bankdaten</label>
              <div class="mt-2">
                <textarea formControlName="bankdetails" rows="4"></textarea>
              </div>
            </div>
          </div>
          <div class="sm:col-span-6">
            <label for="first-name">Gebiete</label>
            <app-listfield *ngIf="undertaker.acf?.regions" unique_id="regions" [input_data]="undertaker.acf?.regions.split(',')" (output_data)="processListField($event, 'regions')"></app-listfield>
            <input formControlName="regions" type="text" class="hidden">
          </div>
          <div class="sm:col-span-6">
            <label for="first-name">Produktkategorien</label>
            <app-listfield *ngIf="undertaker.acf?.product_categories" unique_id="product_categories" [input_data]="undertaker.acf?.product_categories.split(',')" (output_data)="processListField($event, 'product_categories')"></app-listfield>
            <div class="mt-2">
              <input formControlName="product_categories" type="text" class="hidden">
            </div>
          </div>
          <div class="sm:col-span-6">
            <label for="first-name">Produkteinheiten</label>
            <app-listfield *ngIf="undertaker.acf?.product_units" unique_id="product_units" [input_data]="undertaker.acf?.product_units.split(',')" (output_data)="processListField($event, 'product_units')"></app-listfield>
            <div class="mt-2">
              <input formControlName="product_units" type="text" class="hidden">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!loading_general" class="mt-6 flex items-center justify-end gap-x-6">
      <button type="submit" class="primary" [disabled]="form_general.invalid" (click)="updateUndertaker(user.acf.undertaker.ID)">Speichern</button>
    </div>
    <div *ngIf="loading_general" class="text-right mt-6">
      <div role="status">
        <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#333"/>
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="saved" class="rounded bg-signal-green p-4 my-4">
      <p class="text-white">Einstellungen wurden erfolgreich gespeichert.</p>
    </div>
  </form>
</div>
<!-- ereignisse -->
<div *ngIf="tab == 2">
  <div *ngIf="!loading_events" class="mt-6">
    <app-list [data]="list_data_events" (action)="processListActionEvents($event)"></app-list>
    <button type="submit" class="primary" (click)="openEventTemplate(false)">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75V9.25H6.75C6.33579 9.25 6 9.58579 6 10C6 10.4142 6.33579 10.75 6.75 10.75H9.25V13.25C9.25 13.6642 9.58579 14 10 14C10.4142 14 10.75 13.6642 10.75 13.25V10.75H13.25C13.6642 10.75 14 10.4142 14 10C14 9.58579 13.6642 9.25 13.25 9.25H10.75V6.75Z" fill="#0F172A"/>
      </svg>
      <span>Ereignis erstellen</span>
    </button>
  </div>
  <div *ngIf="loading_events" class="py-4">
    <div role="status">
      <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#4F46E5"/>
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="event_slide_over" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <div class="pointer-events-auto w-screen max-w-5xl">
            <div class="flex h-full flex-col overflow-y-scroll bg-white p-6 shadow-xl">
              <div>
                <div class="flex justify-end mb-2">
                  <a (click)="event_slide_over = false" type="button" class="cursor-pointer">
                    <span class="sr-only">Schließen</span>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.5 22.5L22.5 7.5M7.5 7.5L22.5 22.5" stroke="#505050" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </div>
                <div class="flex items-start justify-between mb-4">
                  <h2 id="slide-over-title">Ereignis</h2>
                </div>
              </div>
              <div>
                <form [formGroup]="form_event">
                  <div class="mt-4">
                    <label>Name</label>
                    <div class="mt-2">
                      <input formControlName="name" type="text">
                    </div>
                  </div>
                  <div class="mt-8">
                    <label class="text-hl-small">Eigene Felder</label>
                    <div *ngFor="let custom_field of custom_fields; let i = index">
                      <div *ngIf="!custom_field.toggle" class="grid grid-cols-2 items-center justify-between border border-grey-light rounded mt-3 p-3">
                        <p>{{ custom_field.name }}</p>
                        <div class="flex items-center justify-end gap-4">
                          <span class="flex gap-2">
                            <div style="line-height:0;">
                              <label class="relative inline-flex items-center cursor-pointer">
                                <input [checked]="custom_field.display == '1'" (change)="custom_field.display == '0' ? custom_field.display = '1' : custom_field.display = '0'" type="checkbox" value="" class="sr-only peer">
                                <div class="w-11 h-6 bg-sand rounded-full peer dark:bg-sand peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-signal-green"></div>
                              </label>
                            </div>
                            <span>In der Ereignisliste anzeigen</span>
                          </span>
                          <div class="text-right leading-none" style="line-height:0;">
                            <a (click)="custom_field.toggle = true" class="px-3 py-2 bg-sand rounded inline-block cursor-pointer">
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.17166 1.97003C5.21687 1.69879 5.45154 1.5 5.72651 1.5H6.27349C6.54846 1.5 6.78313 1.69879 6.82834 1.97003L6.90282 2.41693C6.93818 2.62907 7.09464 2.79919 7.29326 2.88166C7.49197 2.96416 7.72015 2.95314 7.89522 2.82809L8.26401 2.56467C8.48776 2.40484 8.79427 2.43021 8.9887 2.62465L9.37548 3.01142C9.56991 3.20585 9.59528 3.51236 9.43546 3.73611L9.17194 4.10503C9.04692 4.28006 9.0359 4.50816 9.11837 4.70682C9.20082 4.90539 9.3709 5.06182 9.58298 5.09716L10.03 5.17166C10.3012 5.21687 10.5 5.45154 10.5 5.72651V6.27349C10.5 6.54846 10.3012 6.78313 10.03 6.82834L9.58306 6.90282C9.37093 6.93818 9.20081 7.09464 9.11834 7.29326C9.03584 7.49196 9.04686 7.72013 9.17192 7.8952L9.43529 8.26392C9.59511 8.48767 9.56974 8.79418 9.37531 8.98861L8.98853 9.37539C8.7941 9.56982 8.48759 9.59519 8.26384 9.43537L7.89504 9.17194C7.72 9.04691 7.49187 9.03588 7.29321 9.11837C7.09462 9.20081 6.93818 9.37091 6.90283 9.58301L6.82834 10.03C6.78313 10.3012 6.54846 10.5 6.27349 10.5H5.72651C5.45154 10.5 5.21687 10.3012 5.17166 10.03L5.09718 9.58306C5.06182 9.37093 4.90536 9.20081 4.70674 9.11834C4.50804 9.03584 4.27987 9.04686 4.1048 9.17192L3.73599 9.43535C3.51224 9.59518 3.20573 9.56981 3.0113 9.37537L2.62452 8.9886C2.43009 8.79416 2.40472 8.48766 2.56454 8.26391L2.82806 7.89498C2.95308 7.71995 2.9641 7.49184 2.88163 7.29319C2.79918 7.09461 2.6291 6.93818 2.41701 6.90284L1.97003 6.82834C1.69879 6.78313 1.5 6.54846 1.5 6.27349V5.72651C1.5 5.45154 1.69879 5.21687 1.97003 5.17166L2.41693 5.09718C2.62907 5.06182 2.79919 4.90536 2.88166 4.70673C2.96416 4.50802 2.95315 4.27985 2.82809 4.10477L2.56474 3.73608C2.40492 3.51233 2.43028 3.20582 2.62472 3.01139L3.01149 2.62461C3.20593 2.43018 3.51243 2.40481 3.73619 2.56463L4.10498 2.82806C4.28002 2.95309 4.50814 2.96411 4.7068 2.88163C4.90539 2.79919 5.06182 2.62909 5.09717 2.417L5.17166 1.97003Z" stroke="#FCFCFC" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.50035 6.00001C7.50035 6.82844 6.82878 7.50001 6.00035 7.50001C5.17192 7.50001 4.50035 6.82844 4.50035 6.00001C4.50035 5.17158 5.17192 4.50001 6.00035 4.50001C6.82878 4.50001 7.50035 5.17158 7.50035 6.00001Z" stroke="#FCFCFC" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </a>
                          </div>
                          <div class="text-right" style="line-height:0;">
                            <a (click)="removeCustomField(i)" class="px-3 py-2 bg-signal-red rounded inline-block cursor-pointer">
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.37019 4.5L7.19712 9M4.80288 9L4.62981 4.5M9.61382 2.89528C9.78481 2.92111 9.95521 2.94873 10.125 2.97815M9.61382 2.89528L9.0799 9.83628C9.03482 10.4224 8.54607 10.875 7.95821 10.875H4.04179C3.45393 10.875 2.96519 10.4224 2.9201 9.83628L2.38618 2.89528M9.61382 2.89528C9.04061 2.80872 8.46075 2.74243 7.875 2.69716M1.875 2.97815C2.04479 2.94873 2.21519 2.92111 2.38618 2.89528M2.38618 2.89528C2.95939 2.80872 3.53925 2.74243 4.125 2.69716M7.875 2.69716V2.23909C7.875 1.64941 7.41967 1.15712 6.83029 1.13826C6.55461 1.12944 6.27782 1.125 6 1.125C5.72218 1.125 5.44539 1.12944 5.16971 1.13826C4.58033 1.15712 4.125 1.64941 4.125 2.23909V2.69716M7.875 2.69716C7.25628 2.64935 6.63098 2.625 6 2.625C5.36902 2.625 4.74372 2.64935 4.125 2.69716" stroke="#FCFCFC" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div [hidden]="!custom_field.toggle" class="border border-grey-light rounded mt-3 p-6">
                        <div class="grid grid-cols-2 gap-4">
                          <div>
                            <label>Feldname</label>
                            <div class="mt-2">
                              <input (input)="processCustomFieldName($event, i)" [value]="custom_field.name" attr.id="custom_field_name_{{i}}" type="text">
                            </div>
                          </div>
                          <div>
                            <label>Typ</label>
                            <div class="mt-2">
                              <select (change)="processCustomFieldType($event, i)" [value]="custom_field.type" attr.id="custom_field_type_{{i}}">
                                 <option value="text">Text</option>
                                 <option value="dropdown">Dropdown</option>
                                 <option value="checkbox">Checkbox</option>
                                 <option value="date">Datum</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="custom_field.type == 'dropdown' || custom_field.type == 'checkbox'" class="mt-4">
                          <label>Auswahlmöglichkeiten</label>
                          <app-listfield [unique_id]="'custom_field_value_' + i" [input_data]="custom_field.value.split(',')" (output_data)="processCustomFieldValue($event, i)"></app-listfield>
                        </div>
                        <div class="grid grid-cols-2 items-center mt-4">
                          <span class="flex gap-2">
                            <div style="line-height:0;">
                              <label class="relative inline-flex items-center cursor-pointer">
                                <input [checked]="custom_field.display == '1'" (change)="custom_field.display == '0' ? custom_field.display = '1' : custom_field.display = '0'" type="checkbox" value="" class="sr-only peer">
                                <div class="w-11 h-6 bg-sand rounded-full peer dark:bg-sand peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-signal-green"></div>
                              </label>
                            </div>
                            <span>In der Ereignisliste anzeigen</span>
                          </span>
                          <div class="flex items-center justify-end gap-2">
                            <button (click)="custom_field.toggle = false" class="primary">
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z" fill="#0F172A"/>
                              </svg>
                              <span>Feld speichern</span>
                            </button>
                            <button (click)="removeCustomField(i)" class="warning">
                              <span>Feld löschen</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex items-center mt-4 gap-2">
                      <button (click)="addCustomField()" class="secondary">
                        <span>Neues Feld hinzufügen</span>
                      </button>
                      <button *ngIf="custom_fields.length > 0" (click)="removeAllCustomFields()" class="warning">
                        <span>Alle entfernen</span>
                      </button>
                    </div>
                  </div>
                  <div class="mt-8">
                    <label class="text-hl-small">Produkte</label>
                    <div *ngFor="let product of products_choice" class="my-3 py-2 px-4 border border-grey-light rounded grid grid-cols-[48px_60%_auto] gap-4 items-center">
                      <div>
                        <img [src]="product.acf.image?.url ? product.acf.image?.url : 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='" class="h-12 w-12 flex-none rounded-full bg-gray-200 object-cover" />
                      </div>
                      <div class="text-txt-large">{{ product.acf.name }}</div>
                      <div class="text-right" style="line-height:0;">
                        <a (click)="removeProduct(product.ID)" class="px-3 py-2 bg-signal-red rounded inline-block cursor-pointer">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.37019 4.5L7.19712 9M4.80288 9L4.62981 4.5M9.61382 2.89528C9.78481 2.92111 9.95521 2.94873 10.125 2.97815M9.61382 2.89528L9.0799 9.83628C9.03482 10.4224 8.54607 10.875 7.95821 10.875H4.04179C3.45393 10.875 2.96519 10.4224 2.9201 9.83628L2.38618 2.89528M9.61382 2.89528C9.04061 2.80872 8.46075 2.74243 7.875 2.69716M1.875 2.97815C2.04479 2.94873 2.21519 2.92111 2.38618 2.89528M2.38618 2.89528C2.95939 2.80872 3.53925 2.74243 4.125 2.69716M7.875 2.69716V2.23909C7.875 1.64941 7.41967 1.15712 6.83029 1.13826C6.55461 1.12944 6.27782 1.125 6 1.125C5.72218 1.125 5.44539 1.12944 5.16971 1.13826C4.58033 1.15712 4.125 1.64941 4.125 2.23909V2.69716M7.875 2.69716C7.25628 2.64935 6.63098 2.625 6 2.625C5.36902 2.625 4.74372 2.64935 4.125 2.69716" stroke="#FCFCFC" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div (click)="show_productlist = true" class="flex gap-4 items-center text-txt-large pb-6 pt-3 cursor-pointer">
                      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_122_1336)">
                        <path d="M15.5 23L30.5 23M23 15.5V30.5" stroke="#A6A6A6" stroke-width="1.32583" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <circle cx="23" cy="23" r="22.5" stroke="black" stroke-opacity="0.08" stroke-dasharray="2 2"/>
                        <defs>
                        <clipPath id="clip0_122_1336">
                        <rect width="21.2132" height="21.2132" fill="white" transform="translate(23 8) rotate(45)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <span>Weiteres Produkt verknüpfen</span>
                    </div>
                    <div *ngIf="show_productlist" class="py-2 px-4 border border-grey-light bg-sand-light h-56 overflow-y-scroll rounded">
                      <div *ngFor="let product of products" (click)="addProduct(product.id)" class="py-2 grid grid-cols-[32px_auto] gap-4 items-center cursor-pointer">
                        <div>
                          <img [src]="product.acf.image?.url ? product.acf.image?.url : 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='" class="h-8 w-8 flex-none rounded-full bg-gray-200 object-cover" />
                        </div>
                        <div class="text-txt-small">{{ product.acf.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <label>Notiz</label>
                    <div class="mt-2">
                      <textarea formControlName="note" rows="4"></textarea>
                    </div>
                  </div>
                  <!-- <div class="mt-4">
                    <label>Produkte</label>
                    <div *ngFor="let product of products_choice" class="mt-2 mb-2 flex justify-between">
                      <div>{{ product.acf.name }}</div>
                       <button type="submit" class="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:bg-red-100" (click)=removeProduct(product.ID)>-</button>
                    </div>
                    <div class="flex gap-2">
                      <select #selected_product class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                         <option *ngFor="let product of products" [value]="product.id">{{ product.acf.name }}</option>
                      </select>
                      <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-100" (click)="addProduct()">+</button>
                    </div>
                  </div> -->
                  <!-- <div class="mt-4">
                    <label class="block text-sm font-medium leading-6 text-gray-900">Felder</label>
                    <div *ngFor="let custom_field of custom_fields; let i = index" class="custom_field mt-2 mb-2 p-4 bg-gray-200">
                      <label class="block text-sm font-medium leading-6 text-gray-900">Name</label>
                      <input [value]="custom_field.name" attr.id="custom_field_name_{{i}}" type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <label class="block text-sm font-medium leading-6 text-gray-900">Typ</label>
                      <select [value]="custom_field.type" attr.id="custom_field_type_{{i}}" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                         <option value="text">Text</option>
                         <option value="dropdown">Dropdown</option>
                         <option value="checkbox">Checkbox</option>
                         <option value="date">Datum</option>
                      </select>
                      <label class="block text-sm font-medium leading-6 text-gray-900">Wert</label>
                      <input [value]="custom_field.value" attr.id="custom_field_value_{{i}}" type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <label class="block text-sm font-medium leading-6 text-gray-900">Anzeige in Übersicht</label>
                      <select [value]="custom_field.display" attr.id="custom_field_display_{{i}}" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                         <option value="1">Ja</option>
                         <option value="0">Nein</option>
                      </select>
                      <button type="submit" class="mt-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:bg-red-100" (click)="removeCustomField(i)">-</button>
                    </div>
                    <div class="flex gap-2">
                      <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-100" (click)="addCustomField()">+</button>
                    </div>
                  </div> -->
                  <div class="mt-6 flex items-center justify-end gap-x-6">
                    <button type="submit" class="primary" [disabled]="form_event.invalid" (click)="updateEventTemplate(event_id)">Speichern</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- prozesse -->
<div *ngIf="tab == 3">
  <div *ngIf="!loading_processes" class="mt-6">
    <app-list [data]="list_data_processes" (action)="processListActionProcesses($event)"></app-list>
    <button type="submit" class="primary" (click)="openProcessTemplate(false)">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75V9.25H6.75C6.33579 9.25 6 9.58579 6 10C6 10.4142 6.33579 10.75 6.75 10.75H9.25V13.25C9.25 13.6642 9.58579 14 10 14C10.4142 14 10.75 13.6642 10.75 13.25V10.75H13.25C13.6642 10.75 14 10.4142 14 10C14 9.58579 13.6642 9.25 13.25 9.25H10.75V6.75Z" fill="#0F172A"/>
      </svg>
      <span>Prozess erstellen</span>
    </button>
  </div>
  <div *ngIf="loading_processes" class="py-4">
    <div role="status">
      <svg aria-hidden="true" class="inline w-9 h-9 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#333"/>
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="process_slide_over" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto w-screen max-w-5xl">
              <div class="flex h-full flex-col overflow-y-scroll bg-white p-6 shadow-xl">
                <div>
                  <div class="flex justify-end mb-2">
                    <a (click)="process_slide_over = false" type="button" class="cursor-pointer">
                      <span class="sr-only">Schließen</span>
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 22.5L22.5 7.5M7.5 7.5L22.5 22.5" stroke="#505050" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </a>
                  </div>
                  <div class="flex items-start justify-between mb-4">
                    <h2 id="slide-over-title">Prozess</h2>
                  </div>
                </div>
                <div>
                <form [formGroup]="form_process">
                  <div class="mt-4">
                    <label>Name</label>
                    <div class="mt-2">
                      <input formControlName="name" type="text">
                    </div>
                  </div>
                  <div class="mt-4">
                    <label>Ereignisse</label>
                    <div *ngFor="let event of events_choice" class="my-3 py-2 px-4 border border-grey-light rounded grid grid-cols-2 gap-4 items-center">
                      <div class="text-txt-large">{{ event.acf.name }}</div>
                      <div class="text-right" style="line-height:0;">
                        <a (click)="removeEventFromProcess(event.ID)" class="px-3 py-2 bg-signal-red rounded inline-block cursor-pointer">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.37019 4.5L7.19712 9M4.80288 9L4.62981 4.5M9.61382 2.89528C9.78481 2.92111 9.95521 2.94873 10.125 2.97815M9.61382 2.89528L9.0799 9.83628C9.03482 10.4224 8.54607 10.875 7.95821 10.875H4.04179C3.45393 10.875 2.96519 10.4224 2.9201 9.83628L2.38618 2.89528M9.61382 2.89528C9.04061 2.80872 8.46075 2.74243 7.875 2.69716M1.875 2.97815C2.04479 2.94873 2.21519 2.92111 2.38618 2.89528M2.38618 2.89528C2.95939 2.80872 3.53925 2.74243 4.125 2.69716M7.875 2.69716V2.23909C7.875 1.64941 7.41967 1.15712 6.83029 1.13826C6.55461 1.12944 6.27782 1.125 6 1.125C5.72218 1.125 5.44539 1.12944 5.16971 1.13826C4.58033 1.15712 4.125 1.64941 4.125 2.23909V2.69716M7.875 2.69716C7.25628 2.64935 6.63098 2.625 6 2.625C5.36902 2.625 4.74372 2.64935 4.125 2.69716" stroke="#FCFCFC" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div (click)="show_eventlist = true" class="flex gap-4 items-center text-txt-large pb-6 pt-3 cursor-pointer">
                      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_122_1336)">
                        <path d="M15.5 23L30.5 23M23 15.5V30.5" stroke="#A6A6A6" stroke-width="1.32583" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <circle cx="23" cy="23" r="22.5" stroke="black" stroke-opacity="0.08" stroke-dasharray="2 2"/>
                        <defs>
                        <clipPath id="clip0_122_1336">
                        <rect width="21.2132" height="21.2132" fill="white" transform="translate(23 8) rotate(45)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <span>Weiteres Ereignis verknüpfen</span>
                    </div>
                    <div *ngIf="show_eventlist" class="py-2 px-4 border border-grey-light bg-sand-light h-56 overflow-y-scroll rounded">
                      <div *ngFor="let event of events" (click)="addEventToProcess(event.id)" class="py-2 cursor-pointer">
                        <div class="text-txt-small">{{ event.acf.name }}</div>
                      </div>
                    </div>
                   <!--  <div *ngFor="let event of events_choice" class="mt-2 mb-2 flex justify-between">
                      <div>{{ event.acf.name }}</div>
                       <button type="submit" class="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:bg-red-100" (click)=removeEventFromProcess(event.ID)>-</button>
                    </div>
                    <div class="flex gap-2">
                      <select #selected_event>
                         <option *ngFor="let event of events" [value]="event.id">{{ event.acf.name }}</option>
                      </select>
                      <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-100" (click)="addEventToProcess()">+</button>
                    </div> -->
                  </div>
                  <div class="mt-6 flex items-center justify-end gap-x-6">
                    <button type="submit" class="primary" [disabled]="form_process.invalid" (click)="updateProcessTemplate(process_id)">Speichern</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>