<ul role="list" class="list" cdkDropList (cdkDropListDropped)="dropItem($event)">
	<li cdkDrag *ngFor="let entry of data.entries; let i = index" (click)="processAction('click', entry.id)">
		<div [class.active]="data.settings?.drag" class="drag cursor-move hidden w-4" cdkDragHandle>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M2.1875 3.0625H11.8125M2.1875 5.6875H11.8125M2.1875 8.3125H11.8125M2.1875 10.9375H11.8125" stroke="#A6A6A6" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>
		<div class="flex grow gap-x-4 items-center">
			<img *ngIf="entry.image.value != 'none'" class="h-16 w-16 flex-none rounded-full bg-gray-200 object-cover" [src]="entry.image.value?.url ? entry.image.value?.url : 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='" alt="">
			<div class="min-w-0 flex-auto">
				<p class="font-bold text-txt-large">{{ entry.headline.text }}</p>
				<p *ngIf="entry.subline.text" class="text-sand mt-1">{{ entry.subline.text }}</p>
				<div *ngIf="entry.subline.data?.length > 0" class="relative mt-4">
					<div class="hide_scrollbar flex gap-4 overflow-x-scroll pr-10">
						<div *ngFor="let data of entry.subline.data" class="bg-sand-light border border-grey-light p-4 rounded shrink-0 grow-0 basis-60">
							<p class="font-bold">{{ data.val1 }}</p>
							<p>{{ data.val2 }}</p>
						</div>
					</div>
					<div class="absolute top-0 right-0 w-10 h-full bg-gradient-to-l from-white"></div>
				</div>
			</div>
		</div>
		<div class="hidden flex justify-end sm:flex gap-10 sm:items-center w-60">
			<div class="text-right">
				<p *ngIf="entry.meta_top?.value !== false" class="mb-1">{{ entry.meta_top?.text }}</p>
				<p *ngIf="entry.meta_bottom?.value !== false" [attr.class]="'text-txt-small flex items-baseline justify-end gap-1 ' + entry.meta_bottom?.class" [attr.data-value]="entry.meta_bottom?.value">
					<span class="dot text-txt-large">•</span>
					<span>{{ entry.meta_bottom?.text }}</span>
				</p>
			</div>
			<div class="relative">
				<button (click)="$event.stopPropagation(); openActions(i)" type="button" class="inline-flex px-0 py-0 h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
					<span class="sr-only">Menü öffnen</span>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 10C3.75 9.30964 4.30964 8.75 5 8.75C5.69036 8.75 6.25 9.30964 6.25 10C6.25 10.6904 5.69036 11.25 5 11.25C4.30964 11.25 3.75 10.6904 3.75 10ZM8.75 10C8.75 9.30964 9.30964 8.75 10 8.75C10.6904 8.75 11.25 9.30964 11.25 10C11.25 10.6904 10.6904 11.25 10 11.25C9.30964 11.25 8.75 10.6904 8.75 10ZM13.75 10C13.75 9.30964 14.3096 8.75 15 8.75C15.6904 8.75 16.25 9.30964 16.25 10C16.25 10.6904 15.6904 11.25 15 11.25C14.3096 11.25 13.75 10.6904 13.75 10Z" fill="#333333"/>
					</svg>
				</button>
				<div *ngIf="selectedActionDropdown == i" class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
					<a (click)="$event.stopPropagation(); processAction(action.trigger, entry.id)" *ngFor="let action of entry.actions" class="block px-3 py-1 cursor-pointer">{{ action.name }}</a>
				</div>
			</div>
		</div>
	</li>
</ul>