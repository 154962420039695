import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WpService } from '../../services/wp.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  @Input() user: any = [];
  deceases_active: number = 0;
  deceases_archive: number = 0;
  deceases_all: number = 0;

  constructor(
    private http: HttpClient,
    private wp: WpService) {
  }

  ngOnInit(): void {
    // count active deceases
    this.countDeceases(false);
    // count archived deceases
    this.countDeceases(true);
  }

  countDeceases(archive: any) {
    // update global counter
    this.wp.getDeceases(this.user.acf.undertaker.ID, archive).subscribe((response:any) => {
      this.deceases_all += response.length
      // set archive counter
      if (archive) {
        this.deceases_archive = response.length;
      }
      // set active counter
      else {
        this.deceases_active = response.length;
      }
    });
  }
}
